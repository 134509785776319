import React from 'react';
import ApplicationLanding from './applicationLanding';
import { personalQuestions } from '../../action/application/personalQuestions';
import { questions } from '../../action/application/questions';
import Element from '../application/element';
import { connect } from "react-redux";
import { createApplication } from '../../action/application/getApplicationValidate';
import { insertApplicationUserDetails } from '../../action/application/insertApplicationUserDetails';
import Loader from '../loader/loader';
import QrCode from '../miteck/qrCode';
import { applicationSave, openBankingInitialize, continueOpenBanking, getOBApplicationStatus } from '../../action/applicationSave/applicationSave';
import { prepopulate } from '../../action/application/prepopulatedQuestion';
import DocumentVerify from './documentVerify';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import { analyticsChangeEvent, analyticsClickEvent, firebaseLoadingEvent } from '../../action/firebaseAnalytics/firebaseAnalytics';
import { getAvatar } from "../../action/avatar/getAvatar";
import Cookies from 'universal-cookie';
import { GTMApplicationId, GTMApplicationSubmitStatus, GTMClientSubmitErrors, GTMServerSubmitError, GTMUserId, GTMUserIdAndLoginState,GTMValidStepsSubmit,GTMApplicationFunnelSubmitError } from '../../utils/GtmDatalayer';
import { thirdPartyWebsiteAlertPopupText } from '../../utils/const';
import Reaptcha from 'reaptcha';
import { sendMarketingConstent } from '../../action/marketingConstent/marketingConstent';
import { validatePersonalDetails } from '../../action/application/validatePersonalDetails';
import { emailSendOTP, verifyEmailOTP } from '../../action/application/emailOTP';
import { sendMobileOTP, verifyMobileOTP } from '../../action/application/mobileOTP';
import { handleStatusCode } from '../../utils/handleStatusCode';
import $ from "jquery";
import { frontEndLogs } from '../../action/logs/frontEndLogs';
import { convertDateFormat } from '../../utils/convertDateFormat';
import { checkUserApplicationEligibility } from '../../action/application/checkApplicantEligibility';
import { generateSessionForUser } from '../../action/application/generateSession';
import { checkUserExistence } from '../../action/application/checkUserExistence';
import { mitekVerificationLinkSentToMobile } from '../../action/application/mitekVerificationLink';
import { mfaValidationCheckForUser  } from '../../action/application/mfaValidationCheckForUser';
import { PropTypes } from 'prop-types';
import { handlePopstate, handleRefreshTheScreen } from '../../action/logs/detectPageEvent';
import OpenBankingConsent from './openBankingConsent';
import { getRetailer } from '../../action/partner/getRetailer';
import branch from 'branch-sdk';
import { removeSpecialCharacterInAddressFields } from '../../utils/helpers/addressFieldValidation';

const threatMertix = window.threatmetrix;

export class Application extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.miteck = React.createRef();
    this.qrRef = React.createRef();
    this.state = {
      hasErrorMessage: 's',
      step: 1,
      stepOne: '',
      stepTwo: '',
      stepThree: '',
      stepFour: '',
      stepZeroData: '',
      stepOnedata: '',
      stepTwodata: '',
      stepThreedata: '',
      displayErrorsStepOne: '',
      displayErrorsStepTwo: '',
      displayErrorsStepThree: '',
      stages: '',
      email: '',
      phoneNo: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      displayPhoneError: '',
      hasOtpSendMessage: '',
      hasOtpSendToUserMessage: '',
      otpNumber: '',
      isEmailConsentChecked: localStorage.getItem('emailConsent') === 'true',
      isMobileAppConsentChecked: localStorage.getItem('mobileConsent') === 'true',
      isSMSConsentChecked: localStorage.getItem('smsConsent') === 'true',
      loading: false,
      isResendEnableBtn: true,
      hasOtpResendMessage: '',
      documentSteps: true,
      passportInstruction: false,
      drivinglicenseInstruction: false,
      selfieInstruction: false,
      width: window.innerWidth,
      showQR: false,
      unableToProvide: false,
      otpVerify: false,
      otpVerifyStatus: '',
      isEnableMobileBtn: true,
      isEnableCheckBox: true,
      serverErrorMessage: '',
      isPopupDisabled: true,
      isEnableOkButton: true,
      isEnableDesktopCapture: false,
      isContinuePrevAmount: false,
      privacyPolicyCheck: false,
      validateStatusCode: '',
      resultSourceURL: '',
      verificationMessage: "",
      userToken: "",
      reCaptchaToken: '',
      codeError: '',
      approvedApplicationMessage: '',
      disableVerifyOkButton: false,
      mobileVerifyFlag: false,
      emailVerifyFlag: false,
      resendEmailFlag: false,
      resendMobileFlag: false,
      loggedUserApplyNowFlag: false,
      isMobileVerified: false,
      prepopulateFlag: false,
      showMobileandEmailAddress: false,
      popupButtonLabel: '',
      isDisableContinue: true,
      redirectURL: '',
      hideFieldList: ['Current_Flat_No__c','Current_House_Name__c','Postal_Code__c', 'Street__c',
        'Town__c', 'PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c','Previous_Address_Line1__c','Address_Line1__c'],
      validateFieldList: ['PreviousPostal_Code__c', 'PreviousStreet__c', 'PreviousTown__c','Previous_Address_Line1__c'],
      occupationValidation: ['occupation', 'time_in_occupation'],
      showFooterText: false,
      secondSection: '',
      thirdSection: '',
      fourSection: '',
      fifthSection: '',
      showSecondSection: false,
      firstSectionProgressValue: 0,
      secondSectionProgressValue: 20,
      thirdSectionProgressValue: 40,
      fourSectionProgressValue: 60,
      fifthSectionProgressValue: 80,
      showThirdSection: false,
      showFourSection: false,
      showFifthSection: false,
      hideSectionThree: false,
      globalProgressBarValue: 0,
      globalProgressBarIndicatorValue:25,
      userLoanTerm: '',
      userLivingCost: '',
      userMonthlyIncome: '',
      userTotalExpenditure: '',
      flxAgree_C: false,
      flxDocumentAgree_C: false,
      flxContactAgree_C: false,
      flxAgree_c_Error: '', 
      flxDocumentAgree_c_Error: '',
      flxContactAgree_C_Error: '',
      progressBarImageSrc: 'images/bird-shadow--logo.svg',
      progessBarLogoStyle: 'img-sizing-svg',
      isMitekProcessReached: false,
      isMobileOTPVerified: false,
      isEmailOTPVerified: false,
      isUserExists: false,
      isNewAccountValue: false,
      sectionHeadingValue: '',
      sectionContextValue: '',
      isFrequencyExists: false,
      isAmendButtonClicked: false,
      emailCheckConsentSrc: 'images/unchecked.svg',
      smsCheckConsentSrc: 'images/unchecked.svg',
      mobileCheckConsentSrc: 'images/unchecked.svg',
      submitSectionFlag: false,
      sendButtonDisable: false,
      showContinueAppButton: false,
      isFormValidated: false,
      inputValue: '',
      mobileValueUpdatedTimeStamp: '',
      verifyMessage: '',
      finalinputValue:'',
      failedfieldValidationCollection: [],
      isShowDecommissionMessage:true,
      isExistingUserChangeEmailOption:false,
      isEmptyChangeEmail: false,
      openBankingRedirectURL:"",
      decisionByLendingMetrics:false,
      openWarningOBConsent:false,
      obStatusRecived:false,
      obStatus:"",
      messageTitle: '',
      reachLimitOTPVerify:false,
      retryLimit:0,
      changedValueAfterComplete:false,
      retryLimitTogetRetailerID:0,
      onBlurInputFiled:false,
      disableMobileNumberField: localStorage.getItem('fromWhere') !== 'apply_now',
      invalidTitlesInFirstName: false
    }
    this.reCaptchaRef = null;
    this.bottomScrollRef = React.createRef();
    this.observer = null;
    this.checkIntervalMobileChange = '';
    this.checkIntervalEmailAddress = '';

    this.removeFromHistory = this.removeFromHistory.bind(this);
    this.isDateval = this.isDateval.bind(this);
    this.getUserValidated = this.getUserValidated.bind(this);
    this.prepopulateUserPersonalDetails = this.prepopulateUserPersonalDetails.bind(this);
    this.validationCheckForPrepopulateData = this.validationCheckForPrepopulateData.bind(this);
    this.timer = null; // Initialize a timer variable

  }

  componentDidMount() {
    handleRefreshTheScreen('application')
    window.addEventListener('popstate', handlePopstate);

    const cookie = new Cookies();
    cookie.remove('applicationId')
    
    this.handlingOBSuccessResponse();

    // Push the customer id and loginState if available on this page to the dataLayer
    window.dataLayer = window.dataLayer || {}; 
    GTMUserIdAndLoginState();

    
    if(localStorage.getItem('fromWhere')=='skip'){
      localStorage.setItem('sessionId', '')
    }

    // Validation for each section input fields is initialized
    this.props.firstSectionStepsReset([]);
    this.props.secondSectionStepsReset([]);
    this.props.thirdSectionStepsReset([]);
    this.props.fourSectionStepsReset([]);
    this.props.resetSectionHeading([]);
    this.props.resetSectionContext([]);

    this.props.resetMobileChange(false);
    this.props.resetEmailChange(false);
    this.props.resetFrequencyExists(false);
    this.props.removeAgreeLabel('');
    this.props.removeDocumentAgreeLabel('');
    this.props.removeContactHummAgreeLabel('');
    this.props.resetMobileVerified();
    this.props.resetEmailVerified();

    this.props.removeQuestionSave([]);
    this.props.appValidationsRemove([]);
    this.props.showappValidation('');
    this.props.getAmountRequested('');
    this.props.removeSavedata([]);
    this.props.showAddress('');

    this.props.firstNameFlagStatusCall(false);
    this.props.lastNameFlagStatusCall(false);
    this.props.emailFlagStatusCall(false);
    this.props.mobileNumberFlagStatusCall(false);
    this.props.birthDateFlagStatusCall(false);
    window.addEventListener("popstate", this.removeFromHistory);
    window.addEventListener('resize', this.handleWindowSizeChange);

    localStorage.setItem('ApplicationErrors', '');

    localStorage.setItem('flxPersonEmail__c', '')
    this.openModal();

    if(this.props.phoneNumbers !== ''){
      this.setState({phoneNo: this.props.phoneNumbers});
    }

    $(window).on("beforeunload", function() {
      localStorage.removeItem('applicationId');

      // Remove user's session and remaining details on application page reload or refresh for apply now user
      if(localStorage.getItem('fromWhere')=='apply_now'){
        localStorage.removeItem('sessionId')
        localStorage.removeItem('token');
        localStorage.removeItem('email')
        localStorage.removeItem('UserName');
        localStorage.removeItem('csrfToken');
        localStorage.removeItem('userID');
      }
    })

    if(localStorage.getItem('fromWhere') == 'apply_now'){
      this.setState({ isEmailConsentChecked: false, isMobileAppConsentChecked: false,isSMSConsentChecked: false })
      localStorage.removeItem('smsConsent')
      localStorage.removeItem('mobileConsent')
      localStorage.removeItem('emailConsent')
    }

    const optionsForScrollObserve = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, 
    };

    if (typeof IntersectionObserver !== `undefined`) {
      this.observer = new IntersectionObserver(this.handleScrollBottomObserver, optionsForScrollObserve);
      this.observer.observe(this.bottomScrollRef.current);
    }

    window.scrollTo(0, 0);
    this.addQuoteAmountTermInLink();        
  }

  // Add quote amount and term in retailer link
  addQuoteAmountTermInLink = ()=>{
    try {
      const { pathname, search } = this.props.location;
    let browserPathRequest = {
      pathname : pathname,
      search: search,
      QuoteAmount : localStorage.getItem('quote-loan-amount'),
      QuoteTerm: localStorage.getItem('quote-loan-term')
    }
    if(localStorage.getItem('quote-loan-amount') && localStorage.getItem('quote-loan-term')){
      frontEndLogs('Browser path history',false,200, 'addQuoteAmountTermInLink',browserPathRequest)
    if(search){
    const urlParams = new URLSearchParams(window.location.search)
        const loanamt = urlParams.get('loanAmt');
        const loanTerm = urlParams.get('term');
        if(!loanamt && !loanTerm){  
          urlParams.append('loanAmt', localStorage.getItem('quote-loan-amount'));
          urlParams.append('term', localStorage.getItem('quote-loan-term'));
          this.props.history.push({ pathname: pathname, search: urlParams.toString()})
        }
    }
    else if(localStorage.getItem('sessionId')) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('loanAmt', localStorage.getItem('quote-loan-amount'));
      urlParams.set('term', localStorage.getItem('quote-loan-term'));
      this.props.history.push({ pathname: pathname, search: urlParams.toString()})
    }
    frontEndLogs('Updated Browser path history',false,200, 'addQuoteAmountTermInLink',window.location.href)
  }
    } catch (error) {
      frontEndLogs('Catch Error - Add Quote amount and term ',true,400, 'addQuoteAmountTermInLink',error?.message)
    }
  }

  // The Humm retailer link send when creating application
  setHummRetailerLink = ()=>{
    try {
      const { pathname, search } = this.props.location;
      let branchReferrer = window.location.search.includes('_branch_referrer');
      let branchTrackingId = window.location.search.includes('trackingId');
      
      let createAppBrowserPathRequest = {
        pathname : pathname,
        search: search,
        QuoteAmount : localStorage.getItem('quote-loan-amount'),
        QuoteTerm: localStorage.getItem('quote-loan-term')
      }
      
    if(localStorage.getItem('quote-loan-amount') && localStorage.getItem('quote-loan-term')){
      frontEndLogs('Browser path history',false,200, 'setHummRetailerLink',createAppBrowserPathRequest)
        if(search){
        const urlParams = new URLSearchParams(window.location.search)
        const quoteLoanAmt = urlParams.get('loanAmt');
        const quoteLoanTerms = urlParams.get('term');
        if(quoteLoanAmt && quoteLoanTerms){
          return window.location.href;
        }
      }
    }else if(branchReferrer && !branchTrackingId){
      return window.location.href;
    }
    return null;
    } catch (error) {
      frontEndLogs('Catch Error - Send retailer link with quote amount and term ',true,400, 'setHummRetailerLink',error?.message)
    }
  }

  handleScroll= (event) =>{
    // Scroll event to show heading and content
    setTimeout(() => {
      this.validationOfProgressIndicatorSections(event);
      }, 200);
      
}

  openModal=()=>{
    if(localStorage.getItem('fromWhere')=='skip' && localStorage.getItem('showPopup')=='true'){ 
      setTimeout(() => {
        window.$("#sessionExpiredModal").modal("show");
      localStorage.setItem('showPopup','false');
    }, 1000);
    }
    localStorage.setItem('showPopup','false');
  }

  clearEmailAddressAndHighlightField = () => {
    this.setState({loading: true});

    const inputElement = document.getElementById('Email_Address');
    inputElement.style.border = "1px solid #ff6200";
    let inputValues = {}
    inputValues['Email_Address'] = '';
    this.props.saveApplicationDatas(inputValues);

    setTimeout(() => {
      if(this.state.isExistingUserChangeEmailOption){
        this.props.updateEmailChange(false);
        this.setState({isExistingUserChangeEmailOption:false})
      }else{
        this.props.updateEmailChange(true);
      }

      this.setState({loading: false})
      inputElement.focus();
    }, 2500);
  }

  isDateval=(dateStr)=>{
    return !isNaN(new Date(dateStr).getDate());
  }

  handleScrollBottomObserver = (entries) => {
    const bottomScrollApplyButton = entries[0];
    if (bottomScrollApplyButton.isIntersecting) {
      setTimeout(() => {
        this.setState({ isDisableContinue: false });
      }, 400);
    }
  };

  handlechangeemailNotTriggred = (e) => {
    this.setState({ loading: true });
    const userDetails = Object.assign({}, ...this.props.saveApplicationData);
   
    this.setState({email: userDetails.Email_Address}, () => {
      localStorage.setItem('email', this.state.email);
    });
    
    this.checkEmailAddress();
    

  };

  mitekVerificationLinkForUser = async() => {
    this.setState({ sendButtonDisable: true});

    const requestPayload = {
      "application_id": await this.getApplicationIDInstorage(),
      "email":  localStorage.getItem('email') ?? this.state.email,
      "mobile_number": this.state.phoneNo,
      "userId": localStorage.getItem('userID'),
    }

    this.setState({ loading: true, popupButtonLabel:'', validateStatusCode: '' });
    const responseResult = await mitekVerificationLinkSentToMobile(requestPayload);

    if(responseResult.status === 200) {
      if(responseResult.data.data.isSendLink) {
        // disable the send button as the mitek Verification link is already sent for the user
        this.setState({ serverErrorMessage: responseResult.data.message, loading: false, sendButtonDisable: true });
      }
      else {
        this.setState({ serverErrorMessage: responseResult.data.message, loading: false, sendButtonDisable: false });
      }
      window.$('#serverErrorShowModel').modal('show');
    }
    else if(handleStatusCode(responseResult.status)){
      this.setState({ serverErrorMessage: responseResult.data.message, loading: false, sendButtonDisable: true });
      window.$('#serverErrorShowModel').modal('show');
    }

  }

  componentWillUnmount() {
    this.props.appValidationsRemove([]);
    this.props.showappValidation('');
    window.removeEventListener('resize', this.handleWindowSizeChange);
    
    if(window.location.pathname.split('/')[1] !== 'application') {
      localStorage.removeItem('applicationId');
    }

    if(this.disconnect) {
      this.observer.disconnect();
    }

    clearInterval(this.checkIntervalMobileChange);
    clearInterval(this.checkIntervalEmailAddress);


    const applicationBodyElement = document.querySelector('body');
    applicationBodyElement.classList.remove('applicationPage');
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  analyticsClickEvent = (data) => {
    if (data === 'serverErrorShowModel') {
      analyticsClickEvent('serverError_popup_ok','')
    } else if (data === 'fieldErrorShowModel') {
      analyticsClickEvent('fieldError_popup_ok','')
    }
  }

  removeFromHistory() {

    if (window.$('#forgotModal').hasClass('show') === false) {
      window.$('#forgotModal').modal('hide');
      window.$('.modal-backdrop').removeClass('show');
      window.$('body').removeClass('modal-open');
      window.$('.modal-backdrop').remove();
    }
  }

  redirectToMobileVerification(){
    window.$('#serverErrorShowModel').modal('hide');
    analyticsClickEvent('mobile_number_does_not_exist_ok','');
    this.props.history.push('mobile-verification');
  }

  loggedUserApplyNow = async () => {
    this.setState({reCaptchaToken: ''});

    if(this.reCaptchaRef !== null && this.reCaptchaRef !== undefined){
      await this.reCaptchaRef.execute();
    }
    this.setState({loggedUserApplyNowFlag: true});
  }

  setValueOfShowMobileAndEmailAddress = () => {
    if(this.state.stepZeroData !== '' && this.state.stepZeroData !== undefined){
      this.state.stepZeroData.map((src) => {
          src.Questions.map((eachQuestion) => {
            if(eachQuestion.flxFieldApiName__c === 'Mobile_Number' || eachQuestion.flxFieldApiName__c === 'Email_Address')
            {
                this.setState({showMobileandEmailAddress: true})
              }
          })
        })
    }
  }

  isFrequencyExistInSecondSection = () => {
    if(this.state.stepZeroData !== '' && this.state.stepZeroData !== undefined){
      this.state.stepZeroData.map((src) => {
          src.Questions.map((eachQuestion) => {
            if(eachQuestion.flxFieldApiName__c === 'frequency')
            {
                this.props.updateFrequencyExists(true);
            }
          })
        })
    }
  }

  setValueOfSectionHeadingAndSubContext = (sectionData) => {
    let allSectionHeading = Object.assign({}, ...this.props.sectionHeading);
    let allSectionContext = Object.assign({}, ...this.props.sectionSubContext);

    if(sectionData !== '' && sectionData !== undefined) {
      sectionData.map((src, i) => {
        if(src.flxHeader__c !== '') {
          if(src.flxHeader__c.includes('Personal') || src.flxHeader__c.includes('About')) {
            allSectionHeading[0] = src.flxHeader__c
            allSectionContext[0] = src.flxSectionBodyRich__c
          }
          else if(src.flxHeader__c.includes('Loan')) {
            allSectionHeading[1] = src.flxHeader__c
            allSectionContext[1] = src.flxSectionBodyRich__c
          }
          else if(src.flxHeader__c.includes('Address')) {
            allSectionHeading[2] = src.flxHeader__c
            allSectionContext[2] = src.flxSectionBodyRich__c
          }
          else if(src.flxHeader__c.includes('Income')) {
            allSectionHeading[3] = src.flxHeader__c
            allSectionContext[3] = src.flxSectionBodyRich__c
          }
          
          this.props.updateSectionHeading(allSectionHeading)
          this.props.updateSectionContext(allSectionContext)
        }
      })

    }

  }

  // This method is called when the Apply Now button is clicked on the Important Information page of the application
  getUserValidated = async() => {
    
    if(!localStorage.getItem('user_tracking_id')) {
      const trackingId = uuidv4();
      localStorage.setItem('user_tracking_id', trackingId);
    }
    
    // remove the application id from the localStorage
    localStorage.removeItem('applicationId');

    let consents = {
      'Mobile_Consent':localStorage.getItem('mobileConsent'),
      'Email_Consent':localStorage.getItem('emailConsent'),
      'SMS_Consent':localStorage.getItem('smsConsent')
    }
    frontEndLogs('Marketing Consent ',false,200, 'getUserValidated',consents);
    if(localStorage.getItem('mobileConsent')){
      frontEndLogs('Mobile App consent is '+ localStorage.getItem('mobileConsent'),false,200, 'getUserValidated')
    }
    
    if(localStorage.getItem('emailConsent')){
      frontEndLogs('Email consent is '+ localStorage.getItem('emailConsent'),false,200, 'getUserValidated')
    }

    if(localStorage.getItem('smsConsent')){
      frontEndLogs('SMS consent is '+ localStorage.getItem('smsConsent'),false,200, 'getUserValidated')
    }

    //Initialise the minimum and maximum loanAmount range as empty
    localStorage.setItem('maxLoanAmountRange', '');
    localStorage.setItem('minLoanAmountRange', '');

    if(localStorage.getItem('fromWhere') == 'apply_now'){
      analyticsClickEvent('apply_now_user_continue_button', '');
      await frontEndLogs('apply_now_user_continue_button_clicked', false, 200, 'start_application_page');
      const result = await this.props.questions();
      if(result?.data !== undefined){
        const details = result.data.data[0].sections.filter((drink, index) => index === 1);

        this.setState({ stepZeroData: details }, () => {
          this.setValueOfShowMobileAndEmailAddress()
          this.setValueOfSectionHeadingAndSubContext(this.state.stepZeroData)
        });

        const resultTwo = result.data.data[0].sections.filter((drink ,index) => index === 2);

        this.setState({secondSection: resultTwo, sectionTwoLength: Object.keys(resultTwo[0]['Questions']).length}, () => {
          this.isFrequencyExistInSecondSection()
          this.setValueOfSectionHeadingAndSubContext(this.state.secondSection);
        });

        const resultThree = result.data.data[0].sections.filter((drink ,index) => index === 3);
        this.setState({thirdSection: resultThree}, () => {
          this.setValueOfSectionHeadingAndSubContext(this.state.thirdSection);
        });

        const resultFour = result.data.data[0].sections.filter((drink ,index) => index === 4);
        this.setState({fourSection: resultFour}, () => {
          this.setValueOfSectionHeadingAndSubContext(this.state.fourSection);
        });


        firebaseLoadingEvent('apply_now_user_loaded','')
        this.props.removeSavedata([]);
        this.props.showappValidation('');
        setTimeout( async() => {
          
          this.setState({privacyPolicyCheck: true});
          this.showSectionHeading();
          this.showSectionSubContext();
          window.scrollTo(0, 0);
          this.setState({loading: false});
          await frontEndLogs('apply_now_questions_loaded',false,200,'application_flow');

          const applicationBodyElement = document.querySelector('body');
          applicationBodyElement.classList.add('applicationPage');

        }, 300);
      }
      return false;
    }
    else if(localStorage.getItem('sessionId') !== '' && localStorage.getItem('sessionId') !== null){
      analyticsClickEvent('logged_user_apply_now_button','');

      // update marketing consent for the login user
      this.setState({
        isEmailConsentChecked: localStorage.getItem('emailConsent') === 'true',
        isMobileAppConsentChecked: localStorage.getItem('mobileConsent') === 'true',
        isSMSConsentChecked: localStorage.getItem('smsConsent') === 'true',
      })

      await frontEndLogs('logged_user_apply_now_button_clicked',false,200,'start_application_page');
      let applicationId = await this.getApplicationIDInstorage()
      if(applicationId !== null && applicationId !== undefined) {
        await this.continueApplicationJourney();
        return false;
      }

      this.setState({loggedUserApplyNowFlag: false});

      this.checkApplicantEligiblityForUser();
      
    }

  }

  loadQuestionsForLoginFlow = async() => {
    this.props.removeSavedata([]);
    this.props.showappValidation('');
    const result = await this.props.questions();
      if(result?.data != undefined){
        const detailsOfFirstSection = result.data.data[0].sections.filter((drink, index) => index === 1);

        this.setState({ stepZeroData: detailsOfFirstSection }, () => {
          this.setValueOfShowMobileAndEmailAddress()
          this.setValueOfSectionHeadingAndSubContext(this.state.stepZeroData)
        });

        const detailsOfSecondSection = result.data.data[0].sections.filter((drink ,index) => index === 2);

        this.setState({secondSection: detailsOfSecondSection, 
          sectionTwoLength: Object.keys(detailsOfSecondSection[0]['Questions']).length}, () => {
          this.isFrequencyExistInSecondSection()
          this.setValueOfSectionHeadingAndSubContext(this.state.secondSection);
        });

        const detailsOfThirdSection = result.data.data[0].sections.filter((drink ,index) => index === 3);
        this.setState({thirdSection: detailsOfThirdSection}, () => {
          this.setValueOfSectionHeadingAndSubContext(this.state.thirdSection);
        });

        const detailsOfFourthSection = result.data.data[0].sections.filter((drink ,index) => index === 4);
        this.setState({fourSection: detailsOfFourthSection}, () => {
          this.setValueOfSectionHeadingAndSubContext(this.state.fourSection);
        });
      }

      return false
  }

  checkUserDataIsEmpty = (userData, fieldName) => {
    let prepopulateValues = {}

    if(userData !== '' && userData !== null) {
      prepopulateValues[fieldName] = userData

      if(fieldName === 'First_Name') {
        this.props.firstNameFlagStatusCall(true);
      }
      if(fieldName === 'Last_Name'){
        this.props.lastNameFlagStatusCall(true);
      }
      if(fieldName === 'Email_Address') {
        this.props.emailFlagStatusCall(true);
      }
      if(fieldName === 'Mobile_Number') {
        this.props.mobileNumberFlagStatusCall(true);
      }
      
    }
    
    setTimeout(() => {
      this.props.saveApplicationDatas(prepopulateValues)
    }, 200);
    return false;
  }


  prepopulateUserPersonalDetails = (prepopulateData, fieldName) => {

    let prepopulateValues = {}
    if(fieldName == 'flxFirst_Name__c') {
      prepopulateValues['First_Name'] = prepopulateData[fieldName];
    }
    if(fieldName == 'flxLast_Name__c') {
      prepopulateValues['Last_Name'] = prepopulateData[fieldName];
    }
    if(fieldName == 'flxPersonEmail__c') {
      prepopulateValues['Email_Address'] = prepopulateData[fieldName];
    }
    if(fieldName == 'flxBirthDate__c') {
      prepopulateValues['Birth_Date'] = prepopulateData[fieldName];
    }
    if(fieldName == 'flxpersonmobile__c') {
      prepopulateValues['Mobile_Number'] = prepopulateData[fieldName];
    }

    this.props.saveApplicationDatas(prepopulateValues);
  }

  validationCheckForPrepopulateData = (prepopulatedData, keyName) => {
    let errorPrePopulate = {}
    if(keyName == 'flxFirst_Name__c' && prepopulatedData[keyName] !== undefined) {
      errorPrePopulate['First_Name'] = '';
    }
    if(keyName == 'flxLast_Name__c' && prepopulatedData[keyName] !== undefined) {
      errorPrePopulate['Last_Name'] = '';
    }
    if(keyName == 'flxPersonEmail__c' && prepopulatedData[keyName] !== undefined) {
      errorPrePopulate['Email_Address'] = '';
    }
    if(keyName == 'flxBirthDate__c' && prepopulatedData[keyName] !== undefined) {
      errorPrePopulate['Birth_Date'] = '';
    }
    if(keyName == 'flxpersonmobile__c' && prepopulatedData[keyName] !== undefined) {
      errorPrePopulate['Mobile_Number'] = ''
    }

    this.props.appvalidations(errorPrePopulate);
  }

  generatePurchaseRedirectURL = () => {
    const cookie = new Cookies()

    if(cookie.get('sourceURL') !== '' && cookie.get('sourceURL') !== undefined){
      let resultSourceURL = decodeURIComponent(cookie.get('sourceURL'));

      this.setState({resultSourceURL});
    }

  }

  userDetailsPopulateForExistingUser = async() => {
    
    const requestObject = {
      email: localStorage.getItem('email'),
      Application_Channel: "Web"
    }
    
    let prepopulateValues = {}
    const result = await getAvatar(requestObject);
    if(result.status==200 && Object.keys(result.data.data).length !== 0){
        this.checkUserDataIsEmpty(result?.data?.data?.first_name, "First_Name");
        this.checkUserDataIsEmpty(result?.data?.data?.last_name, "Last_Name");
        this.checkUserDataIsEmpty(result?.data?.data?.email, "Email_Address");
        this.checkUserDataIsEmpty(result?.data?.data?.mobile_number, "Mobile_Number");

        if(result.data.data.personbirthdate !== null && result.data.data.personbirthdate !== '') {
          this.props.saveDate(convertDateFormat(result.data.data.personbirthdate));
          this.props.birthDateFlagStatusCall(true)
          prepopulateValues['Birth_Date'] = convertDateFormat(result.data.data.personbirthdate)
        }

        this.props.saveApplicationDatas(prepopulateValues)        


        setTimeout(() => {
          
          this.setState({privacyPolicyCheck: true});
          this.showSectionHeading();
          this.showSectionSubContext();
          window.scrollTo(0, 0);
          this.setState({loading: false});
        }, 1400);
    }
  }

  PrepopulateHideData = async () => {
    let prepopulateData = [];
    this.props.applicationValidation.map((appValidationData) => {
      let appValidationStageDatas = {};
      appValidationStageDatas[Object.keys(appValidationData)[0]] = appValidationData.stage
      prepopulateData.push(appValidationStageDatas)
    });


    if(this.state.prepopulateFlag){
        const prepopulateResult = await prepopulate();
        if(prepopulateResult.status === 200) {
          this.props.prepopulateStatusCall(true);
          this.setState({prepopulateFlag: false})        
        }
        return await this.handlePrepopulateDetailsInApplication(prepopulateResult,prepopulateData);
    } 
    
  }

  handlePrepopulateDetailsInApplication = async (prepopulateResult,prepopulateData) => {
    let self = this.props;
    if (prepopulateResult.data.data !== '') {
      const prepopulateDataFilter = Object.assign({}, ...prepopulateData);
      for (let key of Object.keys(prepopulateResult.data.data)) {
        if (prepopulateResult.data.data[key]) {
          let prepopulateValues = {}
           prepopulateValues[key] = this.handleSetPrepopulateValuesInField(prepopulateValues,key,prepopulateResult,self);
          if(Object.keys(prepopulateValues).length === 0){
            return false;
          }
          prepopulateValues[key] = prepopulateResult.data.data[key];
          
          prepopulateValues = removeSpecialCharacterInAddressFields(prepopulateValues,key,prepopulateResult,'prePopulate');
          
          self.saveApplicationDatas(prepopulateValues);
          if (prepopulateDataFilter[key] != undefined) {
            let errorPrepopulate = {};
            errorPrepopulate[key] = '';
            errorPrepopulate['stage'] = prepopulateDataFilter[key];
            self.appvalidations(errorPrepopulate);
          }
        }
      };
      setTimeout(() => {
        
        this.setState({privacyPolicyCheck: true});
        this.showSectionHeading();
        this.showSectionSubContext();
        window.scrollTo(0, 0);
        this.setState({loading: false});
      }, 1400);
    }
  }

  handleSetPrepopulateValuesInField = async(prepopulateValues,key,prepopulateResult,self) => {
    if (key === 'flxBirthDate__c' || key === 'time_in_occupation' || key === 'genesis__bank_hours__c') {
      const dayMonthPart = `(0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02`;
      const yearPart = `[0-9]{4}`;
      const dateRegex = new RegExp(`^(${dayMonthPart})[-/]?(${yearPart})$`);

      const MonthPart = `29[-/]?02`;
      const leapYearPart = `(?:\\d{2}(?:[02468][048]|[13579][26])|(?:[02468][048]|[13579][26])00)`;
      const leapYearRegex = new RegExp(`^(${MonthPart})[-/]?(${leapYearPart})$`);

      const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
    
      if (dateRegex.test(prepopulateResult.data.data[key]) || leapYearRegex.test(prepopulateResult.data.data[key]) || monthYearRegex.test(prepopulateResult.data.data[key])) {
        console.log('check true');
      } else {
        return prepopulateValues;
      }
    }

    if (key === 'flxBirthDate__c') {
      self.saveDate(prepopulateResult.data.data[key])
    }
    if(key==="sortcode_bankaccount"){
      prepopulateResult.data.data[key] = prepopulateResult.data.data[key].replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
    }
    if(key === 'flxFirst_Name__c') {
      prepopulateValues['First_Name'] = prepopulateResult.data.data[key];
      self.firstNameFlagStatusCall(true);
    }
    if(key === 'flxLast_Name__c') {
      prepopulateValues['Last_Name'] = prepopulateResult.data.data[key];
      
    } 
    if(key === 'flxPersonEmail__c') {
      prepopulateValues['Email_Address'] = prepopulateResult.data.data[key];
      self.emailFlagStatusCall(true);
    }
    if(key === 'flxBirthDate__c') {
      prepopulateValues['Birth_Date'] = prepopulateResult.data.data[key];
      self.birthDateFlagStatusCall(true)
    }
    if(key === 'flxpersonmobile__c') {
      prepopulateValues['Mobile_Number'] = prepopulateResult.data.data[key];

      self.mobileNumberFlagStatusCall(true)
    }
    return prepopulateValues;
  }

  monthDiff = (d1) => {
    if (d1 === null) {
      return 0;
    }
    let today = new Date();
    let months;
    months = (today.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += today.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }


  getLabelName=(fieldName)=>{
    let LabelNames='';
    this.props.questionValueSave.map((src) => {
      if(src['field_api_name']===fieldName){
        LabelNames=src['field_question'];
      }
    });
    return LabelNames;
  }

  onlyAcceptInputs = (e) => {
    if (!/\d/.test(e.clipboardData.getData('Text')) || (/[a-zA-Z]/.test(e.clipboardData.getData('Text'))) ) {
        e.preventDefault();
    }
  }

  getMonthDifferenceForDate = (savedUserData) => {
    let monthDiffValue;

    if (savedUserData['date_moved_in_current_address'] && savedUserData['date_moved_in_current_address'] != '' && savedUserData['flxBirthDate__c'] != undefined) {
      let monthdiffData = savedUserData['date_moved_in_current_address'].split('/');
      let date_moved_in_current_address = monthdiffData[0] + '/' + '01' + '/' + monthdiffData[1];
      monthDiffValue = this.monthDiff(new Date(date_moved_in_current_address));
    }
    return monthDiffValue;
  }


  addApplicationProcessSave = (completedSteps, steps= '', stage = '') => {
    this.props.showAddress('');
    let errorDataShow='';
    const accountValue = this.props.amountRequested;
    const savedData = Object.assign({}, ...this.props.saveApplicationData);
    const validationData = Object.assign({}, ...this.props.applicationValidation);
    let applicationSaveProcess = [];
    let monthDiffValue = 6;
    
    monthDiffValue = this.getMonthDifferenceForDate(savedData);

    if (completedSteps === steps) {
      this.props.questionValueSave.map((src) => {
        if (src.stage == stage) {
          if (accountValue > this.props.threashold) {
          
            const completedStepsIfPartResult = this.completedStepsIfPart(src,monthDiffValue, savedData, validationData, applicationSaveProcess)
            applicationSaveProcess = completedStepsIfPartResult['applicationSaveProcess']
          } else {
           
            const completedStepsResult = this.completedStepsElsePart(src,monthDiffValue,savedData, validationData, applicationSaveProcess)

            applicationSaveProcess = completedStepsResult['applicationSaveProcess']
          }
        }
      })
    }

    if (completedSteps === 'three') {
      this.props.questionValueSave.map((src) => {
        if (src.stage == '2') {
          this.completedStepsThreeSortCodeValidation(src, savedData);
          
          applicationSaveProcess = this.completedStepsThreeEmploymentValidation(src,savedData, validationData,applicationSaveProcess)
        }
      })
    }

    errorDataShow = this.stepTwoAddressFieldValidation(completedSteps, monthDiffValue, savedData, errorDataShow)
    if(errorDataShow===''){
      return applicationSaveProcess;
    }else{
      const emptyArrayForError = []
      return emptyArrayForError;
    }
  }

  handleUserDetails = async(details)  => {
     const cookie = new Cookies();
     if(localStorage.getItem('fromWhere') === 'apply_now' && localStorage.getItem('sessionId') !== null) {
        localStorage.removeItem('sessionId')
        localStorage.removeItem('token');
        localStorage.removeItem('email')
        localStorage.removeItem('UserName');
        localStorage.removeItem('csrfToken');
        localStorage.removeItem('userID');
      }

      let userDetails = details;
      this.setState({email: userDetails.Email_Address}, () => {
        localStorage.setItem('email', this.state.email);
      });
      this.setState({phoneNo: userDetails.Mobile_Number});

      userDetails.Application_Channel = "Web";
      userDetails.recaptchaToken = this.state.reCaptchaToken;
      userDetails.Retailer_Account__c = cookie.get('retailerId')
      userDetails.isMobileVerified = this.state.isMobileVerified

      if(this.state.userToken !== '' && this.state.userToken !== undefined){
        userDetails.dataToken = this.state.userToken
      }

      const result = await validatePersonalDetails(userDetails);

      this.setState({userDetailsFlag: false});

      if (result?.data?.statusCode !== undefined) {
        this.setState({loading: false})
        if(result.data.statusCode === 200 && result.data.data.dataToken !== ''){
          this.setState({userToken: result.data.data.dataToken })
          this.setState({codeError: ''});

          await this.redirectionOfApplyNowUser(result.data.data);
        } 
        else if(result.data.message !== undefined){
          let resultMessage = result.data.message;
          resultMessage = this.convertMessage(resultMessage);
          this.setState({serverErrorMessage : resultMessage});

          GTMServerSubmitError('Step 1', 'server',resultMessage, 1, 4, '1 : 4')
          window.$('#serverErrorShowModel ').modal('show')
        }
      } 
  }

  redirectionOfApplyNowUser = async(resultData) => {
    if(resultData.sendOtpTo === 'Mobile'){
      await frontEndLogs('mobile verification popup displayed '+this.state.phoneNo,false,200,'guest user');
      window.$('#mobileModal').modal('show')
      return false;
    }
    if(resultData.sendOtpTo === 'Email'){
      await frontEndLogs('email verification popup displayed '+this.state.email,false,200,'guest user');
      window.$('#registerModal').modal('show')
      return false;
    }
    if(resultData.showMarketingConsent){
      window.$('#marketingConsentModal').modal('show')
      return false;
    }
    else{
      localStorage.setItem('token',resultData.access_token);
      localStorage.setItem('email',resultData.user_details.email);
      localStorage.setItem('UserName',resultData.user_details.username);
      localStorage.setItem('sessionId',resultData.session_id);
      localStorage.setItem('csrfToken',resultData.csrf_token);
      localStorage.setItem('userID',resultData.user_details.userid);

      GTMUserIdAndLoginState();
      GTMUserId();
      await this.checkApplicantEligiblityForUser();
    }
  }

  checkIfButtonLabelAndRedirectUrLExists = (dataResponse) => {

    if(dataResponse.buttonLabel !== '' && dataResponse.buttonLabel !== null && dataResponse.buttonLabel !== undefined) {
      this.setState({ popupButtonLabel: dataResponse.buttonLabel});
    }

    if(dataResponse.redirectUrl !== '' && dataResponse.redirectUrl !== null && dataResponse.redirectUrl !== undefined) {
      this.setState({ redirectURL: dataResponse.redirectUrl});
    }
  }

  validateStepConditions(steps, vals, completedSteps) {
    return steps[vals] !== '' && steps[vals] !== undefined && completedSteps === 'two';
  }

  handleNameCount(nameCount,labelNames, vals) {
    if (nameCount <= 2) {
      const labelName = this.getLabelName(vals);
      if (labelName !== undefined) {
        nameCount++;
        labelNames.push(labelName);
      }
    }
    return {nameCount : nameCount,labelNames:labelNames}
  }

  handleErrorCount(errorCount) {
    errorCount++;
    return errorCount;
  }
  
  incrementStepOne(stepone) {
    stepone++;
    return stepone;
  }

  updateStateAndProps() {
    this.setState({ displayErrorsStepOne: 'errors' });
    this.props.showappValidation('yes');
  }

  handleApplicationSteps = async(stepone,steptwo,errorCount,nameCount,LabelNames,completedSteps) => {
    this.props.applicationValidation.map((steps) => {
      let vals = Object.keys(steps)['0'];
      if (steps['stage'] === '1' && this.validateStepConditions(steps, vals, completedSteps)) {
       let checkNameCount =  this.handleNameCount(nameCount,LabelNames, vals);
       nameCount = checkNameCount.nameCount;
       LabelNames = checkNameCount.labelNames;
       errorCount = this.handleErrorCount(errorCount);
       stepone = this.incrementStepOne();
        this.updateStateAndProps();
      }

      if (steps['stage'] === '2') {
        steptwo++;
        if(steps[vals]!='' && steps[vals]!=undefined &&  completedSteps !== 'two'){
          if(nameCount<=2){
            let label_name=this.getLabelName(vals);
            if(label_name!=undefined){
            nameCount++;
            LabelNames.push(label_name);
            }
          }
          errorCount++;
        }
        this.setState({ displayErrorsStepTwo: 'erros' });
        this.props.showappValidation('yes');
      }
      if (steps['stage'] === '3') {
        steptwo++;
        this.setState({ displayErrorsStepThree: 'erros' });
        this.props.showappValidation('yes');
      }
    });
    return {stepone:stepone,steptwo:steptwo,errorCount:errorCount,nameCount:nameCount,LabelNames:LabelNames}
  }

 

  nextStep = async (completedSteps, e) => {
    window.dataLayer = window.dataLayer || {}; 
    let applicationProcessBasedResult;
    window.$('#userNoteShowModel').modal('hide')
    if(completedSteps == "two"){
      applicationProcessBasedResult = Promise.resolve(this.addApplicationProcessSave(completedSteps,'two', '1')); 
    }
    else {
      applicationProcessBasedResult = Promise.resolve(this.addApplicationProcessSave(completedSteps));
    }
    
    applicationProcessBasedResult = await applicationProcessBasedResult.then((value) => {
      return value;
    })
   
    this.setState({ unableToProvide: false });
    const { step } = this.state;
    if (this.props.applicationValidation.length === '0') {
      this.props.hideappValidation('');
      this.setState({
        step: step + 1
      });
      return false;
    }

    let stepone = 0;
    let steptwo = 0;
    let errorCount=0;
    let nameCount=0;
    let LabelNames=[];
    let applicationStepsResult = await this.handleApplicationSteps(stepone,steptwo,errorCount,nameCount,LabelNames,completedSteps);
    nameCount = applicationStepsResult.nameCount;
    LabelNames = applicationStepsResult.LabelNames;
    errorCount = applicationStepsResult.errorCount;
    stepone = applicationStepsResult.stepone;
    steptwo = applicationStepsResult.steptwo;
    setTimeout(() => {
      if(this.state.errorCount >=1 && this.state.step === 3){
        if(localStorage.getItem('fromWhere') === 'apply_now'){
          GTMClientSubmitErrors('Step 3', 'client', this.state.errorCount, 3, 4, '3 : 4')
        } else {
          GTMClientSubmitErrors('Step 2', 'client', this.state.errorCount, 2, 3, '2 : 3')
        }
  
          return false;
      }
    }, 1000);
    this.setState({errorCount:errorCount})
    this.setState({LabelNames:LabelNames})
    this.setState({requiredFieldCount:nameCount})
    if(Object.keys(applicationProcessBasedResult).length === 0){
      window.$('#fieldErrorShowModel').modal('show')
      return false;
    }
    await this.applicationValidationInProps(stepone,steptwo,completedSteps,applicationProcessBasedResult,step);
  };

  applicationValidationInProps = async (stepone,steptwo,completedSteps,applicationProcessBasedResult,step) => {
    let j = 0;
    this.props.applicationValidation.map(async (src) => {
      let val = Object.keys(src)['0'];
      let stageOneResult = await this.handleStageOneValidation(src,val,stepone,completedSteps,applicationProcessBasedResult,step);
      if(stageOneResult === false){
        return stageOneResult;
      }

      if (src[val] !== '' && src['stage'] === '2') {
        // j = j - 1;
        let jsub = j - 1;
        j = jsub;
      }

      let moveToStepThree = src[val] === '' && src['stage'] === '2' && completedSteps !== 'two' && localStorage.getItem('sessionId') !== null;
      let checkErrorInStepTwo = this.checkstepTwoApiResponse(moveToStepThree,completedSteps);
      if(checkErrorInStepTwo === false){
        return false;
      }
        j++;
        if (steptwo === j) {
          if(!this.props.saveApplicationData){
            return false;
          }
            analyticsClickEvent('step3_next','')
            // window.$('#fieldErrorShowModel').modal('show')
            const allDetailsStage = Object.assign({}, ...applicationProcessBasedResult);
            const cookie = new Cookies();
            const TMXSessionId = uuidv4();
            window.threatmetrix.profile(process.env.REACT_APP_TMXDOMAIN, process.env.REACT_APP_TMXKEY, TMXSessionId);
            allDetailsStage.application_id = await this.getApplicationIDInstorage();
            allDetailsStage.session_id = TMXSessionId;
            this.setState({ loading: true })
            await frontEndLogs('Application submit request : Local Storage applicationId :'+localStorage.getItem("applicationId")+',cookie applicationID :'+cookie.get('applicationId'),false,200,'applicationValidationInProps',allDetailsStage);
            return await this.applicationSubmit();      
        }
      
    })
  }


  handleStageOneValidation = async(src,val,stepone,completedSteps,applicationProcessBasedResult,step) => {
    let i = 0;
    if (src[val] === '' && src['stage'] === '1') {
      i++;
      this.props.saveApplicationData.filter(async function (itm) {
        if (Object.keys(itm).toString() === val) {
          return itm
        }
      });
      let moveToNextStep = stepone === i && completedSteps !== 'three' && localStorage.getItem('sessionId') !== null;
      if(!moveToNextStep){
        return this.handleForShowFieldErrorPopup(completedSteps);
      }
        analyticsClickEvent('step2_next','')
        if (!this.props.saveApplicationData) {
          return false;
        }
          const allDetails = Object.assign({}, ...applicationProcessBasedResult);
          const cookie = new Cookies();
          const TMXSessionId = uuidv4();
          window.threatmetrix.profile(process.env.REACT_APP_TMXDOMAIN, process.env.REACT_APP_TMXKEY, TMXSessionId);
          allDetails.application_id = await this.getApplicationIDInstorage();
          allDetails.session_id = TMXSessionId;

          allDetails.Application_Channel = 'Web';
          this.checkApplicationType(allDetails);
          await frontEndLogs('Application submit request : Local Storage applicationId :'+localStorage.getItem("applicationId")+',cookie applicationID :'+cookie.get('applicationId'),false,200,'handleStageOneValidation',allDetails); 
          return await this.applicationSubmit();
    }
  }


  handleForShowFieldErrorPopup = (completedSteps)=> {
    this.props.applicationValidation.map(async (src) => {
      let valuesOfStageOne = Object.keys(src)['0'];
      if (src[valuesOfStageOne] !== '' && src['stage'] === '1' && completedSteps === 'two') {
        window.$('#fieldErrorShowModel').modal('show')
      }
    })
  }

  checkApplicationType = (allDetails) => {
    if (this.state.resultSourceURL) {
      allDetails.Humm_Purchase_Source_Url__c = this.state.resultSourceURL;
    }

    this.setState({ loading: true })
    const { amountRequested } = this.props;
    const eventType = Number(amountRequested) > Number(process.env.REACT_APP_MAXIMUM_LT_AMOUNT) ? 'big_thing' : 'little_thing';
    const customerType = Number(amountRequested) > Number(process.env.REACT_APP_MAXIMUM_LT_AMOUNT) ? '[BIG THINGS]' : '[LITTLE THINGS]';
    analyticsChangeEvent(eventType, amountRequested);

    window.dataLayer.push({
      "event": "metadata",
      "customerType": customerType
    });
  }


  checkstepTwoApiResponse = (moveToStepThree,completedSteps) => {
    if(!moveToStepThree){
      this.props.applicationValidation.map(async (src) => {
        let valuesOfStageTwo = Object.keys(src)['0'];
        let showErrorModel = src[valuesOfStageTwo] !== '' && src['stage'] === '2' && completedSteps === 'three';
        if (showErrorModel) {
          window.$('#fieldErrorShowModel').modal('show')
        }
      })
      return false;
    }
  }



  sendGtmErrorForVerifyOTP = (errorMessage) => {
    localStorage.getItem('fromWhere') === 'apply_now'
    ? GTMServerSubmitError('Mobile Verification', 'server', errorMessage, 1, 4, '1 : 4')
    : GTMServerSubmitError('Mobile Verification', 'server', errorMessage, 1, 3, '1 : 3')
  }
  
  sendGtmEmailVerifyStatus = () => {
    localStorage.getItem('fromWhere') === 'apply_now'
    ? GTMApplicationSubmitStatus('Email verification','server', 1, 4, "1 : 4", "[Email Verified]")
    : GTMApplicationSubmitStatus('Email verification','server', 1, 3, "1 : 3", "[Email Verified]")
  }

  sendGtmErrorForEmailVerifyOTP = (errorMessage) => {
    localStorage.getItem('fromWhere') === 'apply_now'
    ? GTMServerSubmitError('Email Verification', 'server', errorMessage, 1, 4, '1 : 4')
    : GTMServerSubmitError('Email Verification', 'server', errorMessage, 1, 3, '1 : 3')
  }

  // 
  continueApplicationJourney = async(marketingConsent) => {
    this.setState({loading: true})
    const cookie = new Cookies();
    let applicationId = await this.getApplicationIDInstorage();
    if(applicationId == null || applicationId == undefined) {
      if(!marketingConsent){
        this.setState({prepopulateFlag: true});
      }
      
      const obj = {
        "Application_Channel": "Web",
        "Retailer_Account__c": cookie.get('retailerId') || localStorage.getItem('storeID') || localStorage.getItem('RetailerIdFromQuoteTool'),
        "Retailer_Campaign_Code__c": cookie.get('retailerReference') || null,
        "Humm_Retailer_Link__c":this.setHummRetailerLink()
      }
      await this.checkRetailerAndSendLogs();
      cookie.remove('applicationId');
      const result = await createApplication(obj);
      await frontEndLogs('Received Application Id - '+result.data.data.application_id,false,200, 'continueApplicationJourney');
      if(result.status === 200 && result.data.data.application_id !== undefined){
        localStorage.setItem('applicationId', result.data.data.application_id);

        cookie.set('applicationId',result.data.data.application_id)
        // Push the applicationID to the GTM through dataLayer event
        GTMApplicationId();
      }else {
        this.setState({serverErrorMessage: result.data.message, loading: false});
        window.$('#marketingConsentModal').modal('hide');
        window.$('#serverErrorShowModel').modal('show');
        return false;
      }
    }

    if(window.$('#marketingConsentModal').hasClass('show') === true) {
      window.$('#marketingConsentModal').modal('hide');
    }

    this.setState({loading: true});
    if(localStorage.getItem('fromWhere') === 'apply_now') {
      firebaseLoadingEvent("step2_loaded");
      this.setState({stepOne: "completed"});
      this.setState({step: 2});
    }
    else {
      firebaseLoadingEvent('step1_logged_user_loaded','')
      this.setState({step: 1});
      this.setState({privacyPolicyCheck: true});
    }
    this.props.removeSavedata([]);
    await this.PrepopulateHideData();
    this.props.showappValidation('');

    window.scrollTo(0, 0);
  }

  checkRetailerAndSendLogs = async()=>{
    const cookie = new Cookies();
    if(cookie.get('retailerId')) {
      await frontEndLogs('Retailer Id is sent on request of API call - '+cookie.get('retailerId'),false,200, 'application/createApplication');
    } else {
      await frontEndLogs('Retailer Id is not sent on request of API call ',false,200, 'application/createApplication');
    }
  }

  extractEmails(text) {
    return text.match(/([a-z0-9._+-]+@[a-z]+\.[a-z]+)/gi);
  }

  retryApplication = async() => {         
    if(this.state.stages == 'one'){
      window.$('#serverErrorShowModel').modal('hide');
      await this.nextStep('two');
    }
    else if(this.state.stages == 'two'){
      window.$('#serverErrorShowModel').modal('hide');
      await this.nextStep('three');
    }
  }

  redirectionForErrorMessage = () => {
    if (this.state.reachLimitOTPVerify) {
      if (!this.state.isMobileOTPVerified) {
        this.setState({ reachLimitOTPVerify: false })
        this.props.updateMobileChange(true);
        let fieldValues = {}
        fieldValues['Mobile_Number'] = false;
        this.clearMobileNumberAndHighlightField();
        this.props.firstSectionValidationUpdate(fieldValues);
      } else if (!this.state.isEmailOTPVerified) {
        let fieldValues = {}
        fieldValues['Email_Address'] = false;
        this.validateEmailAddressCheck();
        this.clearEmailAddressAndHighlightField();
        this.props.firstSectionValidationUpdate(fieldValues);
        this.hidePopup("#registerModal")
      }
    }
    analyticsClickEvent('ok', '')
    window.$('#commonModel').modal('hide');
    window.$('#userBalanceShowModel').modal('hide')
    window.$('.modal-backdrop').removeClass('show');
    window.$('body').removeClass('modal-open');
    window.$('.modal-backdrop').remove();
    if(this.state.validateStatusCode == 400){
      this.props.appValidationsRemove([]);
      this.props.getAmountRequested('');
      this.props.showappValidation('');
      this.props.removeSavedata([]);
      this.redirectionForErrorPopupUpIfRedirectURLExists();
    }
  }

  serverErrorRedirection(){
    if(this.state.decisionByLendingMetrics && !this.state.showQR){
      return ( 
        <button type="button" className="btn btn-primary text-capitalize" onClick={() => {this.setState({loading:true});window.$('#serverErrorShowModel').modal('hide'); this.handleWarningOBResponse() }}
        >Try Again</button> );
    }else if(parse(this.state.serverErrorMessage) === 'Something went wrong. Please try again.'){
      return ( 
        <button type="button" className="btn btn-primary text-capitalize" onClick={() => { this.retryApplication().catch(error => console.error(error)); }}
      >Try Again</button> );
    }else if(this.state.decisionByLendingMetrics) {
      return (
        <button type="button" className="btn btn-primary" data-dismiss="modal" 
      onClick={this.redirectionBasedOnLendingMetrics}
      >Ok</button>)
    }
    else if(this.state.validateStatusCode != 404) {
      return (
        <button type="button" className="btn btn-primary" data-dismiss="modal" 
      onClick={this.redirectionForErrorMessage}
      >{this.state.popupButtonLabel ? this.state.popupButtonLabel : `Ok`}</button>)
    }
    else {
      return (
        <button type="button" className="btn btn-primary"  
      onClick={() => this.redirectToMobileVerification()}
      >Ok</button>
      );
    }

  }

  // To handle button label for OB failure
  redirectionBasedOnOB(){
    return ( 
      <button type="button" className="btn btn-primary text-capitalize" onClick={() => {this.setState({loading:true});window.$('#serverErrorShowModel').modal('hide'); this.redirectionBasedOnLendingMetrics() }}
      >OK</button> );
  }

// To handle redirect for OB failure
  redirectionBasedOnLendingMetrics = () => {
    if(localStorage.getItem('fromWhere') === 'apply_now'){
      this.props.history.push("/login");
      window.location.reload(false)
    }
    else {
      this.props.history.push("/home");
    }      
  }


  tabonchange = (step) => {
    window.scrollTo(0, 0);
    if (step === '1') {
      this.setState({ step: 1 });
      analyticsClickEvent('progress_step1','')
    } else if (step === '2') {
      this.setState({ step: 2 });
      analyticsClickEvent('progress_step2','')
    } else if (step === '3') {
      this.setState({ step: 3 });
      analyticsClickEvent('progress_step3','')
    } else if (step === '4') {
      this.setState({ step: 4 });
      analyticsClickEvent('progress_step4','')
      if (this.miteck?.current) {
        this.miteck.current.updatechanges(this.state.documentSteps);
      }
      if (this.qrRef?.current) {
        this.qrRef.current.callevent();
      }
    }
  };

  convertMessage = (msg) => {

    if (this.extractEmails(msg) != null) {
      let newtext = '';
      let val = this.extractEmails(msg);
      val.map((src) => {
        let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
        newtext = msg.replace(src, anchorTag);
        msg = newtext;

      })
    }

    return msg;

  }

  // Method to generate reCaptcha when otp code for mobile is not empty
  handleRecaptchaMobileVerify = () => {
    this.setState({reCaptchaToken: ''})

    if (this.state.code != '' && this.state.code != undefined) {
        this.reCaptchaRef.execute()
          .catch(error => console.error(error));

        this.setState({mobileVerifyFlag: true})
    }
    else {
      this.setState({ codeError: 'Verification code is required.', codeSuccess: ''});

      localStorage.getItem('fromWhere') === 'apply_now'
      ? GTMServerSubmitError('Mobile Verification', 'client', "Verification code is required.", 1, 4, '1 : 4')
      : GTMServerSubmitError('Mobile Verification', 'client', "Verification code is required.", 1, 3, '1 : 3')
    }
  }

  // Method to generate reCaptcha when otp code for Email is not empty
  handleRecaptchaEmailVerify = () => {
    this.setState({reCaptchaToken: ''})

    if (this.state.code != '' && this.state.code != undefined) {
        this.reCaptchaRef.execute()
          .catch(error => console.error(error));

        this.setState({emailVerifyFlag: true})
    }
    else {
      this.setState({ codeError: 'Verification code is required.', codeSuccess: ''});

      localStorage.getItem('fromWhere') === 'apply_now'
      ? GTMServerSubmitError('Email Verification', 'client', "Verification code is required.", 1, 4, '1 : 4')
      : GTMServerSubmitError('Email Verification', 'client', "Verification code is required.", 1, 3, '1 : 3')
    }
  }

  handleRecaptchaForResendEmail = (event) => {
    if(event) {
      let text = event.target.innerText.split(' ').join('_');
      analyticsClickEvent(text.toLocaleLowerCase(),'')
    }

    this.setState({reCaptchaToken: ''})
    this.reCaptchaRef.execute()
      .catch(error => console.error(error));

    this.setState({resendEmailFlag: true})
  }

  handleRecaptchaForResendMobile = (event) => {
    if(event) {
      let text = event.target.innerText.split(' ').join('_');
      analyticsClickEvent(text.toLocaleLowerCase(),'')
    }

    this.setState({reCaptchaToken: ''})
    this.reCaptchaRef.execute()
      .catch(error => console.error(error));

    this.setState({resendMobileFlag: true})
  }

  isConsentUndefinedCheck = (userConsent) => {
    let consents = {
      'Mobile_Consent':localStorage.getItem('mobileConsent'),
      'Email_Consent':localStorage.getItem('emailConsent'),
      'SMS_Consent':localStorage.getItem('smsConsent')
    }
    frontEndLogs('Marketing Consent ',false,200, 'isConsentUndefinedCheck',consents);
    if(userConsent === 'mobileConsent' && localStorage.getItem('mobileConsent')){
      frontEndLogs('Mobile App consent is '+ localStorage.getItem('mobileConsent'),false,200, 'isConsentUndefinedCheck')
    }
    else if(userConsent === 'emailConsent' && localStorage.getItem('emailConsent')){
      frontEndLogs('Email consent is '+ localStorage.getItem('emailConsent'),false,200, 'isConsentUndefinedCheck')
    }
    else if(userConsent === 'smsConsent' && localStorage.getItem('smsConsent')){
      frontEndLogs('SMS consent is '+ localStorage.getItem('smsConsent'),false,200, 'isConsentUndefinedCheck')
    }
  }

  // method to input change for input field in email, mobile and marketing consent popup
  handleInputChange = (event) => {
    if(window.$('#registerModal').hasClass('show') && event.target.value.length >= 6) {
        return false
    }
    else if(window.$('#mobileModal').hasClass('show')) {
      if (event.target.value.length >= 7) {
        return false
      }
    }


    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
    if (name === 'code') {
      if (!/\d/.test(value)) {
          event.preventDefault();
      }
    } 
    let newImageSrc;

    switch(name){
      case "mobile-app-radio":
        localStorage.setItem('mobileConsent', event.target.checked)

        newImageSrc = localStorage.getItem('mobileConsent') === 'true' ? 'images/checked.svg' : 'images/unchecked.svg';
        this.setState({ mobileCheckConsentSrc: newImageSrc });
        this.updateMarketingConsentForLogin();

        setTimeout(() => {
          this.isConsentUndefinedCheck('mobileConsent');
        }, 200);
        break;
      case "smsRadio":
        localStorage.setItem('smsConsent', event.target.checked)

        newImageSrc = localStorage.getItem('smsConsent') === 'true' ? 'images/checked.svg' : 'images/unchecked.svg';
        this.setState({ smsCheckConsentSrc: newImageSrc });        
        this.updateMarketingConsentForLogin();

        setTimeout(() => {
          this.isConsentUndefinedCheck('smsConsent');
        }, 200);
        break;
      case "emailRadio":
        localStorage.setItem('emailConsent', event.target.checked)

        newImageSrc = localStorage.getItem('emailConsent') === 'true' ? 'images/checked.svg' : 'images/unchecked.svg';
        this.setState({ emailCheckConsentSrc: newImageSrc });
        this.updateMarketingConsentForLogin();
      
        setTimeout(() => {
          this.isConsentUndefinedCheck('emailConsent');
        }, 200);
        break;
    }
  }

  aboutSectionValueSendInlogs = (requestObjectMobile,methodName) => {
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);
    let aboutSectionStateValues = {
      "Email_Address": this.state.email,
      "First_Name": this.state.firstName,
      "Last_Name": this.state.lastName,
      "Mobile_Number": this.state.phoneNo,
      "Birth_Date": this.state.dateOfBirth
    }
    frontEndLogs('Application user details sent before call the API', false, 200, methodName, { requestPayload: requestObjectMobile, aboutSectionValues: aboutSectionStateValues, allUserData: allUserData });
  }

  // Resend OTP for mobile
  resendCodeForMobile = async(_e) => {
    let requestObjectMobile = { 
      recaptchaToken: this.state.reCaptchaToken,
      "Mobile_Number": this.state.phoneNo
    }

    this.setState({resendMobileFlag: false})
    if(this.state.reCaptchaToken){
      this.aboutSectionValueSendInlogs(requestObjectMobile,'resendCodeForMobile')
      const result = await sendMobileOTP(requestObjectMobile);
      this.setState({onBlurInputFiled:false},()=>{
        this.handleResendOTPResult(result);
      })
    }

  }

  // check if user exists after verifying mobile number
  checkIfUserExistsAfterMobileVerify = async() => {
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);
    this.setState({serverErrorMessage: ''});
      const requestPayload = {
        "Email_Address": this.state.email ? this.state.email : allUserData['Email_Address'],
        "First_Name": this.state.firstName ? this.state.firstName : allUserData['First_Name'],
        "Last_Name": this.state.lastName ? this.state.lastName : allUserData['Last_Name'],
        "Mobile_Number": this.state.phoneNo ? this.state.phoneNo : allUserData['Mobile_Number'],
        "Birth_Date": this.state.dateOfBirth ? this.state.dateOfBirth : allUserData['Birth_Date']
      }
	let checkUserStateRequest = {
	      "Email_Address": this.state.email,
	      "First_Name": this.state.firstName,
	      "Last_Name": this.state.lastName,
	      "Mobile_Number": this.state.phoneNo,
	      "Birth_Date": this.state.dateOfBirth
	    }
      frontEndLogs('sent request before call the check user existence api', false, 200, 'checkIfUserExistsAfterMobileVerify', { requestPayload: requestPayload, checkUserStateRequest: checkUserStateRequest, allUserData: allUserData });
      const responseCheckUserExistence = await checkUserExistence(requestPayload);
this.setState({ onBlurInputFiled: false }, () => {
      if(responseCheckUserExistence?.status === 200){
        this.handleSuccessResponseOfCheckUserExistenceAPI(responseCheckUserExistence)
      }
      else if(responseCheckUserExistence?.status === 400) {
        this.handleFailureResponseOfCheckUserExistenceAPI(responseCheckUserExistence)
      }
    });
  }

  updateMarketingConsentForLogin = () => {
    return localStorage.getItem('sessionId') !== null ? this.updateMarketingConsent() : {};
  }

  handleFailureResponseOfCheckUserExistenceAPI = (responseCheckUserExistence) => {
    if(responseCheckUserExistence?.data?.data?.accountStatus === "PARTIAL_MATCH_ACCOUNT"){
      let resultmessage = responseCheckUserExistence.data.message;
      resultmessage = this.convertMessage(resultmessage);

      GTMServerSubmitError('About You','server',resultmessage,1,4,'1 : 4');
      this.setState({serverErrorMessage: resultmessage, validateStatusCode: responseCheckUserExistence.status,
        popupButtonLabel: responseCheckUserExistence.data.data.buttonLabel, 
        redirectURL: responseCheckUserExistence.data.data.redirectUrl,isExistingUserChangeEmailOption: true},()=>{
          this.hidePopup('#mobileModal');
      if(localStorage.getItem('fromWhere') !== 'apply_now') {
        this.setState({validateStatusCode: '', loading: false});
        window.$('#serverErrorShowModel').modal('show');
      }
      else if(!this.state.isEmailOTPVerified) {
        this.handleRecaptchaForResendEmail();
        setTimeout(() => {
          if(this.state.codeSuccess !== '') {
            this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: ''});
            this.setState({ loading: false });
            window.$('#registerModal').modal('show');
          }
          else if(this.state.codeErrorForResendCode !== '') {
            this.setState({ popupButtonLabel: '', serverErrorMessage: this.state.codeErrorForResendCode, validateStatusCode: '',
               redirectURL: '',  loading: false });
            window.$('#serverErrorShowModel').modal('show');
            this.props.updateEmailChange(true);
            let fieldValues = {}
            fieldValues['Email_Address'] = false;
          }
        }, 2500);
      }
        });
    }else if(responseCheckUserExistence?.data?.message === 'The last name must contain alphabets and must be at least two characters long.')
    {
      frontEndLogs('The last name field failed the validation' + this.getApplicationIDInstorage(), true, 400, 'handleFailureResponseOfCheckUserExistenceAPI',responseCheckUserExistence);
      let lastNameValidationMessage = responseCheckUserExistence?.data?.message;
      GTMServerSubmitError('About You','server',lastNameValidationMessage,1,4,'1 : 4');
      this.setState({serverErrorMessage: lastNameValidationMessage,loading: false},()=>{
        let stages = {};
        let lastNameStagesValues={}
        lastNameStagesValues['Last_Name'] = '';
        stages['Last_Name'] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.saveApplicationDatas(lastNameStagesValues);
        this.props.appvalidations(stages);
        let fieldValues = {}
        fieldValues['Last_Name'] = false;
        this.props.firstSectionValidationUpdate(fieldValues)
        window.$('#serverErrorShowModel').modal('show')
        return false;
      })
      ;

    } else if (responseCheckUserExistence?.data?.data?.fields === 'First_Name') {
      this.setState({serverErrorMessage: responseCheckUserExistence?.data?.message, loading: false},()=>{
      let stages = {};
        let firsttNameStagesValues = {}
        firsttNameStagesValues['First_Name'] = '';
        stages['First_Name'] = responseCheckUserExistence?.data?.message;
        stages['stage'] = this.props.stage;
        this.props.saveApplicationDatas(firsttNameStagesValues);
        this.props.appvalidations(stages);
        let fieldValues = {}
        fieldValues['First_Name'] = false;
        this.props.firstSectionValidationUpdate(fieldValues)
        window.$('#serverErrorShowModel').modal('show')
        return false;
      })

    } else{
       frontEndLogs('Other response of check user existence ' + this.getApplicationIDInstorage(), true, 400, 'handleFailureResponseOfCheckUserExistenceAPI',responseCheckUserExistence);
        this.setState({ loading: false,serverErrorMessage: responseCheckUserExistence?.data?.message });
        GTMApplicationFunnelSubmitError('Application Flow','server',1,2,"1:2","Something went wrong");
        window.$('#serverErrorShowModel').modal('show')
        return false;
    }
  }

  handleSuccessResponseOfCheckUserExistenceAPI = (responseCheckUserExistence) => {
    if(responseCheckUserExistence?.data?.data?.isNewAccount === true) {
      if(!this.state.isEmailOTPVerified){
        this.setState({loading: true, isNewAccountValue: responseCheckUserExistence?.data?.data?.isNewAccount})
        this.handleScrollOfNextSection();
      }
    }
    else if(responseCheckUserExistence?.data?.data?.isNewAccount === false){
      this.setState({loading: true, isUserExists: true, isNewAccountValue: responseCheckUserExistence?.data?.data?.isNewAccount});
      if(this.props.emailFlag){
        this.checkMfaValidationForUserToMobile();
      }
      else if(!this.state.isEmailOTPVerified){
        this.handleRecaptchaForResendEmail();
        setTimeout(() => { 
          if(this.state.codeSuccess !== '') {
            this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: ''});
            this.setState({ loading: false });
            window.$('#registerModal').modal('show');
          }
          else if(this.state.codeErrorForResendCode !== '') {
            this.setState({  validateStatusCode: '', serverErrorMessage: this.state.codeErrorForResendCode, popupButtonLabel: '',
             redirectURL: '',  loading: false });
            window.$('#serverErrorShowModel').modal('show');
            this.props.updateEmailChange(true);
            let fieldStateValues = {}
            fieldStateValues['Email_Address'] = false;
          }
        }, 2500);
      }
    }
  }

  analyticsCheckBoxClickEvent = (e) => {
    analyticsClickEvent('privacy_policy','');
  }

  resendCodeForEmail = async(_e) => {
    let requestObjectEmail = {
      recaptchaToken: this.state.reCaptchaToken,
      "Email_Address": localStorage.getItem('email') ?? this.state.email,
      "First_Name": this.state.firstName,
      "Last_Name": this.state.lastName
    }
console.log("Email OTP",localStorage.getItem('email') ?? this.state.email);
    this.setState({resendEmailFlag: false})
    if(this.state.reCaptchaToken) {
      this.aboutSectionValueSendInlogs(requestObjectEmail,'resendCodeForEmail');
      const result = await emailSendOTP(requestObjectEmail);
      this.setState({onBlurInputFiled:false},()=>{
      this.handleResendOTPResult(result);
      });
    }
  }

  checkUserExistsAfterEmailVerified = async(responseEmailVerified) => {
    await this.checkIfUserExistsAfterMobileVerify();

    setTimeout(() => {
      if(this.state.serverErrorMessage !== '') {
        if(window.$('#registerModal').hasClass('show') || this.state.loading) {
          this.hidePopup('#registerModal')
          this.setState({ loading: false });
          window.$('#serverErrorShowModel').modal('show');
          return false;
        }
      }
      else if(responseEmailVerified?.data?.data?.dataToken){
        this.setState({loading: true });
        this.props.updateEmailVerified(true)
  
        if(localStorage.getItem('fromWhere') === 'apply_now') {
          this.createSessionForUser(responseEmailVerified.data.data.dataToken);
        }
        else {
          this.hidePopup('#registerModal');
          this.checkApplicantEligiblityForUser();
        }
      }
    }, 3000);
  }

  handleResendOTPResult = (result) => {
    if (result.data.statusCode === 200) {
      this.setState({ codeSuccess: result.data.message }, () => {
        this.stylingChangesForVerificationMessage();
      })
      this.setState({ codeErrorForResendCode: '', codeError: ''})
    }
    /* check whether the status code received matches in the list
    using handleStatusCode method and set the error message in variable codeErrorForResendCode
    */
   else if(handleStatusCode(result.data.statusCode)){
    if(result.data.statusCode === 429){
      this.setState({ reachLimitOTPVerify:true,onBlurInputFiled:false});
    }
     this.setState({ codeSuccess: '', codeError: ''});
     this.setState({ codeErrorForResendCode: result.data.message}, () => {
      this.stylingChangesForVerificationMessage();
     });
    }
  }

  checkMFAForEmailNecessaryForUser = () => {

    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      this.checkMfaValidationForUserToEmail();
    }
    else {
      this.checkIfUserExistsAfterMobileVerify();
    }
  }

  // Method to handle recaptacha verify for mobile verification
  redirectCodeScreenMobile = async(recaptchaToken) => {
    analyticsClickEvent('mobile_verify_ok_button','');
      
    this.setState({ codeError: '', codeErrorForResendCode: '', codeSuccess: ''})
    if (this.state.code === '') {
    this.setState({ codeError: 'Verification code is required.', loading: false, codeErrorForResendCode: '', codeSuccess: '' });
    this.setState({ loading: false });
    return false;
    }

    this.setState({disablePopupRegistration:'disable-popup'});

   let requestObject = {
    'verify_code': this.state.code,
    "recaptchaToken": this.state.reCaptchaToken ? this.state.reCaptchaToken : recaptchaToken,
    "Mobile_Number": this.state.phoneNo
}

    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      requestObject["Email_Address"] = this.state.email ?? localStorage.getItem('email')
    }
    
    this.setState({disableVerifyOkButton:true})
     
    this.setState({mobileVerifyFlag: false, disableField: true});
    this.aboutSectionValueSendInlogs(requestObject,'redirectCodeScreenMobile');
    const mobileVerifyResult = await verifyMobileOTP(requestObject);

    if(mobileVerifyResult.status == 200){
      const resultMessage = mobileVerifyResult.data.message;
      let verifyMobileMessage = resultMessage.replace("\n", "<br>");

      this.setState({verificationMessage: verifyMobileMessage, verifyMessage: verifyMobileMessage, codeSuccess: '',
      codeError: "", codeErrorForResendCode: ''}, () => {
        this.stylingChangesForVerificationMessage();
      })

      setTimeout(() => {
        this.hidePopup('#mobileModal');
        window.$('#mobileSuccessModal').modal('show')
      }, 2600);


      setTimeout(() => {
        this.setState({ loading: true})
        this.hidePopup('#mobileSuccessModal');
        this.props.updateMobileVerified(true);
        let fieldValues = {}
        fieldValues['Mobile_Number'] = true;
        this.props.firstSectionValidationUpdate(fieldValues)
        this.setState({isMobileOTPVerified: true})

        if(mobileVerifyResult?.data?.data?.verificationStatus === 'PARTIAL_MATCH_ACCOUNT') {
          this.setState({
            serverErrorMessage: mobileVerifyResult.data.data.verificationMessage,
            validateStatusCode: '',
            popupButtonLabel: mobileVerifyResult.data.data.buttonLabel,
            redirectURL: mobileVerifyResult.data.data.redirectUrl, loading: false });

            window.$('#serverErrorShowModel').modal('show')
        }
        else if(!this.props.saveDates) {
          this.checkIfUserExistsAfterMobileVerify();
        }
        else {
          this.checkMFAForEmailNecessaryForUser();
        }
      }, 4700);
    }
    else {
        this.setState({disableVerifyOkButton:false, disableField: false})
        this.setState({codeError: mobileVerifyResult.data.message, codeSuccess: '', 
        codeErrorForResendCode: '',verificationMessage: ''});
        this.setState({ loading: false })

        this.stylingChangesForVerificationMessage();

        // Push error message for OTP verification to the datalayer
        this.sendGtmErrorForVerifyOTP(this.state.codeError);
    }
  }

  checkUserIfExistsValidationPointForEmail = (result='') => {
    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      if(window.$('#registerModal').hasClass('show') === true) {
        this.setState({loading: true})
        this.hidePopup('#registerModal');
      }

      if(localStorage.getItem('fromWhere') !== 'apply_now' || this.state.isUserExists) {
        this.handleScrollOfNextSection();
      }
      else {
        if(this.state.loading){
          this.setState({loading: false})
        }
        window.$('#userNoteShowModel').modal('show')
      }
    }
    else {
      this.checkUserExistsAfterEmailVerified(result);
    }
  }

  stylingChangesForVerificationMessage = () => {
    if (window.$('#mobileModal').hasClass('show') === true && 
      (this.state.codeSuccess !== ''|| this.state.codeErrorForResendCode !== '')) {
      const textMessage = document.querySelector('#verifyMessage');
      textMessage.style.marginTop = "2.1375rem";

      const mobileModalButton = document.querySelector('#mobileModal .modal-footer');
      mobileModalButton.style.marginTop = "2.1375rem";

    }
    else if (window.$('#registerModal').hasClass('show') === true && 
    (this.state.codeSuccess !== ''|| this.state.codeErrorForResendCode !== '')) {
      const emailModalButton = document.querySelector('#registerModal .modal-footer');
      emailModalButton.style.marginTop = "2.34rem";

      const textMessage = document.querySelector('#verificationMessage');
      textMessage.style.marginTop = "2.34rem";
    }
  }

  // Method to handle recaptacha verify for email verification
  redirectCodeScreenEmail = async(recaptchaToken) => {
    analyticsClickEvent('email_verify_ok_button','');
      
    this.setState({ codeError: '' })
    if (this.state.code === '') {
    this.setState({ codeError: 'Verification code is required.', loading: false, codeSuccess: '' });
    return false;
    }
      this.setState({ codeError: '', codeErrorForResendCode: ''});
    

    this.setState({disablePopupRegistration:'disable-popup'});

      let obj = {
        "verify_code": this.state.code,
        "recaptchaToken": this.state.reCaptchaToken ? this.state.reCaptchaToken : recaptchaToken,
        "Email_Address": this.state.email
    }
    
    this.setState({disableVerifyOkButton:true, disableField: true})
     
    this.setState({emailVerifyFlag: false});
    this.aboutSectionValueSendInlogs(obj,'redirectCodeScreenEmail');
    const result = await verifyEmailOTP(obj);

    if(result.status == 200){

        let message = result.data.message;
        const verifyMessage = message.replace("\n", "<br>")
        this.setState({ verificationMessage: verifyMessage, verifyMessage: verifyMessage, isEmailOTPVerified: true, 
          codeSuccess: '', codeErrorForResendCode: ''}, () => {
            this.stylingChangesForVerificationMessage();
          })
        // Push email verified success status to the GTM datalayer
        this.sendGtmEmailVerifyStatus();

        setTimeout(() => {
          this.hidePopup('#registerModal');
          this.setState({ loading: false });
          window.$('#registerSuccessModal').modal('show')
        }, 2000);

        setTimeout(() => {
          this.hidePopup('#registerSuccessModal');
          this.setState({ loading: true });
        }, 4700);

        setTimeout(async () => {
          this.checkUserIfExistsValidationPointForEmail(result);
        }, 5500);

        setTimeout(async () => {
          let applicationCreateResult = await this.callToCreateApplicationForLoginUser();
          if(applicationCreateResult === false) {
            return false;
          }
        }, 6000);
    }
    else if(result.data.statusCode === 400) {
      this.setState({disableField: false})
      this.convertVerifyApiResponseMessage(result);
    }
    else if(result.data.statusCode === 409) {
      this.setState({disableField: false})
      let resultmessage = result.data.message;
      resultmessage = this.convertMessage(resultmessage);
      this.setState({approvedApplicationMessage: resultmessage, verificationMessage: result.data.data.verificationMessage});

      if(result.data.data.session_id){
        localStorage.setItem('token',result.data.data.access_token);
        localStorage.setItem('email', result.data.data.user_details.email);
        localStorage.setItem('UserName',result.data.data.user_details.username);
        localStorage.setItem('sessionId',result.data.data.session_id);
        localStorage.setItem('csrfToken',result.data.data.csrf_token);
        localStorage.setItem('userID', result.data.data.user_details.userid);

        GTMUserIdAndLoginState();
        GTMUserId();
      }

      setTimeout(() => {
        this.hidePopup('#registerModal');
      }, 2000)

      setTimeout(() => {
        window.$('#userBalanceShowModel').modal('show');
      }, 2700);
    }
    else {
        this.setState({disableVerifyOkButton:false, disableField: false})
        this.setState({
            codeError: result.data.message, codeSuccess: ''
            });
            this.setState({ loading: false })
            this.setState({ otpErrorMessage: result.data.message })
        
        // Push error message for email OTP verification to the datalayer
        this.sendGtmErrorForEmailVerifyOTP(result.data.messager);
    }
  }

  callToCreateApplicationForLoginUser = async() => {
    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      frontEndLogs('Email MFA Completed for the login user', false, 200, 'user/check_validity_of_user_mfa');
      let applicationId = await this.getApplicationIDInstorage();
      if(applicationId === null || applicationId === undefined){

        // call createNewApplication for creating an application id 
        const browserStoreCookie = new Cookies();
        const createApplicationRequest = {
          "Humm_Purchase_Source_Url__c": this.state.resultSourceURL ?? null,
          "Retailer_Account__c": browserStoreCookie.get('retailerId') ?? localStorage.getItem('storeID') ?? localStorage.getItem('RetailerIdFromQuoteTool'),
          "Email_Address": localStorage.getItem('email') ?? this.state.email,
          "Retailer_Campaign_Code__c": browserStoreCookie.get('retailerReference') ?? null,
          "Humm_Retailer_Link__c":this.setHummRetailerLink()
        }
        const cookie = new Cookies();
        cookie.remove('applicationId');
        const ApiResultFromCreateAPI = await createApplication(createApplicationRequest);
        await frontEndLogs('Recived Application Id - '+ApiResultFromCreateAPI.data.data.application_id,false,200, 'callToCreateApplicationForLoginUser');
        let createApplicationResponseResult = this.checkCreateApplicationApiResponse(ApiResultFromCreateAPI);
        if(createApplicationResponseResult === false){
          return false;
        }
      }else{
        this.checkUserIfExistsValidationPointForEmail();
      }
        }
  }

  convertVerifyApiResponseMessage = (result) => {
    if(result.data.data.verificationMessage) {
      let resultmessage = result.data.message;
      resultmessage = this.convertMessage(resultmessage);

      let message = result.data.data.verificationMessage;
      const verificationMessage = message.replace("\n", "<br>")
      this.setState({verificationMessage: verificationMessage, popupButtonLabel: result.data.data.buttonLabel, redirectURL: result.data.data.redirectUrl});
      this.setState({serverErrorMessage: resultmessage, validateStatusCode: result.data.statusCode,
        codeSuccess: '', codeErrorForResendCode: '',});
      // Push email verified success status to the GTM datalayer
      this.sendGtmEmailVerifyStatus();

      setTimeout(() => {
        this.hidePopup('#registerModal');
        this.setState({loading: true})
      }, 2000);

      setTimeout(() => {
        this.setState({loading: false})
        window.$('#serverErrorShowModel').modal('show');
      }, 2700);
    }
    else {
      this.setState({disableVerifyOkButton:false})
      this.setState({codeError: result.data.message, codeSuccess: '', codeErrorForResendCode: ''});
      // Push error message for email OTP verification to the datalayer
      this.sendGtmErrorForEmailVerifyOTP(this.state.codeError);
    }
  }

  checkCreateApplicationDetailsFromApiResponse = (ApiResultForApplicationId) => {
    const cookie = new Cookies();
    if(ApiResultForApplicationId.status == 201 && ApiResultForApplicationId.data != undefined) {
      localStorage.setItem('applicationId', ApiResultForApplicationId.data.data.application_id);

      cookie.set('applicationId',ApiResultForApplicationId.data.data.application_id)
      // Push the applicationID to the GTM through dataLayer event
      GTMApplicationId();
      window.$('#registerModal').modal('hide')
    }
    else if(handleStatusCode(ApiResultForApplicationId.status)){
      this.setState({statusCode: ApiResultForApplicationId?.status,serverErrorMessage: ApiResultForApplicationId.data.message});
      window.$('#serverErrorShowModel').modal('show');

      return false;
    }
  }

  // Get application id from the API call 'application/create_new_app'
  createNewApplication = async() => {
    const cookie = new Cookies();
    this.setState({ loading: true });

    const requestPayloadForCreateApplication = {
      "Email_Address": localStorage.getItem('email') ?? this.state.email,
      "Retailer_Account__c": cookie.get('retailerId') ?? localStorage.getItem('storeID') ?? localStorage.getItem('RetailerIdFromQuoteTool'),
      "Retailer_Campaign_Code__c": cookie.get('retailerReference') ?? null,
      "Humm_Purchase_Source_Url__c": this.state.resultSourceURL ?? null,
      "Humm_Retailer_Link__c":this.setHummRetailerLink()
    }

    cookie.remove('applicationId');
    const ApiResultForApplicationId = await createApplication(requestPayloadForCreateApplication);
    await frontEndLogs('Received Application Id - '+ApiResultForApplicationId.data.data.application_id,false,200, 'createNewApplication');
    let checkCreateApplicationResult = this.checkCreateApplicationDetailsFromApiResponse(ApiResultForApplicationId);
    if(checkCreateApplicationResult === false){
      return false;
    }

    let returnPrepopulatedResult = await this.SetPrepopulateDataToLoginUser();
    if(returnPrepopulatedResult === false){
      return false;
    }

    if(this.state.isUserExists) {
      if(this.state.prepopulateFlag) {
        this.PrepopulateHideData();
      }

      this.setState({firstSectionProgressValue: 25});
      this.handleScrollOfNextSection();
    }
    else {
      if(this.state.loading){
        this.setState({loading: false})
      }
      console.log('props_loan_termvalue_2',this.props);
      window.$('#userNoteShowModel').modal('show')
    }
  }

  SetPrepopulateDataToLoginUser = async() => {
    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      if(await this.getApplicationIDInstorage()) {
        await this.loadQuestionsForLoginFlow();

        setTimeout( async() => {
          this.props.removeSavedata([]);
          this.props.showappValidation('');

          if(this.state.prepopulateFlag) {
            this.PrepopulateHideData();
            return false;
          }
          
          this.props.userDetailsPopulateStatusCall(true);
          await this.userDetailsPopulateForExistingUser();
        }, 3400);
          
          return false;
      }
    }
  }

  checkCreateApplicationApiResponse = (ApiResultFromCreateAPI) => {
    const cookie = new Cookies();
    if(ApiResultFromCreateAPI.status == 201 && ApiResultFromCreateAPI.data != undefined) {
      localStorage.setItem('applicationId', ApiResultFromCreateAPI.data.data.application_id);
      cookie.set('applicationId',ApiResultFromCreateAPI.data.data.application_id,{ path: '/', maxAge: 3600 });

      cookie.set('applicationId',ApiResultFromCreateAPI.data.data.application_id)
      // Push the applicationID to the GTM through dataLayer event
      GTMApplicationId();
      window.$('#registerModal').modal('hide')
    }
    else if(handleStatusCode(ApiResultFromCreateAPI.status)){
      this.setState({statusCode: ApiResultFromCreateAPI?.status,serverErrorMessage: ApiResultFromCreateAPI.data.message});
      window.$('#serverErrorShowModel').modal('show');

      return false;
    }
    if(this.state.isUserExists) {        
      this.setState({firstSectionProgressValue: 25});
    
      const moveToNextSection = document.getElementById("second-section");          
        this.setState({loading: false, globalProgressBarValue: 25}, () => {
          this.showSectionSubContext();
          this.showSectionHeading(); 
        });
        if(moveToNextSection && this.state.firstSectionProgressValue === 25){
            this.validationforPrepopulatedOfSecondSection();
        }                
    }
    else {
      if(this.state.loading){
        this.setState({loading: false})
      }
      window.$('#userNoteShowModel').modal('show')
    }
  }

  checkMfaValidationForUserToMobile = async() => {

    const requestPayload = {
      "recipient": this.state.phoneNo,
      "recipientType": "Mobile"
    }

    const responseResult = await mfaValidationCheckForUser(requestPayload)
    this.setState({onBlurInputFiled:false},()=>{
    if(responseResult.status === 406) {
      this.handleRecaptchaForResendMobile();
          
      setTimeout(() => {
        if(this.state.codeSuccess !== '' || this.state.codeErrorForResendCode !== '') {
          this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: '', loading: false });
          
          setTimeout(() => {
            this.props.disableChangeMobileOption(true);
            this.props.updateMobileChange(false);
            window.$('#mobileModal').modal('show');              
          }, 800);
        }
      }, 2500);
    } else {
      this.setState({ isMobileOTPVerified: true})
      frontEndLogs('Mobile MFA not needed for the login user', false, 200, 'user/check_validity_of_user_mfa');

      this.checkMfaValidationForUserToEmail();
    }
  });
  }

  checkMfaValidationForUserToEmail = async() => {
    const requestPayload = {
      "recipient": this.state.email,
      "recipientType": "Email"
    }

    const responseResult = await mfaValidationCheckForUser(requestPayload)
    this.setState({onBlurInputFiled:false},async()=>{
    if(responseResult.status === 406) {
      this.handleRecaptchaForResendEmail();

      setTimeout(() => {
        if(this.state.codeSuccess !== '') {
          this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: ''});
          this.setState({ loading: false });
            this.props.disableChangeEmailOption(true);
          window.$('#registerModal').modal('show');
        }
        else if(this.state.codeErrorForResendCode !== '') {
          this.setState({ loading: false, serverErrorMessage: this.state.codeErrorForResendCode, popupButtonLabel: '', validateStatusCode: '',
            redirectURL: ''  });
          window.$('#serverErrorShowModel').modal('show');
          this.props.updateEmailChange(true);
          let fieldValue = {}
          fieldValue['Email_Address'] = false;
        }
      }, 5500);
    } else if(responseResult.status === 202) {
      // call to create application API after Email MFA verified
      await this.createApplicationAfterMFACompleted();
    }
    else {
      this.setState({ isEmailOTPVerified: true})
      frontEndLogs('Email MFA not needed for the login user', false, 200, 'user/check_validity_of_user_mfa');

      this.checkUserIfExistsValidationPointForEmail();
    }
  });
  }

  // call to create application API after Email MFA verified
  createApplicationAfterMFACompleted = async() => {
    this.setState({ isEmailOTPVerified: true})
    frontEndLogs('Email MFA not needed for the login user', false, 200, 'user/check_validity_of_user_mfa');
    let applicationId = await this.getApplicationIDInstorage();
    if(applicationId === null || applicationId === undefined){

      // call createNewApplication for creating an application id 
      const browserCookie = new Cookies();
      this.setState({ loading: true });
  
      const requestForCreateApplication = {
        "Email_Address": localStorage.getItem('email') ?? this.state.email,
        "Humm_Purchase_Source_Url__c": this.state.resultSourceURL ?? null,
        "Retailer_Account__c": browserCookie.get('retailerId') ?? localStorage.getItem('storeID') ?? localStorage.getItem('RetailerIdFromQuoteTool'),
        "Retailer_Campaign_Code__c": browserCookie.get('retailerReference') ?? null,
        "Humm_Retailer_Link__c":this.setHummRetailerLink()
      }
      browserCookie.remove('applicationId');
      const applicationDetails = await createApplication(requestForCreateApplication);
      await frontEndLogs('Received Application Id - '+applicationDetails.data.data.application_id,false,200, 'createApplicationAfterMFACompleted');
      if(applicationDetails.status == 201 && applicationDetails.data != undefined) {
        localStorage.setItem('applicationId', applicationDetails.data.data.application_id);
        browserCookie.set('applicationId',applicationDetails.data.data.application_id)
        // Push the applicationID to the GTM through dataLayer event
        GTMApplicationId();
        window.$('#registerModal').modal('hide')
      }
      else if(handleStatusCode(applicationDetails.status)){
        this.setState({statusCode: applicationDetails?.status,serverErrorMessage: applicationDetails.data.message});
        window.$('#serverErrorShowModel').modal('show');
        return false;
      }

      if(this.state.isUserExists) {    
        this.setState({firstSectionProgressValue: 25});
        this.handleScrollOfNextSection();
      }
      else {
          this.setState({loading: false})
        window.$('#userNoteShowModel').modal('show')
      }
    }else{
      this.checkUserIfExistsValidationPointForEmail();
    }
  }

  // create session for the user from the API call 'user/generate_session'
  createSessionForUser = async(userDataToken) => {
    const requestObject = {
      "Email_Address": this.state.email,
      "First_Name": this.state.firstName,
      "Last_Name": this.state.lastName,
      "Mobile_Number": this.state.phoneNo,
      "Birth_Date": this.state.dateOfBirth,
      "dataToken": userDataToken
    }

    const resultData = await generateSessionForUser(requestObject);

    if(resultData.status ===200) {
      localStorage.setItem('token',resultData?.data?.data?.access_token);
      localStorage.setItem('email',resultData?.data?.data?.user_details.email);
      localStorage.setItem('UserName',resultData?.data?.data?.user_details.username);
      localStorage.setItem('sessionId',resultData?.data?.data?.session_id);
      localStorage.setItem('csrfToken',resultData?.data?.data?.csrf_token);
      localStorage.setItem('userID',resultData?.data?.data?.user_details.userid);
      GTMUserIdAndLoginState();
      GTMUserId();

      if(!this.state.isUserExists) {
        this.updateMarketingConsent();
      }

      this.hidePopup('#registerModal');
      this.checkApplicantEligiblityForUser();
    }
    else {
      window.$('#registerModal').modal('hide')
      
      setTimeout(() => {
        this.setState({loading: false, validateStatusCode: resultData?.data?.statusCode ?? '',serverErrorMessage: resultData?.data?.message ?? ''});
        window.$('#serverErrorShowModel').modal('show');
      }, 800);
    }
  }

  checkApplicantEligiblityForUser = async() => {

    const obj = {
      "Email_Address": localStorage.getItem('email') ?? this.state.email,
      isNewAccount: this.state.isNewAccountValue
    }

    this.generatePurchaseRedirectURL();
    this.aboutSectionValueSendInlogs(obj,'checkApplicantEligiblityForUser');
    const ApplicantEligibilityResult = await checkUserApplicationEligibility(obj);
    if(ApplicantEligibilityResult.status == 200 && ApplicantEligibilityResult.data.data.application_id !== ''){
      if(localStorage.getItem('fromWhere') !== 'apply_now') {
        this.setState({ isUserExists: true})
        this.props.removeSavedata([]);
    this.props.showappValidation('');
          await this.loadQuestionsForLoginFlow();
  
          setTimeout( async() => {
            this.props.removeSavedata([]);
            this.props.showappValidation('');
  
            if(this.state.prepopulateFlag) {
              this.PrepopulateHideData();
              return false;
            }
            
            this.props.userDetailsPopulateStatusCall(true);
            await this.userDetailsPopulateForExistingUser();
          }, 3400);
          
            
            return false;
      }
      else {
        this.createNewApplication();
      }
    }
    else if(ApplicantEligibilityResult.status === 409) {
      this.setState({redirectURL: ApplicantEligibilityResult.data.data.redirectUrl})

      let resultmessage = ApplicantEligibilityResult.data.message;
      resultmessage = this.convertMessage(resultmessage);
      this.setState({ popupButtonLabel: ApplicantEligibilityResult?.data?.data?.buttonLabel ?? ""});

      this.setState({ approvedApplicationMessage: resultmessage, prepopulateFlag: true});
      this.setState({ loading: false });
      window.$('#userBalanceShowModel').modal('show');
    }
    else if(ApplicantEligibilityResult.status === 400 ) {
      this.setState({ redirectURL: ApplicantEligibilityResult?.data?.data?.redirectUrl ?? '', 
      popupButtonLabel: ApplicantEligibilityResult?.data?.data?.buttonLabel ?? ''});
      let resultMessage = ApplicantEligibilityResult.data.message;
      resultMessage = this.convertMessage(resultMessage);

      this.setState({ serverErrorMessage: resultMessage, validateStatusCode: ApplicantEligibilityResult.status });
      this.setState({ loading: false });
      window.$('#serverErrorShowModel').modal('show');
    }
  }

  updateMarketingConsent = async() =>{
    const obj = {
      email: localStorage.getItem('email'),
      email_marketing_consent: localStorage.getItem('emailConsent'),
      sms_marketing_consent: localStorage.getItem('smsConsent'),
      mobile_app_marketing_consent: localStorage.getItem('mobileConsent'),
      Application_Channel: "Web",
    }

    let marketingConsetResult = await sendMarketingConstent(obj);
    localStorage.setItem('smsConsent', marketingConsetResult.data.data.sms_marketing_consent);
    localStorage.setItem('emailConsent', marketingConsetResult.data.data.email_marketing_consent);
    localStorage.setItem('mobileConsent', marketingConsetResult.data.data.mobile_app_marketing_consent);
  }

  onKeyPressData = (event) => {
    let eventValue = event.target.value
    if (!/\d/.test(event.key)) {
        event.preventDefault();
    }

    if (this.state.code !== '') {
      this.setState({ otpErrorMessage: '' });
    } else {
      this.setState({ otpErrorMessage: eventValue });
      this.setState({ otpErrorMessage: 'Verification code is required.' });
      return false;
    }
  }

  /*  Handle fields change */
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  onLoad = () => {
    this.setState({
      captchaReady: true
    });

  };

  onVerify = (getToken) => {
      // If the reCaptcha is loaded and token is generated, save the token to the state reCaptchaToken
      if(getToken != null && getToken !== '' && this.state.captchaReady){
          this.setState({verified: true})
          this.setState({reCaptchaToken: getToken}, async () => {
              if(this.state.userDetailsFlag){
                  await this.handleUserDetails(this.state.userDetails);
              }

              if(this.state.mobileVerifyFlag){
                  await this.redirectCodeScreenMobile(getToken);
              }

              if(this.state.emailVerifyFlag){
                await this.redirectCodeScreenEmail(getToken);
              }
              await this.onVerifyReCaptcha();
          });

      }
      else {
          this.setState({verified: false})
      }

  }

  firstSectionValidationDuringScroll = (firstSectionValidFields, firstSectionValidation, allUserData) => {
    if(this.state.globalProgressBarValue<=25 && firstSectionValidFields < 5) {
      this.setState({ firstSectionProgressValue: 0})
    }
    else if(firstSectionValidation['Mobile_Number'] === false) {
      this.setState({ firstSectionProgressValue: 0})
    }
    else if(this.state.firstSectionProgressValue === 25) {
      return false;
    }
    else if(firstSectionValidFields === 5){
      this.setState({onBlurInputFiled:true},()=> {
        this.changeProgressBarValueForFirstSection(allUserData);
        const mobileInputElement = document.getElementById('Mobile_Number');
        mobileInputElement.style.border = "none";
        const emailInputElement = document.getElementById('Email_Address');
        emailInputElement.style.border = "none";
      })      
    }
  }

  fourthSectionValidationDuringScroll = (fourSectionValidFields, allUserData) => {
    console.log("allUserData for process",allUserData);
    if(this.state.fourSectionProgressValue === 100) {
      GTMValidStepsSubmit('Income & Expenses','Client','4','4','4:4');
      console.log("Validation If condition");
      return false;
    }
    else if((allUserData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' 
    || allUserData['Current_Residential_Status__c'] === 'Living with parents/family') && 
    (allUserData['Employment_Stability__c'] == 'Student'
    || allUserData['Employment_Stability__c'] == 'Unemployed' || 
    allUserData['Employment_Stability__c'] == 'Retired') && fourSectionValidFields == 6) {
      this.validationForFourthSection(allUserData);
    }
    else if((allUserData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' 
    || allUserData['Current_Residential_Status__c'] === 'Living with parents/family') && fourSectionValidFields == 7) {
      this.validationForFourthSection(allUserData);
    }
    else if((allUserData['Employment_Stability__c'] == 'Student'
      || allUserData['Employment_Stability__c'] == 'Unemployed' || 
      allUserData['Employment_Stability__c'] == 'Retired') && fourSectionValidFields == 6){
        this.validationForFourthSection(allUserData);
      }
      else if(fourSectionValidFields == 7){
        this.validationForFourthSection(allUserData);
      }else{
        console.log("else",fourSectionValidFields);
      }
  }

  checkSecondSelectionValidation = (secondSectionValidation,allUserData) => {
    if(secondSectionValidation !== this.state.secondSectionProgressValue){
      const secondSectionValidFields = this.validationCheckForEachSection(secondSectionValidation);

      setTimeout(() => {
        this.changeProgressBarValueForSecondSection(secondSectionValidFields,allUserData);

      }, 200);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const allUserData = Object.assign({}, ...nextProps.saveApplicationData);
    const secondSectionValidation = Object.assign({}, ...nextProps.secondSectionSteps);
    this.checkSecondSelectionValidation(secondSectionValidation,allUserData);
    if(allUserData['Mobile_Number']){
      this.setState({phoneNo: allUserData['Mobile_Number']});
    }
    if(allUserData['Email_Address']){
      this.setState({email: allUserData['Email_Address']});
    }

    const firstSectionValidation = Object.assign({}, ...nextProps.firstSectionSteps);
    const firstSectionValidFields = this.validationCheckForEachSection(firstSectionValidation); 
    this.checkNextPropsValidation(nextProps,firstSectionValidFields,firstSectionValidation,allUserData);

    if(nextProps.thirdSectionSteps !== this.state.thirdSectionProgressValue) {
      const thirdSectionValidation = Object.assign({}, ...nextProps.thirdSectionSteps);
      const thirdSectionValidFields = this.validationCheckForEachSection(thirdSectionValidation);
      console.log(thirdSectionValidFields,"thirdSectionValidFields");

      setTimeout(() => {
        if(this.state.thirdSectionProgressValue === 75) {
          return false;
        }
        else if(thirdSectionValidFields === 4){
          this.setState({loading: true, thirdSectionProgressValue: 75}, () => {
            
            setTimeout(() => {
              const span = document.getElementById("four-section");
              span.scrollIntoView({ behavior: 'smooth' });

              this.validationforPrepopulatedOfFourthSection();
            },2000);

            this.handleScrollOfFourSection();
          });
        }
      }, 800);
    }

    if(nextProps.fourSectionSteps !== this.state.fourSectionProgressValue) {
      let fourSectionValidation = Object.assign({}, ...nextProps.fourSectionSteps);
      let fourSectionValidFields = 0;
      fourSectionValidation['Total_Monthly_Expenses__c'] = false
        fourSectionValidFields = this.validationCheckForEachSection(fourSectionValidation);
        console.log("fourSectionValidFields before",fourSectionValidFields);
      if(fourSectionValidFields>=5){
      const newValue = Date.now();
        this.setState({ finalinputValue: newValue });
        // Clear the previous timer, if any
      clearTimeout(this.timer);
      // Set a new timer to check for changes after 3 seconds
      this.timer = setTimeout(() => {
        // Check if the input value hasn't changed for 3 seconds
        if (this.state.finalinputValue == newValue) {
          console.log('No change for the last 3 seconds before final:', newValue);
          fourSectionValidation['Total_Monthly_Expenses__c'] = false
        fourSectionValidFields = this.validationCheckForEachSection(fourSectionValidation);
        console.log("fourSectionValidFields new",fourSectionValidFields);
        this.fourthSectionValidationDuringScroll(fourSectionValidFields, allUserData);
        }
      }, 2000);
    }
      
      
    }
    
    setTimeout(() => {
    this.validationOfProgressBarAllSections(allUserData);
    this.validateFormProgress();
    }, 200);
  }

  checkNextPropsValidation = (nextProps,firstSectionValidFields,firstSectionValidation,allUserData) => {
    if((nextProps.firstSectionSteps !== this.state.firstSectionProgressValue) || firstSectionValidFields<5) {

      //28092023

      const newValue = Date.now();
      this.setState({ inputValue: newValue });
      // Clear the previous timer, if any
    clearTimeout(this.timer);
    // Set a new timer to check for changes after 3 seconds
    this.timer = setTimeout(() => {
      // Check if the input value hasn't changed for 3 seconds
      if (this.state.inputValue == newValue) {
        console.log('No change for the last 3 seconds:', newValue);
        this.firstSectionValidationDuringScroll(firstSectionValidFields, firstSectionValidation, allUserData)
      }
    }, 3000);
    
    
  }
  }

  validateEmailAddressCheck =  () => {
    //Disable interval pop-up display function to avoid multiple looping
  }

  checkEmailAddress = () => {
    const allUserValidation = Object.assign({}, ...this.props.applicationValidation);
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);

    if(this.props.isChangeEmail && allUserData['Email_Address'] !== undefined) {
      if(allUserValidation['Email_Address'] === '') {
        const mobileInputElement = document.getElementById('Email_Address');
        mobileInputElement.style.border = "none";
        if(!this.state.isEmailOTPVerified) {
          this.handleRecaptchaForResendEmail();
          
          setTimeout(() => {
            this.setState({ loading: false });
            if(this.state.codeSuccess !== '') {
              this.setState({ email: allUserData['Email_Address'], codeSuccess: '', codeError: '', codeErrorForResendCode: '', loading: false });
              
                this.props.updateEmailChange(false);
                window.$('#registerModal').modal('show');              
            }
            else if(this.state.codeErrorForResendCode !== '') {
              this.setState({ serverErrorMessage: this.state.codeErrorForResendCode, loading: false });
              window.$('#serverErrorShowModel').modal('show');
              this.props.updateEmailChange(true);
              let fieldValues = {}
              fieldValues['Email_Address'] = false;
            }
          }, 3500)
        }
      }
    }
  }

  submitSectionDetails = async (sectionHeading) => {
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);
    allUserData.flxHeader__c=sectionHeading;
    
    Object.keys(allUserData).forEach(key => {
      if (allUserData[key] === null || allUserData[key]=="") {
        delete allUserData[key];
      }
    });
    this.setState({onBlurInputFiled:false},async ()=>{
      this.aboutSectionValueSendInlogs(allUserData,'submitSectionDetails');
      await insertApplicationUserDetails(allUserData);
    })
  }


  handleSendOtpForMobile = () => {

    const allUserDataDetails = Object.assign({}, ...this.props.saveApplicationData);
    if(allUserDataDetails['Mobile_Number'] != undefined){
      this.setState({phoneNo: allUserDataDetails['Mobile_Number']});
    }
    this.handleRecaptchaForResendMobile();
          
    setTimeout(() => {
      if(this.state.codeSuccess !== '') {
        this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: '', loading: false });
        
        setTimeout(() => {
          this.props.updateMobileChange(false);
          window.$('#mobileModal').modal('show');              
        }, 800);
      }
      else if(this.state.codeErrorForResendCode !== '') {
        this.setState({ serverErrorMessage: this.state.codeErrorForResendCode, loading: false });
        window.$('#serverErrorShowModel').modal('show');
        this.props.updateMobileChange(false);
        let fieldValues = {}
        fieldValues['Mobile_Number'] = false;
      }
    }, 2500);
  }

  validateFlagOfMobileAndDateOfBirth = () => {

    if(!this.props.birthDateFlag && !this.props.mobileNumberFlag) {
      if(this.props.prepopulateStatus) {
        this.checkMfaValidationForUserToMobile();
      }
      else {
        this.handleSendOtpForMobile();
      }

    }
    else if(!this.props.birthDateFlag) { 
      this.checkIfUserExistsAfterMobileVerify()
    }
    else if(!this.props.mobileNumberFlag){
      this.handleSendOtpForMobile();
    }
    else if(this.props.birthDateFlag && this.props.mobileNumberFlag) {
      this.checkMfaValidationForUserToMobile();
    }
  }

  checkSectionValidation = (firstSectionValidFields,isFirstStepValidated,applicationValidation,isSecondStepValidated,secondSectionValidFields,thirdSectionValidFields,isThirdStepValidated) => {
    // Check the validation results for each section and handle accordingly
    if (firstSectionValidFields !== 5 || isFirstStepValidated !== 5) {
      // If the first section validation fails, log a message, scroll to the first section, and set 'applicationValidation' to false
      console.log("First step validation failed");
      applicationValidation = false;
    } else if (isSecondStepValidated !== 2 || secondSectionValidFields !== 2) {
      // If the second section validation fails, log a message, scroll to the next section, and set 'applicationValidation' to false
      console.log("Second step validation failed");
      applicationValidation = false;
    } else if (thirdSectionValidFields < 4 || isThirdStepValidated < 4) {
      // If the third section validation fails, log a message, scroll to the third section, and set 'applicationValidation' to false
      console.log("Third step validation failed");
      applicationValidation = false;
    }
    return applicationValidation;
  }

  validationForFourthSection = async (allUserData) => {

      if(!this.state.submitSectionFlag) {
        this.setState({ submitSectionFlag: true});
        this.submitSectionDetails("Income & Expenses");
      }
      
      // Initialize a flag for overall application validation
let applicationValidation = true;

// Create an object 'firstSectionField' by combining properties from 'firstSectionSteps' using Object.assign
const firstSectionField = Object.assign({}, ...this.props.firstSectionSteps);

// Perform validation for the first section and await the result
const isFirstStepValidated = this.validationForEachStep(firstSectionField);

// Create an object 'secondSectionField' for the second section
const secondSectionField = Object.assign({}, ...this.props.secondSectionSteps);

// Perform validation for the second section and await the result
const isSecondStepValidated = this.validationForEachStep(secondSectionField);

// Create an object 'thirdSectionField' for the third section
const thirdSectionField = Object.assign({}, ...this.props.thirdSectionSteps);

// Perform validation for the third section and await the result
const isThirdStepValidated = this.validationForEachStep(thirdSectionField);

// Calculate the number of valid fields in each section using 'validationCheckForEachSection'
const firstSectionValidFields = this.validationCheckForEachSection(firstSectionField);
const secondSectionValidFields = this.validationCheckForEachSection(secondSectionField);
const thirdSectionValidFields = this.validationCheckForEachSection(thirdSectionField);

const validationData = Object.assign({}, ...this.props.applicationValidation);
console.log(validationData,"validationData");

// Filter the object to include only non-empty values for specified properties
const filteredData = [];

// Specify the properties you want to check
const propertiesToNotCheck = ["stage", "frequency", "flx_DocumentAgree__c","flx_Contact_Humm_agree__c","flx_Agree__c"];


Object.keys(validationData).forEach(key => {
  if (propertiesToNotCheck.indexOf(key) !== -1) {
    console.log("skip validation",key);
  }else if(validationData[key] !=""){
    let fieldIndex=this.getLabelName(key);
    if(fieldIndex !== ''){
      filteredData.push(fieldIndex);
    }
  }
});
this.setState({failedfieldValidationCollection:filteredData});
console.log("filteredData",filteredData);

// Check the validation results for each section and handle accordingly
applicationValidation = this.checkSectionValidation(firstSectionValidFields,isFirstStepValidated,applicationValidation,isSecondStepValidated,secondSectionValidFields,thirdSectionValidFields,isThirdStepValidated);

// If the 'applicationValidation' flag is true, then all sections are validated successfully

    if(applicationValidation){
    
    setTimeout(() => {

        this.setState({fourSectionProgressValue: 100, globalProgressBarValue: 100,progressBarImageSrc: "images/bird-shadow--logo.svg", progessBarLogoStyle: 'img-sizing-svg'}, () => {
          setTimeout(() => {
            this.setState({loading: true})
          }, 200);
        });

        setTimeout(() => {
          this.setState({loading: false})
          this.props.saveIncomeDataValue(allUserData['Total_Monthly_Income__c']);
          this.setState({
            userTotalExpenditure: allUserData['Total_Expenditure__c'],
            userLoanTerm: allUserData['Loan_Term_Requested__c'],
           })
           this.checkIfEmailOTPVerified();
        }, 400);
      
    }, 1800);
  }else{
    
  window.$('#finalValidationModal').modal('show');
  }
  }


  checkIfEmailOTPVerified = () => {
    if(this.state.isMobileOTPVerified && this.state.isEmailOTPVerified) {
      window.$('#userNoteShowModel').modal('show')
     }
     else {
       this.handleRecaptchaForResendEmail();

       setTimeout(() => {
         if(this.state.codeSuccess !== '') {
           this.setState({ codeSuccess: '', codeError: '', codeErrorForResendCode: ''});
           this.setState({ loading: false });
           window.$('#registerModal').modal('show');
         }
         else if(this.state.codeErrorForResendCode !== '') {
           this.setState({ validateStatusCode: '', popupButtonLabel: '', redirectURL: '',
              serverErrorMessage: this.state.codeErrorForResendCode,loading: false });
           window.$('#serverErrorShowModel').modal('show');
           this.props.updateEmailChange(true);
           let fieldValues = {}
           fieldValues['Email_Address'] = false;
         }
       }, 2500);
    }
  }

  changeProgressBarValueForFirstSection = (allUserData) => {
    this.setState({
      disableMobileNumberField: true, firstSectionProgressValue: 25, loading: true, firstName: allUserData['First_Name'], lastName: allUserData['Last_Name'], dateOfBirth: allUserData['Birth_Date'], phoneNo: allUserData['Mobile_Number'], email: allUserData['Email_Address']
    }, async () => {
      setTimeout(() => {
        const firstSectionValidateAllField = Object.assign({}, ...this.props.firstSectionSteps);
        const firstSectionValidAllFields = this.validationCheckForEachSection(firstSectionValidateAllField);
        if (firstSectionValidAllFields === 5) {
          localStorage.setItem('email', allUserData['Email_Address'])
          this.submitSectionDetails("Personal Details");
          if (this.state.firstSectionProgressValue === 25) {

            if (localStorage.getItem('fromWhere') !== 'apply_now') {

              this.validateFlagOfMobileAndDateOfBirth();
              return false;
            }

            if (!this.state.isMobileOTPVerified) {
              this.handleSendOtpForMobile();
            }
            else {
              console.log("Mobile number already verified");
              this.handleScrollOfNextSection();
            }
          }
        } else {
          this.setState({ loading: false })
        }
      }, 2000);


    });
  }

  changeProgressBarValueForSecondSection = (secondSectionValidFields,allUserData) => {
    if(allUserData['Email_Address']){
      localStorage.setItem('email', allUserData['Email_Address'])
    }
    
    if(this.state.secondSectionProgressValue === 50) {
      return false;
    }
    else if(secondSectionValidFields === this.state.sectionTwoLength){
      this.setState({secondSectionProgressValue: 50}, () => {

      setTimeout(() => {
        const span = document.getElementById("third-section");
        span.scrollIntoView({ behavior: 'smooth' });
      },2000);

        this.submitSectionDetails("Loan Details");
        this.handleScrollOfThirdSection();
      });
    }
  }

  onVerifyReCaptcha = async() => {
    if(this.state.resendEmailFlag){
      await this.resendCodeForEmail();
    }
    if(this.state.resendMobileFlag){
      await this.resendCodeForMobile();
    }
    if(this.state.loggedUserApplyNowFlag){
      await this.getUserValidated();
    }
  }


  // To check whether the validation of input field on each section is true or false
  validationCheckForEachSection = (sectionFieldValue) => {
    
    return Object.values(sectionFieldValue).reduce((acc, value) => acc + (value ? 1 : 0), 0);
  }
  validationFinalStepsDetails=(sectionFieldValue)=>{
    let fourSectionValidation = Object.assign({}, ...this.props.fourSectionSteps);
    // Initialize a counter for validation
    let finalvalidation = 0;

    // Define an array of field names to check
    const fieldsToCheck = [
      'occupation',
      'Fractional_Residential_Expense__c',
      'monthly_expense_financial_commitments',
      'Fractional_Living_Expense__c',
      'flxNumber_of_Other_Financial_Dependents__c',
      'Employment_Stability__c',
      'Total_Monthly_Income__c'
    ];

    // Iterate through the field names to check their values
    for (const fieldName of fieldsToCheck) {
      // Retrieve the field's value from the sectionFieldValue object
      const fieldValue = sectionFieldValue[fieldName];
      
      // Check if the field value is defined and not an empty string
      if (fieldValue !== undefined && fieldValue !== '') {
        // Increment the validation counter
        finalvalidation++;
      }
    }
    let fourthSectionValidatedFieldCount = this.validationCheckForEachSection(fourSectionValidation);
    if (finalvalidation !== fourthSectionValidatedFieldCount) {
      finalvalidation = fourthSectionValidatedFieldCount;
    }
    // Return the final validation count
    
    return finalvalidation;
  }

  validateFormProgress = async () => {
    let buttonStatus= true;
    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    let isEmptyChangeEmail = false;
    const progressBarFirstSectionField = Object.assign({}, ...this.props.firstSectionSteps);
    const isProgressBarFirststepValidated = this.validationForEachStep(progressBarFirstSectionField);
    const progressBarSecondSectionField = Object.assign({}, ...this.props.secondSectionSteps);
    const isProgressBarSecondtepValidated = this.validationForEachStep(progressBarSecondSectionField);
    const progressBarThirdSectionField = Object.assign({}, ...this.props.thirdSectionSteps);
    const isProgressBarThirdstepValidated = this.validationForEachStep(progressBarThirdSectionField);
    
    const savedUserData = Object.assign({}, ...this.props.saveApplicationData);
    const fourthSectionFieldValuesValidation = this.validationFinalStepsDetails(savedUserData);
    const allUserValidation = Object.assign({}, ...this.props.applicationValidation);
    
    let validationCheckForFourthSection = false;

      const employementStability=['Student','Unemployed','Retired'];
      if (employementStability.includes(savedUserData['Employment_Stability__c'])) {
        validationCheckForFourthSection = isProgressBarSecondtepValidated >= 2 && isProgressBarThirdstepValidated >= 4 && fourthSectionFieldValuesValidation<6;
      } else {
        validationCheckForFourthSection = isProgressBarSecondtepValidated >= 2 && isProgressBarThirdstepValidated >= 4 && fourthSectionFieldValuesValidation<7;
      }
      if (isProgressBarFirststepValidated !== 5 ||
        isProgressBarSecondtepValidated !== 2 ||
        isProgressBarThirdstepValidated !== 4 ||
        validationCheckForFourthSection || allDetails['Total_Monthly_Income__c']) {
      buttonStatus = false;
      isEmptyChangeEmail = true;
    }
    if(allUserValidation['Email_Address'] !== ''){
      isEmptyChangeEmail = true;
    }
      this.setState({isFormValidated:buttonStatus,isEmptyChangeEmail: isEmptyChangeEmail});


  }

  validationForEachStep(sectionFieldValue) {
    const savedUserData = Object.assign({}, ...this.props.saveApplicationData);
    let validationCheckCount=0;
    let optionalFields=["Address_Line2__c"];
    if(Object.keys(sectionFieldValue).length>0){
      validationCheckCount = Object.entries(sectionFieldValue).map(([key, value]) =>{
      if (!optionalFields.includes(key)) {
        if(savedUserData[key] !== undefined && savedUserData[key] !== '' && savedUserData[key] !== null){
          return validationCheckCount++;
        }
      }
    });
  }

    let validatedFieldCount = this.validationCheckForEachSection(sectionFieldValue);
    if (validationCheckCount !== validatedFieldCount) {
      validationCheckCount = validatedFieldCount;
    }

    return validationCheckCount;
    
  }

  // To validate the input fields of all sections when input changes and update the progress bar value
  validationOfProgressBarAllSections = async (allDetailsData) => {
    const firstSectionField = Object.assign({}, ...this.props.firstSectionSteps);
    const isFirststepValidated = this.validationForEachStep(firstSectionField);
    const secondSectionField = Object.assign({}, ...this.props.secondSectionSteps);
    const isSecondtepValidated = this.validationForEachStep(secondSectionField);
    const thirdSectionField = Object.assign({}, ...this.props.thirdSectionSteps);
    const isthirdstepValidated = this.validationForEachStep(thirdSectionField);
    

    const savedUserData = Object.assign({}, ...this.props.saveApplicationData);
    const fourthSectionFieldValuesValidation = this.validationFinalStepsDetails(savedUserData)
    
    let validationCheckForFourthSection = false;

      const employementStability=['Student','Unemployed','Retired'];
      let finalStepSuccessed=false;
      if (employementStability.includes(allDetailsData['Employment_Stability__c'])) {
        validationCheckForFourthSection = isSecondtepValidated >= 2 && isthirdstepValidated >= 4 && fourthSectionFieldValuesValidation<6;
        finalStepSuccessed=validationCheckForFourthSection?false:true;
      } else {
        validationCheckForFourthSection = isSecondtepValidated >= 2 && isthirdstepValidated >= 4 && fourthSectionFieldValuesValidation<7;
        finalStepSuccessed=validationCheckForFourthSection?false:true;
      }

      const validationCheckForOneofEachSection =  isFirststepValidated==5 && isSecondtepValidated == 2 && isthirdstepValidated == 4 && finalStepSuccessed;
      
      this.setStateToIncreaseProgressbar(isFirststepValidated,isSecondtepValidated,isthirdstepValidated,validationCheckForFourthSection,validationCheckForOneofEachSection);

  }

  setStateToIncreaseProgressbar = (isFirststepValidated,isSecondtepValidated,isthirdstepValidated,validationCheckForFourthSection,validationCheckForOneofEachSection) => {
    if(this.state.isAmendButtonClicked || this.state.changedValueAfterComplete) {
      if(isFirststepValidated!=5){
        this.setState({globalProgressBarValue: 0})
      }
      else if(isSecondtepValidated!=2){
        this.setState({globalProgressBarValue: 25})
      }
      else if(isthirdstepValidated!=4){
        this.setState({globalProgressBarValue: 50})
      }
      else if(validationCheckForFourthSection)
      {
        this.setState({globalProgressBarValue: 75});
      }
      else if(validationCheckForOneofEachSection) 
      {
        this.setState({ globalProgressBarValue: 100});
      }
  }
}

  setSectionMovieToSmallScreen = (divposition,globalProgressBarIndicatorValue,sectionToShow) => {
    if (divposition >= 0 && divposition < 125) {
      globalProgressBarIndicatorValue = 25;
      sectionToShow = "section-one";
    } else if (divposition >= 125 && divposition < 375) {
      globalProgressBarIndicatorValue = 50;
      sectionToShow = "section-two";
    } else if (divposition >= 375 && divposition < 1600) {
      globalProgressBarIndicatorValue = 75;
      sectionToShow = "section-third";
    } else if (divposition >= 1600) {
      globalProgressBarIndicatorValue = 100;
      sectionToShow = "section-four";
    }
    return {globalProgressBarIndicatorValue:globalProgressBarIndicatorValue,sectionToShow:sectionToShow}
  }

  setSectionMovieToBigScreen = (divposition,globalProgressBarIndicatorValue,sectionToShow) => {
    if (divposition >= 0 && divposition < 300) {
      globalProgressBarIndicatorValue = 25;
      sectionToShow = "section-one";
    } else if (divposition >= 300 && divposition < 700) {
      globalProgressBarIndicatorValue = 50;
      sectionToShow = "section-two";
    } else if (divposition >= 700 && divposition < 1600) {
      globalProgressBarIndicatorValue = 75;
      sectionToShow = "section-third";
    } else if (divposition >= 1600) {
      globalProgressBarIndicatorValue = 100;
      sectionToShow = "section-four";
    }
    return {globalProgressBarIndicatorValue:globalProgressBarIndicatorValue,sectionToShow:sectionToShow}
  }

  setSectionMovieToLargeScreen = (divposition,globalProgressBarIndicatorValue,sectionToShow) => {
    if (divposition >= 0 && divposition < 200) {
      globalProgressBarIndicatorValue = 25;
      sectionToShow = "section-one";
    } else if (divposition >= 200 && divposition < 800) {
      globalProgressBarIndicatorValue = 50;
      sectionToShow = "section-two";
    } else if (divposition >= 800 && divposition < 1900) {
      globalProgressBarIndicatorValue = 75;
      sectionToShow = "section-third";
    } else if (divposition >= 1900) {
      globalProgressBarIndicatorValue = 100;
      sectionToShow = "section-four";
    }
    return {globalProgressBarIndicatorValue:globalProgressBarIndicatorValue,sectionToShow:sectionToShow}
  }
  
  // To validate the input fields of all sections when input changes and update the progress bar indicator
  validationOfProgressIndicatorSections = async (event) => {

    // Get the width and scroll position
    const width = this.state.width;
    const divposition = event.target.scrollTop;
    // Initialize variables for progress bar and section to show
    let globalProgressBarIndicatorValue = 25;
    let sectionToShow = "";

    console.log("divposition", divposition , "width", width);

    // Check screen width and scroll position to determine progress bar and section
    if (width <= 991) { // For screens less than or equal to 991px

     let checkSmallScreenResult =  this.setSectionMovieToSmallScreen(divposition,globalProgressBarIndicatorValue,sectionToShow);
     globalProgressBarIndicatorValue = checkSmallScreenResult.globalProgressBarIndicatorValue;
     sectionToShow = checkSmallScreenResult.sectionToShow;

    } else if (width <= 1199) { // For screens between 992px and 1199px

      let checkBigScreenResult =  this.setSectionMovieToBigScreen(divposition,globalProgressBarIndicatorValue,sectionToShow);
     globalProgressBarIndicatorValue = checkBigScreenResult.globalProgressBarIndicatorValue;
     sectionToShow = checkBigScreenResult.sectionToShow;

    } else if (width > 1200) { // For screens greater than 1200px

      let checkLargeScreenResult =  this.setSectionMovieToLargeScreen(divposition,globalProgressBarIndicatorValue,sectionToShow);
     globalProgressBarIndicatorValue = checkLargeScreenResult.globalProgressBarIndicatorValue;
     sectionToShow = checkLargeScreenResult.sectionToShow;

    }

    // Update the state with the calculated progress bar and section values
    this.setState({
      globalProgressBarIndicatorValue,
    });
    this.changeHeaderAndSubContentForEachSection(sectionToShow);

  }


  // To change the value of header and subcontent for progress Bar during scroll of each section
  changeHeaderAndSubContentForEachSection = (sectionId) => {
    if(sectionId == 'section-one') {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 0)
      })
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 0)
      })
    }
    else if(sectionId == 'section-two') {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 1)
      })
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 1)
      })
    }
    else if(sectionId == 'section-third') {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 2)
      })
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 2)
      })
    }
    else if(sectionId == 'section-four') {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 3)
      })
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 3)
      })
    }
  }

  // This function prepares the request payload for an API call 'application/submit_application'  
  saveApplicationUserData = (savedUserData, allUserDetails) => {
    for(let fieldName in savedUserData) {
      if(savedUserData[fieldName] !== "") {
        allUserDetails[fieldName] = savedUserData[fieldName]

      }
    }
    return allUserDetails;
  }

  checkThreasholdAmount = () => {
    if(Number(this.props.amountRequested) > Number(process.env.REACT_APP_MAXIMUM_LT_AMOUNT)){
      analyticsChangeEvent('big_thing',this.props.amountRequested)
      window.dataLayer.push({ 
        "event" : "metadata", 
        "customerType" : "[BIG THINGS]" 
      });

    } else {
      analyticsChangeEvent('little_thing',this.props.amountRequested)
        window.dataLayer.push({ 
          "event" : "metadata", 
          "customerType" : "[LITTLE THINGS]"
        });
    }
  }

  checkRedirectionBasedOnApplicationSubmitResponse = (applicationResult) => {
    if(localStorage.getItem('fromWhere') !== 'apply_now') {
      if(this.state.resultSourceURL !== ''){
        this.checkIfButtonLabelAndRedirectUrLExists(applicationResult.data.data);
      }
      else {
        this.setState({ popupButtonLabel: "Return Home"}) 
      }
    }
    else {
      this.checkIfButtonLabelAndRedirectUrLExists(applicationResult.data.data);
    }
    analyticsClickEvent('application_approved', '');
    this.setState({ hasErrorMessage: applicationResult.data.message, loading: false });
    window.$('#commonModel').modal('show');

    setTimeout(() => {
      window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
    }, 5000);
    this.props.showappValidation('');
    this.props.appValidationsRemove([]);
    window.$('#commonModel').modal({ backdrop: 'static', keyboard: false });
    localStorage.setItem('flxPersonEmail__c', '');
    return false;
  }

  // This function initiates an API call to 'application/submit_application' and handles its response.
  applicationSubmit = async () => {
    let filteredData = await this.validateMissingparams();
    if (filteredData.length > 0) {
      frontEndLogs('Missing Application Request ' + this.getApplicationIDInstorage(), false, 400, 'applicationSubmit', filteredData);
      this.setState({changedValueAfterComplete:true,failedfieldValidationCollection: filteredData,isFormValidated:false,showContinueAppButton: true},()=>{
        window.$('#finalValidationModal').modal('show');
        const allUserData = Object.assign({}, ...this.props.saveApplicationData);
        this.validationOfProgressBarAllSections(allUserData);
      })
     
      return false;
    } else {
      const savedUserData = Object.assign({}, ...this.props.saveApplicationData);

      let allUserDetails = {}

      this.setState({ obStatusRecived: true, loading: true, popupButtonLabel: '', redirectURL: '' })
      allUserDetails = this.saveApplicationUserData(savedUserData, allUserDetails)
      allUserDetails['flx_DocumentAgree__c'] = this.state.flxDocumentAgree_C
      allUserDetails['flx_Agree__c'] = this.state.flxAgree_C
      allUserDetails['flx_Contact_Humm_agree__c'] = this.state.flxContactAgree_C

      const TMXSessionId = uuidv4();
      const cookie = new Cookies();
      window.threatmetrix.profile(process.env.REACT_APP_TMXDOMAIN, process.env.REACT_APP_TMXKEY, TMXSessionId);
      allUserDetails.application_id = await this.getApplicationIDInstorage();
      allUserDetails.session_id = TMXSessionId;
      const { First_Name, Last_Name, Mobile_Number, Email_Address, Birth_Date, ...allDetails } = allUserDetails;
      this.checkThreasholdAmount();
      await frontEndLogs('Application submit request : Local Storage applicationId :' + localStorage.getItem("applicationId") + ',cookie applicationID :' + cookie.get('applicationId'), false, 200, 'applicationSubmit', allDetails);
      let applicationResult = await applicationSave(allDetails);
      await frontEndLogs('Application submit response ' + this.getApplicationIDInstorage(), false, 200, 'applicationSubmit', applicationResult);
      if (applicationResult?.data?.data?.decision_taken_by === 'LendingMetrics') {
        this.setState({ decisionByLendingMetrics: true }, async () => {
          await this.handleLendingMetricsResponse(applicationResult);
        })
        return false;
      } else if (applicationResult === undefined || applicationResult?.data?.statusCode === 503) {
        this.handleServiceUnavailableInApplicationResponse(applicationResult, 'applicationSubmit');
        return false;
      } else if (applicationResult?.data?.message === 'Sorry, something has gone wrong with the processing of your application. Please try again.') {

        this.setState({ loading: false })
        this.setState({ hasErrorMessage: applicationResult.data.message });
        window.$('#commonModel').modal('show');
        GTMApplicationFunnelSubmitError('Application Flow', 'server', 1, 2, "1:2", applicationResult?.data?.message);
        return false;
      } else if (applicationResult.data.statusCode === 202) {

        GTMApplicationSubmitStatus("Step 1", "server", 1, 2, "1 : 2", "[Approved]")
        return this.checkRedirectionBasedOnApplicationSubmitResponse(applicationResult);
      }
      else if (applicationResult.data.statusCode === 200) {
        analyticsClickEvent('application_referred', '');
        GTMApplicationSubmitStatus('Step 1', 'server', 1, 2, "1 : 2", '[Refer]')
        localStorage.getItem('fromWhere') !== 'apply_now' ? this.setState({ popupButtonLabel: "Return Home" }) : this.checkIfButtonLabelAndRedirectUrLExists(applicationResult.data.data);
        this.setState({ hasErrorMessage: applicationResult.data.message, loading: false, messageTitle: applicationResult.data.data.message_title })
        window.$('#commonModel').modal('show');

        setTimeout(() => {
          window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
        }, 5000);
        this.props.showappValidation('');
        this.props.appValidationsRemove([]);
        window.$('#commonModel').modal({ backdrop: 'static', keyboard: false });
        localStorage.setItem('flxPersonEmail__c', '');
        return false;
      }
      else if (applicationResult.data.statusCode === 417) {
        let errorMessage = applicationResult.data.message;

        analyticsClickEvent('application_declined', '');
        GTMApplicationSubmitStatus('Step 1', 'server', 1, 2, "1 : 2", "[Decline]")
        this.checkIfButtonLabelAndRedirectUrLExists(applicationResult.data.data);
        this.setState({ loading: false, hasErrorMessage: errorMessage, messageTitle: applicationResult.data.data.message_title });
        window.$('#commonModel').modal('show');
        setTimeout(() => {
          window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
        }, 5000);

      }
      else if (applicationResult.status === 206) {
        this.setState({ loading: false });
        this.props.showappValidation('');
        this.setState({ isMitekProcessReached: true, showQR: true })
        window.scrollTo(0, 0);
        await this.handleLogsForDocumentpage('applicationSubmit');
      }
      else {
        GTMApplicationFunnelSubmitError('Application Flow', 'server', 1, 2, "1:2", applicationResult?.data?.message);
        this.setState({ serverErrorMessage: applicationResult?.data?.message, loading: false })
        window.$('#serverErrorShowModel').modal('show');
      }
    }

  }

   validateMissingparams = async () =>{
    let missingParamsData = [];
    let applicationValidationData = Object.assign({}, ...this.props.applicationValidation);
    const doNotCheckTheFields = ["stage", "frequency", "flx_DocumentAgree__c", "flx_Contact_Humm_agree__c", "flx_Agree__c"];
    applicationValidationData = Object.keys(applicationValidationData)
  .filter(key => !doNotCheckTheFields.includes(key))
  .reduce((obj, key) => {
    obj[key] = applicationValidationData[key];
    return obj;
  }, {});
    Object.keys(applicationValidationData).forEach(key => {
      if (applicationValidationData[key] != "") {
        let fieldIndex = this.getLabelName(key);
        if(fieldIndex !== ''){
          missingParamsData.push(fieldIndex);
        }
      }
    });
    return missingParamsData;
  }

  // To handle the service unavailable when  application submit
  handleServiceUnavailableInApplicationResponse = async (applicationResult, methodName) => {
    try {
      await frontEndLogs('Service unavailable when application submit ' + localStorage.getItem("applicationId"), false, 200, methodName, applicationResult);
      if (this.state.retryLimit < 4) {
        setTimeout(async () => {
          this.setState({ retryLimit: this.state.retryLimit + 1 }, () => {
            this.applicationSubmit()
          })
        }, 10000);
      } else {
        let serviceDelayRespone = 'Something has gone wrong while processing your application, please apply again. If this has occurred previously, please email contactuk@shophumm.com and we will investigate.';
        let convertMessage = this.convertMessage(serviceDelayRespone);
        this.setState({ obStatusRecived: false, loading: false, hasErrorMessage: convertMessage }, () => {
          window.$('#commonModel').modal('show');
        })
      }
    } catch (error) {
      await frontEndLogs('Catch Error ' + localStorage.getItem("applicationId"), true, 400, 'handleServiceUnavailableInApplicationResponse', error?.message);
    }
  }

  // To handle the lending metrics backend response
  handleLendingMetricsResponse = async(applicationResult)=>{
    try {
      if(applicationResult?.data?.statusCode === 206){
          analyticsClickEvent('application_warning_ob', '');
        GTMApplicationSubmitStatus('Step 1','server', 1, 2,"1 : 2", '[Warning-OB]')
        await frontEndLogs('Application response received as Warning-OB '+await this.getApplicationIDInstorage(),false,200,'handleLendingMetricsResponse',applicationResult?.data?.data);
        await this.handleWarningOBResponse();
          
        }else  if(applicationResult?.data?.statusCode === 202){
        await frontEndLogs('Showed the Mitek screen after the application was accepted or refer '+await this.getApplicationIDInstorage(),false,200,'handleLendingMetricsResponse');
      this.props.showappValidation('');
      this.setState({isMitekProcessReached: true, showQR: true },()=>{
        this.setState({ loading: false,obStatusRecived:false });
      })
      window.scrollTo(0, 0);
      await this.handleLogsForDocumentpage('applicationSubmit');
        
      }else if(applicationResult?.data?.statusCode === 417){
        let firstStageDeclineErrorMessage = applicationResult.data.message;

      analyticsClickEvent('application_declined_based_on_lendingmetrics', '');
      GTMApplicationSubmitStatus('Step 1','server', 1, 2, "1 : 2", "[Decline]")
      this.checkIfButtonLabelAndRedirectUrLExists(applicationResult.data.data);
      this.setState({ obStatusRecived:false, loading: false, hasErrorMessage: firstStageDeclineErrorMessage,messageTitle:applicationResult.data.data.message_title });
      window.$('#commonModel').modal('show');
      setTimeout(() => {
        window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
      }, 5000);
      } else if (applicationResult === undefined || applicationResult?.data?.statusCode === 503) {
        this.handleServiceUnavailableInApplicationResponse(applicationResult, 'handleLendingMetricsResponse');
        return;
      } else if (applicationResult?.data?.statusCode === 400) {
        let convertMessage = this.convertMessage(applicationResult.data.message);
        this.setState({ loading: false, obStatusRecived: false, hasErrorMessage: convertMessage })
        window.$('#commonModel').modal('show');
        return false;
      } else {
        await frontEndLogs('Submit application another response ' + await this.getApplicationIDInstorage(), false, 400, 'handleLendingMetricsResponse', applicationResult?.data);
        this.setState({ loading: false, obStatusRecived: false });
        this.setState({ hasErrorMessage: "Something went wrong" })
        GTMApplicationFunnelSubmitError('Application Flow','server',1,2,"1:2","Something went wrong");
        window.$('#commonModel').modal('show');
      }
      return;
    } catch (error) {
      await frontEndLogs('Catch Error '+await this.getApplicationIDInstorage(),true,400,'handleLendingMetricsResponse',error?.message);
    }
  }

  // To handle Waring-ob response to show consent
  handleWarningOBResponse = async()=>{
    try {
      let requestPayloadOBInitialize = {
        "application_id": await this.getApplicationIDInstorage(),
        "email":  localStorage.getItem('email') ?? this.state.email,
        "userId": localStorage.getItem('userID'),
      }
      let obInitializeResponse =  await openBankingInitialize(requestPayloadOBInitialize);
      await frontEndLogs('Open banking initialize response '+await this.getApplicationIDInstorage(),false,200,'handleWarningOBResponse',obInitializeResponse?.data);
      if(obInitializeResponse && obInitializeResponse?.data?.statusCode === 201){
        if(obInitializeResponse?.data?.data?.url){
          this.setState({openBankingRedirectURL: obInitializeResponse.data.data.url,openWarningOBConsent:true},()=>{
            const applicationBodyElement = document.querySelector('body');
            applicationBodyElement.classList.remove('applicationPage');
            this.setState({ loading: false,obStatusRecived:false })
            })
        }else{
          await this.unableToRetrieveLink(obInitializeResponse);
        }        
      }else {
        await this.unableToRetrieveLink(obInitializeResponse);
      }
    } catch (error) {
      await frontEndLogs('Catch Error '+await this.getApplicationIDInstorage(),true,400,'handleWarningOBResponse',error?.message);
    }
  }

  // Handle the situation where the OB Link cannot be retrieved.
  unableToRetrieveLink = async(obInitializeResponse)=>{
      await frontEndLogs('The backend response OB link could not be retrieved. '+await this.getApplicationIDInstorage(),false,400,'unableToRetrieveLink',obInitializeResponse?.data);
      this.setState({ serverErrorMessage: "Something went wrong. Please try again." })
      window.$('#serverErrorShowModel').modal('show');
      this.setState({ loading: false })
  }

// will be sent to a third party in order to finish the OB procedure.
  redirectionToOpenBankingScreen = async() => {
    try {
      this.setState({ loading: true });
      await frontEndLogs('The user click on continue with open banking button '+await this.getApplicationIDInstorage(),false,200,'redirectionToOpenBankingScreen',this.state.openBankingRedirectURL);
      let requestofContinueOpenBankingUser = {
        "application_id": await this.getApplicationIDInstorage(),
        "url": this.state.openBankingRedirectURL
      }
      await continueOpenBanking(requestofContinueOpenBankingUser);
      window.location.href = this.state.openBankingRedirectURL;
    } catch (error) {
      await frontEndLogs('Catch Error '+await this.getApplicationIDInstorage(),true,400,'redirectionToOpenBankingScreen',error?.message);
    }
    
  }

// To proceed with the Mitek flow in lending metrics flow 
  continueOnMitekFlow = async()=>{
    try {
      this.setState({ loading: true });
      await frontEndLogs('Continued the Mitek flow after the application status was Warning-OB'+await this.getApplicationIDInstorage(),false,200,'continueOnMitekFlow');
      let requesttoContinueWithoutOpenBankingUser = {
        "application_id": await this.getApplicationIDInstorage(),
        "url": ""
      }
      await continueOpenBanking(requesttoContinueWithoutOpenBankingUser);
      this.props.showappValidation('');
      this.setState({openWarningOBConsent:false,decisionByLendingMetrics:false,isMitekProcessReached: true, showQR: true },()=>{
        this.setState({ loading: false });
      })
      window.scrollTo(0, 0);
      await this.handleLogsForDocumentpage('applicationSubmit');
    } catch (error) {
      await frontEndLogs('Catch Error '+await this.getApplicationIDInstorage(),true,400,'continueOnMitekFlow',error?.message);
    }
  }

// To handling the response for OB success and failure
  handlingOBSuccessResponse = async (result)=>{
    try {
      const urlParams = new URLSearchParams(window.location.search);
      await frontEndLogs('Following being redirected to the application send the OB status',false,200,'handlingOBSuccessResponse',window.location.href);
      const obStatus = urlParams.get('status');
      const obTaskId = urlParams.get('task_id');
      const applicationId = urlParams.get('application_id');
      if(obStatus && obStatus === 'success' && obTaskId){
        this.setState({ obStatusRecived:true,obStatus:obStatus,loading:true})
        clearTimeout(this.timer);
          this.timer = setTimeout(async () => {
          this.props.history.push('/');
          const { pathname, search } = this.props.location;
          const newSearch = search.replace(`?status=${obStatus}&task_id=${obTaskId}`, '');
          this.props.history.replace({ pathname, search: newSearch });
              await this.getOBApplicationStatus(obStatus,obTaskId,applicationId);

        }, 5000);
        
      }else if(obStatus && obStatus === 'failure'){
        this.setState({ obStatusRecived:true,obStatus:obStatus,serverErrorMessage: "Unfortunately, Your attempt to authenticate with open banking has failed. We kindly request that you try the process again." },()=>{
          window.$('#serverErrorShowModel').modal('show');
        })
      }
    } catch (error) {
      await frontEndLogs('Catch Error',true,400,'handlingOBSuccessResponse',error?.message);
    }
    
  }

  // To find out the status of an OB application
  getOBApplicationStatus = async(obStatus,obTaskId,applicationId)=>{
    try {
      let requestOfOBApplication = {
        "status": obStatus,
        "task_id":  obTaskId,
        "application_id":applicationId
      }
      let getOBApplicationResponse =  await getOBApplicationStatus(requestOfOBApplication);
      await frontEndLogs('Open banking attempted application status - '+obTaskId,false,200,'getOBApplicationStatus',getOBApplicationResponse);
      if (getOBApplicationResponse === undefined || getOBApplicationResponse?.data?.statusCode === 503) {
        GTMServerSubmitError('Step 2', 'client', "Service unavailable", 2, 2, '2 : 2')
        this.handleServiceUnavailableInOBResponse(obTaskId, getOBApplicationResponse, obStatus);
      } else if(getOBApplicationResponse.data.statusCode === 202) {
  
        GTMApplicationSubmitStatus("Step 2","server", 2, 2, "2 : 2", "[Approved]")
        return this.checkRedirectionBasedOnApplicationSubmitResponse(getOBApplicationResponse);
      }
      else if(getOBApplicationResponse.data.statusCode === 200) {
        analyticsClickEvent('application_referred', '');
        GTMApplicationSubmitStatus('Step 2','server', 2, 2,"2 : 2", '[Refer]')
        localStorage.getItem('fromWhere') !== 'apply_now' ? this.setState({ popupButtonLabel: "Return Home"}) :this.checkIfButtonLabelAndRedirectUrLExists(getOBApplicationResponse.data.data);
        this.setState({ hasErrorMessage: getOBApplicationResponse.data.message, loading: false,messageTitle:getOBApplicationResponse.data.data.message_title })
        window.$('#commonModel').modal('show');
  
        setTimeout(() => {
          window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
        }, 5000);
        this.props.showappValidation('');
        this.props.appValidationsRemove([]);
        window.$('#commonModel').modal({ backdrop: 'static', keyboard: false });
        localStorage.setItem('flxPersonEmail__c', '');
        return false;
      }
      else if (getOBApplicationResponse.data.statusCode === 417) {
        let obApplicationErrorMessage = getOBApplicationResponse.data.message;
  
        analyticsClickEvent('application_declined', '');
        GTMApplicationSubmitStatus('Step 2','server', 2, 2, "2 : 2", "[Decline]")
        this.checkIfButtonLabelAndRedirectUrLExists(getOBApplicationResponse.data.data);
        this.setState({ loading: false, hasErrorMessage: obApplicationErrorMessage,messageTitle:getOBApplicationResponse.data.data.message_title });
        window.$('#commonModel').modal('show');
        setTimeout(() => {
          window.open(this.state.redirectURL, '_self', 'noopener noreferrer');
        }, 5000);
  
      }else if (getOBApplicationResponse?.data?.statusCode === 400) {
        let convertMessage = this.convertMessage(getOBApplicationResponse.data.message);
        this.setState({ loading: false, obStatusRecived: false, hasErrorMessage: convertMessage })
        window.$('#commonModel').modal('show');
        return false;
      }
      else {
        GTMServerSubmitError('Step 2', 'server', getOBApplicationResponse?.data?.message, 2, 2, '2 : 2')
        this.setState({ hasErrorMessage:  getOBApplicationResponse?.data?.message, loading: false ,unableToProvide:true},()=>{
          window.$('#commonModel').modal('show');
        })
        return false;
      }

    } catch (error) {
      await frontEndLogs('Catch Error '+ obTaskId,true,400,'getOBApplicationStatus',error?.message);
    }
  }

// To handle the service unavailable issue when OB flow
  handleServiceUnavailableInOBResponse = async (obTaskId, getOBApplicationResponse, obStatus) => {
    try {
      await frontEndLogs('Service unavailable in Open banking flow - ' + obTaskId, false, 200, 'handleServiceUnavailableInOBResponse', getOBApplicationResponse);
      if (this.state.retryLimit < 4) {
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          this.setState({ retryLimit: this.state.retryLimit + 1 }, () => {
            this.getOBApplicationStatus(obStatus, obTaskId)
          })
        }, 10000);
      } else {
        let serviceDelayRespone = 'Something has gone wrong while processing your application, please apply again. If this has occurred previously, please email contactuk@shophumm.com and we will investigate.';
        let convertMessage = this.convertMessage(serviceDelayRespone);
        this.setState({ loading: false, hasErrorMessage: convertMessage }, () => {
          window.$('#commonModel').modal('show');
        });
        return false;
      }
    } catch (error) {
      await frontEndLogs('Catch Error ' + obTaskId, true, 400, 'handleServiceUnavailableInOBResponse', error?.message);
    }
  }
  
  handleLogsForDocumentpage = async (methodName)=>{
    let { width } = this.state;
      let isMobile = width <= 768;
      if(isMobile){
       await frontEndLogs('All available document are showed in document verify'+await this.getApplicationIDInstorage(),false,200,methodName);
      }else{
      await frontEndLogs('QR Page showed - '+this.getApplicationIDInstorage(),false,200,methodName);
      }
  }


  displayResendSMSForEmail = () => {
    if(this.state.disableField) {
        return (
            <button id="resend-code" className="resendCode">Resend code</button>
        );
    }
    else {
        return(
          <button id="resend-code" className="resendCode" 
          onClick={(e) => this.handleRecaptchaForResendEmail(e)} onKeyDown={(e) => { e.preventDefault(); }} >Resend code</button>
        );
    }

  }

  stylingForShowMitekProcess = () => {
    return (
      `tab-pane fade ${this.state.isMitekProcessReached ? 'active show' : ''}`
    );
  }

  stylingToHideApplicationFlow = () => {
    return (
      `below-form-section ${this.state.isMitekProcessReached ? 'd-none' : ''}`
    );
  }

  stylingOfNextButtonPopup = () => {
    return (
      `${this.state.disablePopupRegistration === 'disable-popup' ? 'btn btn-primary disable-popup' : 'btn btn-primary'}`
    );
  }

  analyticsClickEventPurchaseJourney = () => {
    analyticsClickEvent('return_to_purchase_journey','')
  }

  displayChangeEmail= () => {
    if(this.state.disableField){
        return (
            <button className="emailChange" style={{pointerEvents:"none"}} >
                            Wrong Email? Change here</button>
        );
    }
    else if(this.props.isDisableChangeEmailOption){
      return(
        <button className="emailChange" style={{pointerEvents:"none"}} >
            Wrong Email? Change here
        </button>
      )
    }
    else {
        return(
          <button className="emailChange" onClick={()=> {
            this.setState({onBlurInputFiled:false},()=>{
            let fieldValues = {}
            fieldValues['Email_Address'] = false;
            this.validateEmailAddressCheck();
            this.clearEmailAddressAndHighlightField();
            this.props.firstSectionValidationUpdate(fieldValues);
            this.hidePopup('#registerModal') })}}
            onKeyDown={(e) => { e.preventDefault(); }}
            >
              Wrong Email? Change here
          </button>
        )
    }
  }

  handleEmailPopupcloseButton = () => {
    if(this.props.isDisableChangeEmailOption || this.state.disableVerifyOkButton){
      return(
        <a href aria-label="Close" 
                            className="close" onClick={(e) => { e.preventDefault(); }} style={{pointerEvents:"none"}}>
                              <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
                          </a>
          );
    }else{
      return(
        <a href aria-label="Close" 
        className="close" onClick={() => {
          this.setState({onBlurInputFiled:false},()=>{
          let fieldValues = {}
          fieldValues['Email_Address'] = false;
          this.validateEmailAddressCheck();
          this.clearEmailAddressAndHighlightField();
          this.props.firstSectionValidationUpdate(fieldValues);
          this.hidePopup("#registerModal") })
          }}>
          <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
      </a>
      );
    }
  }

  handleMobilePopupcloseButton = () => {
    if(this.props.isDisableChangeMobileOption || this.state.disableVerifyOkButton){
          return(
            <a href aria-label="Close" 
            className="close" onClick={(e) => { e.preventDefault(); }} style={{pointerEvents:"none"}}>
                <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
            </a>
          );
    }else{
      return(
        <a href aria-label="Close" 
                      className="close" onClick={()=> {
			this.setState({onBlurInputFiled:false, disableMobileNumberField: false},()=>{
                        this.props.updateMobileChange(true);
                        let fieldValues = {}
                        fieldValues['Mobile_Number'] = false;
                        this.clearMobileNumberAndHighlightField();
                        this.props.firstSectionValidationUpdate(fieldValues);
                        this.hidePopup("#mobileModal")
                        })
                        } }>
                          <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
                      </a>
      );
    }
  }

  displayResendSMSForMobile = () => {
    if(this.state.disableField) {
        return (
            <button id="resend-code" className="resendCode">Resend Code</button>
        );
    }
    else {
        return(
          <button id="resend-code" className="resendCode" 
          onKeyDown={(e) => { e.preventDefault(); }} onClick={(e) => this.handleRecaptchaForResendMobile(e)}  >Resend Code</button>
        );
    }

  }

  displayChangeMobileNumber = () => {
    if(this.state.disableField){
        return (
            <button className="emailChange" style={{pointerEvents:"none"}}>
                            Wrong Number? Change here</button>
        );
    }
    else if(this.props.isDisableChangeMobileOption){
      return(
        <button className="emailChange" style={{pointerEvents:"none"}}>Wrong Number? Change here</button>
      )

    }
    else {
        return(
          <button className="emailChange" 
          onKeyDown={(e) => { e.preventDefault(); }}
          onClick={()=> {
	  this.setState({onBlurInputFiled:false},()=>{
            this.props.updateMobileChange(true);
            let fieldValues = {}
            fieldValues['Mobile_Number'] = false;
            this.clearMobileNumberAndHighlightField();
            this.props.firstSectionValidationUpdate(fieldValues);
            this.hidePopup("#mobileModal")
            this.setState({disableMobileNumberField: false});
	  })
          }}>Wrong Number? Change here</button>
        )
    }
  }


  /* Display the success message when the message is not empty */
  resendSuccessMessagDisplay = () => {
    if(this.state.codeSuccess !== '') {
      return (
        <span>{this.state.codeSuccess}</span>
      );
    }
  }

  /* Display the error message when the message is not empty */
  resendErrorMessageDisplay = () => {
    if(this.state.codeErrorForResendCode !== '') {
      return (
        <span style={{ color: "#e60000" }}>{this.state.codeErrorForResendCode}</span>
      );
    }
  }

  redirectionForErrorPopupUpIfRedirectURLExists = () => {
    if (this.state.redirectURL !== '' && this.state.redirectURL !== undefined) {
      this.setState({ loading: true })
      window.location.href = this.state.redirectURL
    }
    else if (localStorage.getItem('fromWhere') === 'apply_now') {
      this.props.history.push("/login");
      window.location.reload(false)
    }
    else {
      this.props.history.push("/home");
    }

  }

  redirectCodeScreen = () => {
   
    if(this.state.isContinuePrevAmount === false){
      analyticsClickEvent('cancel','')
    }else{
      analyticsClickEvent('popup_ok','');
    }
   
    window.$('#commonModel').modal('hide');
    window.$('#userBalanceShowModel').modal('hide')
    window.$('.modal-backdrop').removeClass('show');
    window.$('body').removeClass('modal-open');
    window.$('.modal-backdrop').remove();
    if (this.state.unableToProvide === false) {
      this.props.appValidationsRemove([]);
      this.props.getAmountRequested('');
      this.props.showappValidation('');
      this.props.removeSavedata([]);

      this.redirectionForErrorPopupUpIfRedirectURLExists();      
    }

  }

  clearMobileNumberAndHighlightField = () => {
    this.setState({loading: true, disableMobileNumberField: false});
    const inputElement = document.getElementById('Mobile_Number');
    inputElement.style.border = "1px solid #ff6200";
    let inputValues = {}
    inputValues['Mobile_Number'] = '';
    this.props.saveApplicationDatas(inputValues);

    setTimeout(() => {
      this.setState({loading: false})
      inputElement.focus();
    }, 2500);
  }

  handleLoaderStatus = (value) => {
    let loaderId = document.querySelector('.wrapper.welcome-page.loader-page');
    if(value === true){
      loaderId.style.backgroundColor = 'rgba(250, 250, 250, 0.980)';
    }
    else {
      loaderId.style.backgroundColor = '';
    }
    this.setState({loading: value})
  }

  handleRetryLoaderStatus = (value) => {
    this.setState({loading: value})
  }

  hidePopup = (modalName) => {
    // Reset the variables on popup to its initial value when the popup is dismissed
    this.setState({code: '', disableVerifyOkButton: false, codeError: '', codeSuccess: '', 
    codeErrorForResendCode: '', disableField: false ,verificationMessage: ''});

    window.$(modalName).modal("hide");
  }

  handChange = (event) => {

    const name = event.target.name;
    const value = event.target.checked;

    switch(name){
      case 'flxAgree_C':
        if(value){
          this.setState({ flxAgree_C: true});

          this.setState({flxAgree_c_Error: ''});
        }
        else {
          this.setState({ flxAgree_C: false});
          this.setState({ flxAgree_c_Error: 'This is Required'})
        }
        break;

      case 'flxDocumentAgree_C':
        if(value){
          this.setState({ flxDocumentAgree_C: true});

          this.setState({flxDocumentAgree_c_Error: ''});
        }
        else {
          this.setState({ flxDocumentAgree_C: false});
          this.setState({flxDocumentAgree_c_Error: 'This is Required'});
        }
        break;
      case 'flxContactAgree_C':
        if(value){
          this.setState({ flxContactAgree_C: true});

          this.setState({flxContactAgree_C_Error: ''});
        }
        else {
          this.setState({ flxContactAgree_C: false});
          this.setState({flxContactAgree_C_Error: 'This is Required'});
        }
        break;
      default:
        break;
    }
  }

  buttonLabel = () => {
    return (this.state.popupButtonLabel !== '' ? this.state.popupButtonLabel : `Cancel`);
  }

  getRetailerFromBranchSDK = async () => {
    const cookie = new Cookies();
    let Dob = cookie.get("dob");
    try {
      let self = this;
      frontEndLogs('Branch SDK Initiated', false, 200, 'getRetailerFromBranchSDK');
      branch.init(process.env.REACT_APP_BRANCH_KEY, async function (err, data) {
        await frontEndLogs('Branch SDK Initiate response', false, 200, 'getRetailerFromBranchSDK', { 'Error': err, 'Data': data });
        if (!err) {
          branch.data(async function (err, data) {
            await frontEndLogs('Branch SDK data response', false, 200, 'getRetailerFromBranchSDK', { 'Error': err, 'Data': data });
            if (err) {
              await frontEndLogs('Error fetching Branch deep link data', false, 400, 'getRetailerFromBranchSDK', err);
              self.handleApplicationQuestion();
            } else if (data?.data_parsed?.retailer_id) {
              localStorage.setItem('storeID', data.data_parsed.retailer_id)
              if (Dob) {
                cookie.set('Dob', Dob, { path: '/', maxAge: 3600 });
              }
              await frontEndLogs('Retailer ID received from branch link ' + data.data_parsed.retailer_id, false, 200, 'getRetailerFromBranchSDK');
              self.handleApplicationQuestion();
            } else {
              await frontEndLogs('Retailer ID not received from branch link ', false, 404, 'getRetailerFromBranchSDK', data.data_parsed);
              self.handleApplicationQuestion();
            }
          });
        } else {
          await frontEndLogs('Branch SDK initiate error', false, 400, 'getRetailerFromBranchSDK', err);
          self.handleApplicationQuestion();
        }
      });
    } catch (error) {
      frontEndLogs('Branch SDK catch error', true, 400, 'getRetailerFromBranchSDK', error);
      this.handleApplicationQuestion();
    }
  }


  getRetailerDetailsFromBackend = async() => {
    const cookie = new Cookies(); 
    let decryptedPayload = cookie.get('decryptedPayload');
    let browserUrl = window.location.href
    if(decryptedPayload){
      browserUrl = `${window.location.origin}${window.location.pathname}?${decryptedPayload}`
    }
    let getRetailerRequest = {     
        expandedUrl: browserUrl 
    };
    let retailerResponse = await getRetailer(getRetailerRequest)
    await frontEndLogs('Retailer response through the webhook in the Backend Branch SDK', false, 200, 'getRetailerDetailsFromBackend',retailerResponse?.data);
    if(retailerResponse?.data?.statusCode === 200){
      let reatilerData = retailerResponse?.data?.data;
      if(reatilerData?.retailer_id){
        localStorage.setItem('storeID', reatilerData?.retailer_id)
        cookie.set('retailerId',reatilerData?.retailer_id);
        this.handleApplicationQuestion();
      }else {
        await frontEndLogs('The retailer ID was not received for the purchase link from the backend API ', false, 200, 'getRetailerDetailsFromBackend', retailerResponse?.data);
        this.handleApplicationQuestion();
      }
    } else if (retailerResponse?.data?.statusCode === 400) {
      this.handleApplicationQuestion();
    } else {
      await frontEndLogs('Other responses received from backend in the purchase flow', true, 400, 'getRetailerDetailsFromBackend', retailerResponse?.data);
      this.handleApplicationQuestion();
    }
  }

  handleApplicationQuestion = async()=>{
    if(localStorage.getItem('fromWhere') == 'apply_now')
      {
        await this.getUserValidated();
      } 
      else {
        await this.loggedUserApplyNow();
     }
  }

  redirectionOfApplyNowButton = async () => {
    this.setState({ loading: true, isShowDecommissionMessage: false }, async () => {
      const cookie = new Cookies();
      let branchReferrer = window.location.search.includes('_branch_referrer');
      if (cookie.get('branchReferrer')) {
        branchReferrer = cookie.get('branchReferrer')
      }
      let branchTrackingId = window.location.href.includes('trackingId');
      if (cookie.get('trackingId')) {
        branchTrackingId = cookie.get('trackingId')
      }
      let quoteLoanAmount = localStorage.getItem('quote-loan-amount');
      let quoteLoanTerm = localStorage.getItem('quote-loan-term');
      let quoteRetailerId = localStorage.getItem('RetailerIdFromQuoteTool');
      if ((quoteLoanAmount && quoteLoanTerm && quoteRetailerId) || !branchReferrer) {
        this.handleApplicationQuestion();
      } else if (branchReferrer && branchTrackingId) {
        await this.getRetailerDetailsFromBackend()
      } else if (branchReferrer && !branchTrackingId) {
        frontEndLogs('Attempted to retrieve the retailerId using the Branch SDK.', false, 200, 'redirectionOfApplyNowButton');
        await this.getRetailerFromBranchSDK();
      } else {
        this.handleApplicationQuestion();
      }
    });
  }

  stylingForMissingFieldsList = () => {

    if(this.state.stepZeroData !== '') {
      return 'ml-1'; 
    }
    else {
      return 'ml-5';
    }

  }

  handleScrollOfNextSection = () => {
    const nextSection = document.getElementById("second-section");
    const prevSection = document.getElementById("first-section");

    const span = document.getElementById("second-section");

    setTimeout(() => {
      this.setState({loading: true})
    }, 300);

    setTimeout(() => {
      this.setState({loading: false, globalProgressBarValue: 25}, () => {
        this.showSectionSubContext();
        this.showSectionHeading(); 
      });
      if(nextSection && this.state.firstSectionProgressValue === 25){

        if(this.state.width <= 768) {
          window.scrollTo({ top: nextSection.offsetTop +30, behavior: "smooth"})
        }
        else {
          // styling for position scroll view of second section 
          nextSection.style.marginBottom = "410px"
          prevSection.style.marginBottom = "210px"
          window.scrollTo({ top: nextSection.offsetTop - 110, behavior: "smooth"})
        }

        span.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          this.validationforPrepopulatedOfSecondSection();
        }, 1100);
        GTMValidStepsSubmit('About You','Client','1','4','1:4');
      }
    }, 1500);
  }

  handleScrollOfThirdSection = () => {
    const thirdSection = document.getElementById("third-section");

    setTimeout(() => {
      this.setState({loading: true})
    }, 300);


    setTimeout(() => {
      this.setState({loading: false, globalProgressBarValue: 50}, ()=> {
        this.showSectionHeading();
        this.showSectionSubContext();
      })

      if(thirdSection && this.state.secondSectionProgressValue === 50){
        if(this.state.width <= 768) {
          window.scrollTo({ top: thirdSection.offsetTop +30, behavior: "smooth"})
        }
        else {
          thirdSection.style.marginBottom = "250px"
          window.scrollTo({ top: thirdSection.offsetTop - 190, behavior: "smooth"})
        }

        setTimeout(() => {
          this.validationforPrepopulatedOfThirdSection();
        }, 1100);
        GTMValidStepsSubmit('Loan Details','Client','2','4','2:4');
      }
    }, 1500);
  }

  updateIncomeValue = () => {
    const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);

    this.props.saveIncomeDataValue(allUserDetails['Total_Monthly_Income__c']) 
  }

  handleScrollOfFourSection = () => {
    const fourSection = document.getElementById("four-section");

    setTimeout(() => {
      this.setState({loading: true})
    }, 300);
    
    setTimeout(() => {
      this.setState({loading: false, globalProgressBarValue: 75}, () => {
        this.showSectionHeading(); 
        this.showSectionSubContext();
        this.submitSectionDetails("Current Address");
      })
      if(fourSection && this.state.thirdSectionProgressValue === 75){

        if(this.state.width <= 768) {
          window.scrollTo({ top: fourSection.offsetTop + 30, behavior: "smooth"})
        }
        else {
          window.scrollTo({ top: fourSection.offsetTop - 150, behavior: "smooth"})
        }

        this.setState({showFooterText: true});

        GTMValidStepsSubmit('Address','Client','3','4','3:4');
      }
    }, 1500);
  }

  scrollToFirstSection = () => {
    const firstSection = document.getElementById("first-section");

    setTimeout(() => {
      if(firstSection){

        if(this.state.width <= 768) {
          window.scrollTo({ top: firstSection.offsetTop +30, behavior: "smooth"})
        }
        else {
          window.scrollTo({ top: firstSection.offsetTop - 190, behavior: "smooth"})
        }
        
        firstSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1500);

  }

  validationforPrepopulatedOfSecondSection = () => {
    const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
    const validationData = Object.assign({}, ...this.props.applicationValidation);
    let fieldValues = {}, stageTwoValue= {}
    if (validationData['Loan_Term_Requested__c']) {
      fieldValues['Loan_Term_Requested__c'] = validationData['Loan_Term_Requested__c'];
      stageTwoValue['Loan_Term_Requested__c'] = false;
    } else if (allUserDetails['Loan_Term_Requested__c'] !== '' &&
      allUserDetails['Loan_Term_Requested__c'] !== undefined) {
      fieldValues['Loan_Term_Requested__c'] = '';
      stageTwoValue['Loan_Term_Requested__c'] = true;
    }
    
    this.props.appvalidations(fieldValues);
    this.props.secondSectionValidationUpdate(stageTwoValue);
  }

  validationforPrepopulatedOfThirdSection = () => {
    const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
    let fieldValues = {}, stageThreeValues= {}

    if((this.props.prepopulateStatus) && allUserDetails['Address_Line1__c'] !== '' && 
      allUserDetails['Address_Line1__c'] !== undefined) {
      fieldValues['Address_Line1__c'] = '';
      stageThreeValues['Address_Line1__c'] = true;
    }
    if((this.props.prepopulateStatus) && allUserDetails['Postal_Code__c'] !== '' && 
      allUserDetails['Postal_Code__c'] !== undefined) {
      fieldValues['Postal_Code__c'] = '';
      stageThreeValues['Postal_Code__c'] = true;
    }
    if((this.props.prepopulateStatus) && allUserDetails['Town__c'] !== '' && 
      allUserDetails['Town__c'] !== undefined) {
      fieldValues['Town__c'] = '';
      stageThreeValues['Town__c'] = true;
    }
    if((this.props.prepopulateStatus) && allUserDetails['Current_Residential_Status__c'] ) {
      
      fieldValues['Current_Residential_Status__c'] = '';
      stageThreeValues['Current_Residential_Status__c'] = true;
    }

    this.props.appvalidations(fieldValues);
    this.props.thirdSectionValidationUpdate(stageThreeValues);
  }

  validationforPrepopulatedOfFourthSection = () => {
    const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);
    let fieldValues = {}, stageFourValues= {}, stage={}

    if(this.props.prepopulateStatus && allUserDetails['Employment_Stability__c'] !== undefined) {
      fieldValues['Employment_Stability__c'] = '';
      stageFourValues['Employment_Stability__c'] = true;
    }
    if((this.props.prepopulateStatus) && allUserDetails['occupation'] !== '' && 
    allUserDetails['occupation'] !== undefined) {
      fieldValues['occupation'] = '';
      stageFourValues['occupation'] = true;
      stage['occupation'] = ''
      this.props.appvalidations(stage);
      this.props.getEmploynmentstatus(true);

    }
    if((this.props.prepopulateStatus) && allUserDetails['flxNumber_of_Other_Financial_Dependents__c'] !== '' && 
    allUserDetails['flxNumber_of_Other_Financial_Dependents__c'] !== undefined) {
      fieldValues['flxNumber_of_Other_Financial_Dependents__c'] = '';
      stageFourValues['flxNumber_of_Other_Financial_Dependents__c'] = true;
      stage['flxNumber_of_Other_Financial_Dependents__c'] = ''
      this.props.appvalidations(stage);
    }

    this.props.appvalidations(fieldValues);
    this.props.fourSectionValidationUpdate(stageFourValues);
  }

  setSectionHeading = (i, eachHeading, value) => {
    if(i==value) {
      this.setState({sectionHeadingValue: eachHeading[value]})
    }
  }

  setSectionContext = (i, eachContext, value) => {
    if(i==value) {
      this.setState({sectionContextValue: eachContext[value]})
    }
  }

  setCommonModelButtonLabel = () => {
    return (this.state.popupButtonLabel ? this.state.popupButtonLabel : 'Ok');
  }

  delimiterAddedAmountField = (number) => {
    if(number !== undefined) {
      let numStr = number.toString();
      let integerPart = numStr.split('.')[0];
      let decimalPart = numStr.split('.')[1] || '';
      
      let formattedIntegerPart = '';
      let count = 0;
  
      // Iterate through the integer part in reverse and add commas
      for (let i = integerPart.length - 1; i >= 0; i--) {
          count++;
          formattedIntegerPart = integerPart[i] + formattedIntegerPart;
          if (count % 3 === 0 && i !== 0) {
              formattedIntegerPart = ',' + formattedIntegerPart;
          }
      }
  
      // Add decimal part if exists
      let formattedNumber = decimalPart ? formattedIntegerPart + '.' + decimalPart : formattedIntegerPart;
      return formattedNumber;
    }
    

  }

  showSectionHeading = () => {
    if(this.state.globalProgressBarValue === 0) {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 0)
      })
    }
    else if(this.state.globalProgressBarValue === 25) {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 1)
      })
    }
    else if(this.state.globalProgressBarValue === 50) {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 2)
      })
    }
    else if(this.state.globalProgressBarValue === 75) {
      this.props.sectionHeading.map((eachHeading, i) => {
        this.setSectionHeading(i, eachHeading, 3)
      })
    }
  }

  showSectionSubContext = () => {
    if(this.state.globalProgressBarValue === 0) {
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 0)
      })
    }
    else if(this.state.globalProgressBarValue === 25) {
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 1)
      })
    }
    else if(this.state.globalProgressBarValue === 50) {
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 2)
      })
    }
    else if(this.state.globalProgressBarValue === 75) {
      this.props.sectionSubContext.map((eachContext, i) => {
        this.setSectionContext(i, eachContext, 3)
      })
    }
  }

  showAnimateForProgress = () => {
    if(this.state.globalProgressBarValue === 0) {
      return "";
    }
    else if(this.state.globalProgressBarValue === 25) {
      return "progressBar20";
    }
    else if(this.state.globalProgressBarValue === 50) {
      return "progressBar40";
    }
    else if(this.state.globalProgressBarValue === 75) {
      return "progressBar60"
    }
    else if(this.state.globalProgressBarValue >75) {
      return "progressBar100";
    }
  }

  animateHummLogoStyling = () => {
    if(this.state.globalProgressBarValue === 0) {
      return "";
    }
    else if(this.state.globalProgressBarValue === 25) {
      return "humm-logo--position-for-twenty";
    }
    else if(this.state.globalProgressBarValue === 50) {
      return "humm-logo--position-for-forty";
    }
    else if(this.state.globalProgressBarValue === 75) {
      return "humm-logo--position-for-sixty"
    }
    else if(this.state.globalProgressBarValue > 75) {
      return "humm-logo--position-on-completion";
    }
  }

  stylingForDisplayFooterTextDisclaimer = () => {
    return this.state.showFooterText ? 'text-center footer-text': 'text-center footer-text d-none';
  }

  isDisplayMarketingConsentForLoginUser = () => {
    return !(this.state.isEmailConsentChecked || this.state.isSMSConsentChecked || this.state.isMobileAppConsentChecked)
  }

  stylingForApplicationHeading = (isMobile) => {
    return isMobile ? 'application-heading mb-2' : 'application-heading';
  }

  stylingForVerticalIndicatorIcons = (isMobile) => {
    return isMobile ? 
      'vertical-carousel-scrollview-sections d-none' : 'vertical-carousel-scrollview-sections';
  }

  stylingForSpacingToFirstSection = (isMobile) => {
    return isMobile ? 'row ml-0 mr-0 firstSection-Scrollable': 'row ml-0 mr-0';
  }

  stylingForProgressBar = (isMobile) => {
    return isMobile ?
      'col-md-4 slidecontainer-form px-5 progress-bar-section fixed-topProgressBar d-none':
      'col-md-4 slidecontainer-form progress-bar-section fixed-topProgressBar';
  }

  stylingForAllSectionsResponsively = (isMobile) => {
    return isMobile ? 
      'col-md-7 form-row ml-0 mb-5 m-0' : 'col-md-7 form-row application-sections';
  }

  stylingForDisplayTwoSection = () => {
    return this.state.firstSectionProgressValue === 25 && this.state.isMobileOTPVerified ? 
      'second-section form-row align-items-end' : 'second-section form-row align-items-end d-none';
  }

  stylingForDisplayThirdSection = () => {
    return this.state.secondSectionProgressValue === 50 ? 
      'third-section form-row align-items-end' : 'third-section form-row align-items-end d-none';
  }

  stylingForDisplayFourthSection = () => {
    return this.state.thirdSectionProgressValue == 75 ? 
      'four-section form-row align-items-end': 'four-section form-row align-items-end d-none';
  }

  stylingForSummaryPopupCheckBox = (isMobile) => {
    return isMobile ? 'ml-0 mt-5 application-checkBox': ' applicationInputCheckbox';
  }

  redirectionThirdPartnerSite = () => {
    window.open("https://www.cifas.org.uk/fpn", '_blank', 'noopener noreferrer');
    window.$('#thirdPartyWebsiteModel').modal('hide');
  }

  checkBoxValidationForJustConfirm = (çheckBoxName) => {
    if(çheckBoxName === 'flxAgree_C'){
      return this.state.flxAgree_C === true ? "images/checked.svg" : "images/unchecked.svg"
    }

    if(çheckBoxName === 'flxDocumentAgree_C'){
      return this.state.flxDocumentAgree_C === true ? "images/checked.svg" : "images/unchecked.svg"
    }
    if(çheckBoxName === 'flxContactAgree_C'){
      return this.state.flxContactAgree_C === true ? "images/checked.svg" : "images/unchecked.svg"
    }
  }

  valueCheckForProgressBarValue = () => {
    return this.state.globalProgressBarValue === 110 ? 100 : this.state.globalProgressBarValue;
  }

  stylingForContinueAppButton = () => {
    return this.state.showContinueAppButton ? 'btn btn-primary mt-0 mb-5' : 'btn btn-primary d-none'
  }

  termsDisplay = () => {
   return this.props.amountRequested >= 500.00 ? 'Months' : 'Weeks';
  }

  removeWebView(){
    if(localStorage.getItem('webView') != null){
      localStorage.removeItem('webView');
      window.location.reload(false)
    }
  }

  getApplicationIDInstorage(){
    const cookie = new Cookies();
    return localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId')
  }


  render() {
    const allUserData = Object.assign({}, ...this.props.saveApplicationData);
    console.log('check_props_termdata_allUserData',allUserData);
    const { width } = this.state;
    const isMobile = width <= 768;
    const { step } = this.state;
    const cookie = new Cookies();
    this.removeWebView();
    if(this.state.openWarningOBConsent){
      return (
        <div>
           <Loader loaderStatus={this.state.loading} obStatusRecived = {this.state.obStatusRecived}/>
        <OpenBankingConsent redirectToOpenBanking={this.redirectionToOpenBankingScreen} redirectToMitekPage={this.continueOnMitekFlow}></OpenBankingConsent>  
        </div>        
      )
    }

    if (step !== 0 && !this.state.openWarningOBConsent) {


      return (
        <div className="wrapper application">
          <Loader loaderStatus={this.state.loading} obStatusRecived = {this.state.obStatusRecived}/>
          <section className="main-application-page">
            <div className="container-terms-page">
            { !this.state.privacyPolicyCheck && 
                <div className="top-nav-bar--terms-page" >
                  <div className="start-the-application--heading d-flex justify-content-center">
                    <h2 className>Before you start, please ensure you meet the following: </h2>
                  </div>
                  <div className="start-the-application--subsection text-justify">
                    <div className="icon-need-item">
                        <div className="icon--items">
                            <div className="icon-monthly">
                                <div className="icon-circle">

                                    <img src="images/live-permanent-UK.svg" className="living-in-uk" alt="living in UK" />

                                </div>
                                <div className="icon-para">
                                    <p>Live permanently <br /> in the UK</p>
                                </div>
                            </div>
                            <div className="icon-monthly">
                                <div className="icon-circle">
                                    <img src="images/valid-government-ID.svg" className="valid-id-card" alt="valid ID card" />
                                </div>
                                <div className="icon-para">
                                    <p>Have valid <br /> government ID to hand</p>
                                </div>
                            </div>
                            <div className="icon-monthly">
                                <div className="icon-circle">
                                    <img src="images/earn-over-1000-per-month.svg" className="monthly-income" alt="monthly income" />
                                </div>
                                <div className="icon-para">
                                    <p>Earn over £1,000 <br/>each month</p>
                                </div>
                            </div>
                            <div className="icon-monthly">
                                <div className="icon-circle">
                                    <img src="images/bank-credit-history.svg" className="credit-statement" alt="credit statement" />
                                </div>
                                <div className="icon-para">
                                    <p>Have a good <br></br>credit history</p>
                                </div>
                            </div>
                        </div>
                      </div>

                    <p className='terms-of-privacy-policy'>
                    Please read our <a href="https://www.shophumm.com/uk/privacy-policy/" target='_blank' rel="noopener noreferrer"
                    className='apply-important-info-link' id="privacy-policy-link">
                    Privacy Policy</a> which sets out the basis on which we will process your personal data when you use our services.&nbsp;
                    <span className='d-block'>By clicking "Continue"
                     you agree that you have read and accepted all of the important information above. </span>
                    </p>

                    {this.isDisplayMarketingConsentForLoginUser() && 
                    <div>
                      <p className='subheading'>
                        Marketing Consent Preferences
                      </p>

                      <div className="marketing-constent--terms pl-0">
                        <p className >humm would love to send you exclusive offers from 
                        our partner retailers and keep you up to date with the latest news from us. Please select how you'd like to hear from us below. You can opt-out anytime. For details on how we use your personal information please visit our <a
                        id="marketing-consent--privacy-policy"
                        href="https://www.shophumm.com/uk/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                    
                        <div className='marketingConsent--button-group'>
                            <div className="app-form-check form-group">
                              <a onChange={this.handleInputChange} >
                                <img classname="checkBox" src={this.state.emailCheckConsentSrc} alt="checkbox" name="emailRadio"
                                 value={localStorage.getItem('emailConsent') === 'true'} />
                                

                                <label htmlFor="emailRadio" className="radio-label">Email</label>
                              </a>
                              <input type="checkbox" name="emailRadio" id="emailRadio" className="app-form-check-input" 
                              checked={localStorage.getItem('emailConsent') === 'true'} onChange={this.handleInputChange}/>
                            </div>

                            <div className="app-form-check form-group">
                              <a onChange={this.handleInputChange} >
                                <img classname="checkBox" src={this.state.smsCheckConsentSrc} alt="checkbox"  name="smsRadio"
                                 value={localStorage.getItem('smsConsent') === 'true'}/>

                                <label htmlFor="smsRadio" className="radio-label">SMS</label>
                              </a>
                              <input type="checkbox" name="smsRadio" id="smsRadio" className="app-form-check-input" 
                              checked={localStorage.getItem('smsConsent') === 'true'} onChange={this.handleInputChange} />
                              
                            </div>
                        </div>
                      </div>

                    </div>
                    }

                  </div>

                  <div className="modal-footer d-flex flex-column"
                  style={{borderTop: "none"}}>
                      {localStorage.getItem('sessionId') !== null &&
                            <Reaptcha
                              ref={event => (this.reCaptchaRef = event)}
                              size="invisible"
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              onLoad={this.onLoad}
                              onVerify={this.onVerify}
                                />
                      }
                    <button type="button" id="applyButton" className="btn btn-primary" ref={this.bottomScrollRef}
                    disabled={ this.state.isDisableContinue }
                    onClick={() => { this.redirectionOfApplyNowButton().catch(error => console.error(error)) }}>Continue</button>
                    {cookie.get('approvedURL') != null &&
                      <div className='text-center mt-3'>
                        <a href={cookie.get('approvedURL')} target='_self' rel="noopener noreferrer"
                        className='apply-important-info-link mb-4' onClick={this.analyticsClickEventPurchaseJourney}>
                          Got your limit? Start Purchase</a>
                      </div>
                  }
                  </div>
                </div>
              }

              {this.state.privacyPolicyCheck &&
              <div className="appfrom-section">
                {!this.state.isMitekProcessReached &&
                  <div className={this.stylingForApplicationHeading(isMobile)}>
                    <center className="top-nav-bar">
                        <h2>
                          Let's get started
                        </h2>

                        <p className='pb-0 mb-0'>
                          To make this process even faster for you, when you complete each section, you will be automatically scrolled to the next. 
                        </p>
                    </center>
                  </div>
                }

                {<div className={this.stylingToHideApplicationFlow()}>

                  <div className='tab-pane fade active show' id="stepOne" role="tabpanel" aria-labelledby="stepOne-tab">
                      <div className="step-two-section step-one">
                      
                    <form autoComplete='nope'>

                      <div className={`slidecontainer-form progress-bar-section ${isMobile ? '': 'd-none'}`}>
                        <h3 className='mt-2 mb-0'>
                          {this.state.sectionHeadingValue}
                        </h3>

                        <p className='mt-3 mb-0'>
                          {this.state.sectionContextValue}
                        </p>

                        <div className='app-progressBar progress-moved'>
                            <div className={`progress-bar2 ${this.showAnimateForProgress()}`}></div>
                            {this.state.progressBarImageSrc !== null && 
                              <img
                              src={this.state.progressBarImageSrc}
                              alt="logo"
                              className={`img-fluid ${this.animateHummLogoStyling()} ${this.state.progessBarLogoStyle}`}
                              id="progressBar-slider"
                              />
                            }
                        </div>

                        <p>
                          <span id="demo"></span>
                          {this.valueCheckForProgressBarValue()}% Complete
                        </p>
                        
                      </div>

                      

                      <div className={this.stylingForSpacingToFirstSection(isMobile)} >
                        <div className={this.stylingForProgressBar(isMobile)}>
                          <h3 className=''>
                            {this.state.sectionHeadingValue}
                          </h3>

                          <p className='section-context'>
                            {this.state.sectionContextValue}
                          </p>  

                          <div className='app-progressBar progress-moved mb-3'>
                            <div className={`progress-bar2 ${this.showAnimateForProgress()}`}></div>
                            {this.state.progressBarImageSrc !== null && 
                              <img
                              src={this.state.progressBarImageSrc}
                              alt="logo"
                              className={`img-fluid ${this.animateHummLogoStyling()} ${this.state.progessBarLogoStyle}`}
                              id="progressBar-slider"
                              />
                            }
                          </div>
                          
                          <p>
                            <span id="demo"></span>
                            {this.valueCheckForProgressBarValue()}% Complete
                          </p>
                          
                        </div>

                        
                        <div className={this.stylingForAllSectionsResponsively(isMobile)} onScroll={this.handleScroll} onTouchMove={this.handleScroll}>

                          <div className='first-section form-row align-items-end' id="first-section" data-section="#section-one">
                          
                            {this.state.stepZeroData?.map((data, i) =>
                                <Element fieldItem={data['Questions']}  ref={this.child} stage={'0'} mobileno={this.state.phoneNo} change={this.handleChange} showValidation={this.state.displayErrorsStepOne}  key={data.sections} goBackAmendClicked = {this.state.isAmendButtonClicked} onBlurInputFiled = {this.state.onBlurInputFiled} disableMobileNumberField = {this.state.disableMobileNumberField}/>
                              )}

                          </div>
                          <div className='section-break'></div>
                          <div className={this.stylingForDisplayTwoSection()} id='second-section' data-section="#section-two">
                          
                            {this.state.secondSection?.map((data, i) =>
                              <Element fieldItem={data['Questions']} ref={this.child} stage={'1'} mobileno={this.state.phoneNo} change={this.handleChange} showValidation={this.state.displayErrorsStepOne}  key={data.sections}  goBackAmendClicked = {this.state.isAmendButtonClicked} onBlurInputFiled = {this.state.onBlurInputFiled}/>
                            )}
                          </div>

                          {(this.state.globalProgressBarValue >= 25 || this.state.secondSectionProgressValue == 50) && 
                            <div className='section-break'></div>
                          }
                          <div className={this.stylingForDisplayThirdSection()} id='third-section' data-section="#section-third">
                          
                            {this.state.thirdSection?.map((data, i) =>
                            <Element fieldItem={data['Questions']}  ref={this.child} stage={'2'} mobileno={this.state.phoneNo} change={this.handleChange} showValidation={this.state.displayErrorsStepOne}  key={data.sections}  goBackAmendClicked = {this.state.isAmendButtonClicked} onBlurInputFiled = {this.state.onBlurInputFiled}/>
                          )}
                          </div>

                          {this.state.thirdSectionProgressValue === 75 && 
                            <div className='section-break'></div>
                          }
                          <div className={this.stylingForDisplayFourthSection()} id='four-section' data-section="#section-four">
                          
                            {this.state.fourSection?.map((data, i) =>
                              <Element fieldItem={data['Questions']}  ref={this.child} stage={'3'} mobileno={this.state.phoneNo} change={this.handleChange} showValidation={this.state.displayErrorsStepOne}  key={data.sections}  goBackAmendClicked = {this.state.isAmendButtonClicked} onBlurInputFiled = {this.state.onBlurInputFiled}/>
                            )}
                          </div>

                           
                          {this.props.isChangeEmail && 
                              
                            <button type="button" id="continueAppButton"
                            className='btn btn-primary mt-0 mb-5'
                            onClick={() => { 
                              let fieldValues = {}
                              fieldValues['Email_Address'] = true;
                              this.handlechangeemailNotTriggred() }}
                              disabled={this.state.isEmptyChangeEmail}>
                              Continue
                            </button>
                          }

                              {this.state.isFormValidated && 
                              
                          <button type="button" id="continueAppButton"
                          className={this.stylingForContinueAppButton()}
                          onClick={() => { 
                            this.updateIncomeValue();
                            window.$('#userNoteShowModel').modal('show')
                             }}>
                            Continue
                          </button>
                              }
                              {!this.state.isFormValidated && 
                              
                              <button type="button" id="continueAppButton"
                              className={this.stylingForContinueAppButton()}
                              disabled>
                                Continue
                              </button>
                                  }

                        </div>
                    <div className={this.stylingForVerticalIndicatorIcons(isMobile)}>
                      {Array.from({ length: 4 }, (_, index) => (
                      <span
                        key={`section-${index + 1}`}
                        className={`app-section${this.state.globalProgressBarIndicatorValue === ((index+1) * 25) ? ' is-active' : ''}`}
                        id={`section-${index + 1}`}
                      ></span>
                      ))}
                      </div>
                          

                        
                      </div>
                      <div className={this.stylingForDisplayFooterTextDisclaimer()}>
                        <p>
                        humm is a trading style of Humm Group Limited which is authorised and regulated by the Financial Conduct Authority. Financial Services Registration Number 954478.</p>
                      </div>

                      <Reaptcha
                          ref={event => (this.reCaptchaRef = event)}
                          size="invisible"
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          badge="bottomright"
                          onLoad={this.onLoad}
                          onVerify={this.onVerify}
                      />

                    </form>

                    </div>
                  </div>
                </div>
                
                }
                {<div className='mitek-section-page'>
                  <div className={this.stylingForShowMitekProcess()} id="stepFour" role="tabpanel" aria-labelledby="stepFour-tab">
                    <div className="step-two-section">
                        <span>
                          {isMobile === true && (
                            <DocumentVerify
                              ref={this.miteck}
                              handleViewChange={this.tabonchange}
                              handleRetryLoader={this.handleRetryLoaderStatus}
                            />
                          )}
                          {isMobile === false && this.state.showQR === true && ( 
                            <div>
                              <div className='mitek-section-heading'>
                                <h2 className="application-identity-title">
                                Let's confirm your identity
                                </h2>

                                <p className='subcontext'>
                                Last step, grab your passport or driving licence and then scan the QR code below using your mobile device to continue.
                                </p>
                              </div>
                              <div className='mitek-option-sections'>
                                <div className='qrcode-option'>
                                  <QrCode ref={this.qrRef} handleLoader={this.handleLoaderStatus} enableWebCapture={this.isEnableDesktopCapture} />
                                </div>
                              
                                <div className='separate-section'>
                                  <span className='vertical-line'></span>
                                  <h3 className='or-subtext'>OR</h3>
                                  <span className='vertical-line'></span>
                                </div>

                                <div className='smslink-option'>
                                  <form>
                                    <div className="form-group identity-doc-form" >
                                      <label htmlFor="phoneNumber" className='text-left d-block mb-2'>Phone Number</label>

                                      <input type="text"  id='phoneNumber' value={this.state.phoneNo} name="phoneNo" 
                                        disabled={this.state.phoneNo !== ''}
                                        className="form-control" placeholder="Phone No" />
                                    </div>
                                  </form>

                                  <div className="next-btn  text-center">
                                    <button type="button" style={{backgroundColor: "#ff6c00"}}
                                    disabled={this.state.sendButtonDisable}
                                    className="btn btn-primary" id="btn-one" onClick={() => { this.mitekVerificationLinkForUser() }} >Send</button>
                                  </div>
                                </div>
                              </div>
                          </div>
                           )}
                        </span>

                    </div>
                  </div>
                </div>
                }
                {/* section close */}
              </div>
              }
            </div>
          </section>
          <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">�</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="mt-4">You will Be Lose Data</p>
                </div>
                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary">Ok</button>
                </div>
              </div>
            </div>
          </div>
          
          {/* session Expired */}

          <div className="modal fade model-error" id="sessionExpiredModal" aria-labelledby="sessionExpiredModalLabel" tabIndex="-1" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  <h5 className="modal-title " id="sessionExpiredModalLabel">Message</h5>
                  {/* <p className="text-center mt-4">Please enter the verification code we've sent by SMS</p> */}
                  <p className="text-center mt-4">Your session has expired. Please verify again to proceed</p>
                </div>

                <div className="modal-footer mb-4">
                  <button
                    type="button"
                    data-toggle="modal"
                    className="btn btn-primary" id="session-expired-button"
                    data-dismiss="modal"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* stage save message modal */}
          <div className="modal fade model-error" id="commonModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="commonModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  {this.state.messageTitle &&
                  <h5 className="modal-title message-title" id="commonModellLabel">{this.state.messageTitle}</h5>
                  }
                  <p className="text-center mt-4" >{parse(this.state.hasErrorMessage)}</p>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" onClick={() => this.redirectCodeScreen()}>
                    {this.setCommonModelButtonLabel()}
                  </button>
                </div>


              </div>
            </div>
          </div>

          {/* field error show modal */}

          <div className="modal fade model-error" id="fieldErrorShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="fieldErrorShowModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  {/* <h5 className="modal-title " id="fieldErrorShowModellLabel">Message</h5> */}
                  <div>
                  <p className="text-center mt-4">There are <b>{this.state.errorCount}</b> required fields that are either blank or incorrect. Please check again.</p>
                  {this.state.errorCount<=3 &&
                  <div className='missing-fields'>
                      {this.state.requiredFieldCount!=0 &&
                      <h5 className="text-center mt-1 modal-title">Missing Fields</h5>
                      }
                      {this.state.LabelNames != undefined && <ul className='pl-0'> 
                        {
                        this.state.LabelNames?.map((response, index) =>
                          <li className={`text-left mt-4 ${this.stylingForMissingFieldsList()}`} key={response.toString()}>{response}</li>)
                        }
                      </ul>
                      }
                   </div>
                  }
                   </div>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.analyticsClickEvent('fieldErrorShowModel')}>Ok</button>
                </div>


              </div>
            </div>
          </div>
          {/* userNoteShowModel show modal */}
          <div className="modal fade model-error" id="userNoteShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="userNoteShowModelLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a href aria-label="Close" data-dismiss="modal" className="close" onClick={()=> {
                  this.setState({ isAmendButtonClicked: true, showContinueAppButton: true });
                } }>
                  <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
                </a>
                <div className="modal-body p-0">
                    <div className="logo">
                      <img src="images/modal-logo.svg" alt="logo" className="img-fluid mb-2" />
                    </div>
                    <div className="heading text-center ">               
                      <h2 className='mb-0' style={{fontFamily: "ProximaNova-ExtraBold"}}>Before you continue…</h2>
                    </div>

                  <div className='sub-header'>
                    <p className=' text-wrap' style={{color: '#212529', fontFamily: "Proxima Nova, sans-serif"}}>
                    Please ensure that the below information is correct before you submit.
                    </p>
                  </div>

                  <div class="label-container">
                    <div class="label-column">
                      { (this.props.amountRequested !== undefined && this.props.amountRequested !== '') &&
                        <div class="label-item"> 
                          <h4>Loan Amount:</h4>
                          <span>£{this.delimiterAddedAmountField(this.props.amountRequested)}</span>
                        </div>
                      }
                      {allUserData['Loan_Term_Requested__c'] !== ''  && 
                        <div class="label-item">
                          <h4>Loan Term:</h4>
                          <span>{allUserData['Loan_Term_Requested__c']}&nbsp;{this.termsDisplay()}</span>
                        </div>
                      }

                      
                    </div>
                    <div class="label-column">
                      { this.props.saveIncomeData !== ''  && 
                        <div class="label-item">
                          <h4>Net Monthly Income:</h4>
                          <span>£{this.delimiterAddedAmountField(this.props.saveIncomeData)}.00</span>
                        </div>
                      }
                      {this.props.saveexpendituredata !== "" &&
                        <div class="label-item">
                          <h4>Total Monthly Expenses:</h4>
                          <span>£{this.delimiterAddedAmountField(this.props.saveexpendituredata)}.00</span>
                        </div>
                      }
                    </div>

                  </div>

                  <div className={this.stylingForSummaryPopupCheckBox()}>
                      <div className='form-check form-group mb-1'>
                        <a className='checkBox' onChange={(e) => this.handChange(e)} >
                          <img className="checkBox" src={ this.checkBoxValidationForJustConfirm('flxAgree_C') } alt="" /> 
                          <input value={this.state.flxAgree_C} onChange={(e) => this.handChange(e)} name='flxAgree_C' type="checkbox" className="form-check-input" id='flxAgree_C' />
                         
                          <label className={`form-check-label text-justify`} htmlFor='flxAgree_C'>
                          <a className="d-block label-link" role="button" tabIndex={0} onKeyDown={(e) => { 
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                              } 
                            }} onClick={(e) => {
                              this.analyticsCheckBoxClickEvent(e);
                            }} 
                            style={{ fontFamily: 'ProximaNova-Medium' }}
                          >
                            {this.props.DocumentAgreeLabel && parse(this.props.DocumentAgreeLabel)}
                          </a>
                          </label>
                        </a>
                          
                          <p className="error-message text-left mb-2 mt-1">
                            {this.state.flxAgree_c_Error &&
                              <span class="app-error-message" style={{ color: 'Red' }}>
                              {this.state.flxAgree_c_Error}</span>
                            }
                          </p>
                      </div>

                      <div className='form-check form-group mb-1'>
                        <a className='checkBox' onChange={(e) => this.handChange(e)} >
                            <img className="checkBox" src={ this.checkBoxValidationForJustConfirm('flxDocumentAgree_C') } alt="" /> 
                            <input value={this.state.flxDocumentAgree_C} onChange={(e) => this.handChange(e)} name='flxDocumentAgree_C' type="checkbox" className="form-check-input" id='flxDocumentAgree_C' />
                          
                          <label className={`form-check-label text-justify`} htmlFor='flxDocumentAgree_C'>
                          <a className="d-block label-link" role="button" tabIndex={0} onKeyDown={(e) => { 
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                              } 
                            }} onClick={(e) => {
                              this.analyticsCheckBoxClickEvent(e);
                            }} 
                            style={{ fontFamily: 'ProximaNova-Medium' }}
                          >
                            {this.props.AgreeLabel && parse(this.props.AgreeLabel)}
                          </a>
                          </label>
                        </a>
                          
                          <p className="error-message text-left mb-2 mt-1">
                            {this.state.flxDocumentAgree_c_Error &&
                              <span class="app-error-message" style={{ color: 'Red' }}>
                              {this.state.flxDocumentAgree_c_Error}</span>
                            }
                          </p>
                      </div>

                      <div className='form-check form-group mb-1'>
                        <a className='checkBox' onChange={(e) => this.handChange(e)} >
                            <img className="checkBox" src={ this.checkBoxValidationForJustConfirm('flxContactAgree_C') } alt="" />
                            <input value={this.state.flxContactAgree_C} name='flxContactAgree_C' 
                            type="checkbox" onChange={(e) => this.handChange(e)}
                            className="form-check-input" id='flxContactAgree_C' />
                         
                            <label className={`form-check-label text-justify`} htmlFor='flxContactAgree_C'>
                            <a className="d-block label-link" role="button" tabIndex={0} onKeyDown={(e) => { 
                              if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                              } 
                            }} onClick={(e) => {
                              this.analyticsCheckBoxClickEvent(e);
                            }} 
                            style={{ fontFamily: 'ProximaNova-Medium' }}
                          >
                            {this.props.contactDocumentAgree && parse(this.props.contactDocumentAgree)}
                          </a>
                            </label>
                        </a>
                          
                          <p className="error-message text-left mb-2 mt-1">
                            {this.state.flxContactAgree_C_Error &&
                              <span class="app-error-message" style={{ color: 'Red' }}>
                              {this.state.flxContactAgree_C_Error}</span>
                            }
                          </p>
                      </div>
                  </div>

                </div>

                <div className='modal-footer mt-0 mb-0 mx-0'>
                  <button type="button" class="btn btn-warning btn-fill"  
                  disabled={ !(this.state.flxAgree_C && this.state.flxDocumentAgree_C && this.state.flxContactAgree_C) }
                  
                  onClick={() => { 
                    window.$('#userNoteShowModel').modal('hide')
                    this.applicationSubmit().catch(error => console.error(error)) }}

                  >
                    Continue and Submit
                  </button>
                  {/* </div>
                  <div className="modal-footer mt-0 mb-0 mx-0"> */}
                  <button type="button" class="btn btn-warning btn-outline" 
                  onClick={() => {
                      this.setState({ isAmendButtonClicked: true, showContinueAppButton: true });
                      this.scrollToFirstSection();
                      window.$('#userNoteShowModel').modal('hide')
                      analyticsClickEvent('go_back_and_amend_button', '')
                      frontEndLogs('go_back_and_amend_button_clicked',false,200,'userNoteShowModel').catch(error => console.error(error));
                    }}>
                      Go Back and Amend
                  </button>
                </div>

              </div>
            </div>
          </div>
          {/* userBalanceShowModel show modal */}
          <div
            className="modal fade model-error"
            id="userBalanceShowModel"
            tabIndex="-1"
            role="dialog"
            data-keyboard="false"
            data-backdrop="static"
            aria-labelledby="userBalanceShowModellLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header"></div>
                <div className="modal-body">
                  <div className="logo">
                    <img
                      src="images/modal-logo.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  {/* <h5 className="modal-title " id="userBalanceShowModellLabel">
                    Message
                  </h5> */}
                  <p className="text-center mt-4" id="prevamtmsg">
                    {parse(this.state.approvedApplicationMessage)}
                  </p>
                </div>

                <div className="modal-footer mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={(e) => this.redirectCodeScreen(e)}
                  >
                    {this.buttonLabel()}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => { this.createNewApplication().catch(error => console.error(error)) }}
                  >
                    New Application
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* show server error message */}
          <div className="modal fade model-error" id="serverErrorShowModel" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="serverErrorShowModellLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                  </div>
                  {/* <h5 className="modal-title " id="serverErrorShowModelLabel">Message</h5> */}
                  {/* Added the margin left and right spacing to message displayed in popup */}
                  <p className="text-center mt-4 mx-3">{parse(this.state.serverErrorMessage)}</p>
                </div>

                <div className="modal-footer mb-4">
                  {!this.state.obStatusRecived && 

                  this.serverErrorRedirection()
                }

                {this.state.obStatusRecived && this.state.obStatus === 'failure' &&
                this.redirectionBasedOnOB()
                }

                </div>


              </div>
            </div>
          </div>

          <div className="modal fade" id="mobileSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="mobileSuccessModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-body pt-0 pb-0">
                          <div className="logo">
                              <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                          </div>

                          <h5 className="modal-title text-center" id="mobileSuccessModalLabel">
                            Confirmed
                          </h5>

                        {this.state.verifyMessage !== '' &&
                          <p className='sucess-message text-center'>
                            {parse(this.state.verifyMessage)}
                          </p>
                        }
                      </div> 
                    </div>
              </div>
           </div>

           <div className="modal fade" id="registerSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="mobileSuccessModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-body pt-0 pb-0">
                          <div className="logo mt-5 mx-auto">
                              <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                          </div>

                          <h5 className="modal-title text-center" id="registerSuccessModalLabel">
                            Confirmed
                          </h5>

                        {this.state.verifyMessage !== '' &&
                          <p className='sucess-message text-center'>
                            {parse(this.state.verifyMessage)}
                          </p>
                        }
                      </div> 
                    </div>
              </div>
           </div> 

          {/* Email Verification popup */}
          <div className="modal fade" id="registerModal" tabIndex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
		                {this.handleEmailPopupcloseButton()}
                      <div className="modal-body pt-0 pb-0">
                          <div className="logo">
                              <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                          </div>


                          
                          <p className="sub-context">Enter the 5-digit code we have sent to {this.state.email ||localStorage.getItem('email')}</p>

                          <input className="form-control" type="text" id="code" name="code" placeholder="Enter code here"
                              value={this.state.code} onKeyPress={(event) => { this.onKeyPressData(event) }} onPaste={(e) => { this.onlyAcceptInputs(e) }}
                              required onChange={(e) => this.handleInputChange(e)} />


                          
                        <p className="text-center modal-error-msg mt-3">
                          { this.state.codeError !== '' &&
                            <span style={{ color: "#e60000",fontSize: "0.8rem" }}>
                              { this.state.codeError }</span>
                          }
                        </p>

                        <p className="text-center link--block mb-0">
                            { this.displayResendSMSForEmail() }
                            
                            {this.state.showMobileandEmailAddress && this.displayChangeEmail() }
                        </p>
                      </div>
                      {(this.state.codeSuccess !== ''|| this.state.codeErrorForResendCode !== '') &&
                        <p id="verificationMessage" className="modal-error-msg text-center">
                          { this.resendSuccessMessagDisplay() }
                          { this.resendErrorMessageDisplay() }
                        </p>
                      }

                        {localStorage.getItem('sessionId') !== null &&
                          <Reaptcha
                            size="invisible"
                            ref={event => (this.reCaptchaRef = event)}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onLoad={this.onLoad}
                            onVerify={this.onVerify}
                            />
                        }

                      <div className="modal-footer pb-0 pl-0">
                          <button type="button" id='register-redirect-screen' 
                          className={this.stylingOfNextButtonPopup()}
                          disabled={this.state.disableVerifyOkButton} 
                          onClick={(e) => this.handleRecaptchaEmailVerify(e)}>Continue</button>
                          
                      </div>
                  </div>
              </div>
          </div>

          {/* Validation Failed Pop-up before final step */}
<div className="modal fade finalValidationModal" id="finalValidationModal" role="dialog" tabIndex="-1" data-keyboard="false" aria-labelledby="finalValidationPopupLabel">
            <div role="document" className="modal-dialog" >
              <div className="modal-content">
              <a href aria-label="Close" 
                      className="close" onClick={()=> {
                        this.hidePopup("#finalValidationModal")} }>
                          <img src="images/close-orange.png" alt="close icon" className="img-fluid" />
                      </a>
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" class="img-fluid" alt="logo" />
                  </div>
                  {this.state.failedfieldValidationCollection.length > 0 &&
<div className="text-center mt-4 validationErrorContainer">
  <label>Please Fill the below missing field values</label>
                  <ul>
  {
    this.state.failedfieldValidationCollection.map((value, idx) => (
      <li key={`${value}-${idx}`}>{value}</li>
    ))
  }
</ul>
</div>
    }

                </div>

                <div className="modal-footer mb-4">
                  
                  <button type="button" className="btn btn-primary" onClick={() => {
                  this.hidePopup("#finalValidationModal")}} >Continue</button>
                </div>


              </div>
            </div>
          </div>

          {/* Mobile verification modal */}
          <div className="modal fade" id="mobileModal" role="dialog" tabIndex="-1"  aria-labelledby="mobileModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div role="document" className="modal-dialog" >
                  <div className="modal-content">
		 {this.handleMobilePopupcloseButton()}
                      <div className="modal-body">
                          <div className="logo">
                              <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                          </div>
                          <h5 className="modal-title" id="mobileModalLabel">
                            Enter the 6-digit code we have sent to {this.state.phoneNo}.
                          </h5>

                          <input 
                          className="form-control" 
                          type="text" 
                          id="code" 
                          name="code" 
                          placeholder="Enter code here"
                          value={this.state.code} 
                          disabled={this.state.disableField}
                          onPaste={(event) => { this.onlyAcceptInputs(event) }} required
                          onChange={(event) => this.handleInputChange(event)} 
                          onKeyPress={(event) => { this.onKeyPressData(event) }} />


                        <p className="text-center modal-error-msg mt-4 mb-4">
                          { this.state.codeError !== '' &&
                              <span style={{ color: "#e60000",fontSize: "0.81rem" }}>
                                {this.state.codeError}
                              </span>
                          }
                        </p>

                        <p className="text-center link--block mb-2">
                          { this.displayResendSMSForMobile() }

                          { this.state.showMobileandEmailAddress && this.displayChangeMobileNumber() }
                        </p>

                        {this.state.codeError==='' && (this.state.codeSuccess !== ''|| this.state.codeErrorForResendCode !== '') &&
                          <p id="verifyMessage" className="text-center modal-error-msg mb-0">
                          {this.resendSuccessMessagDisplay()}
                          
                          {this.resendErrorMessageDisplay()}

                        </p>
                        }

                      </div>                      

                      {localStorage.getItem('sessionId') !== null &&
                            <Reaptcha
                              ref={event => (this.reCaptchaRef = event)}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              size="invisible"
                              onLoad={this.onLoad}
                              onVerify={this.onVerify}
                                />
                      }

                      <div className="modal-footer pb-0 pl-0">
                          <button type="submit" id='moible-redirect-screen' 
                          className={this.stylingOfNextButtonPopup()} 
                          disabled={this.state.disableVerifyOkButton} 
                          onClick={(e) => this.handleRecaptchaMobileVerify(e)}>Continue</button>

                      </div>
                  </div>
              </div>
          </div>
          {/* Third party website redirection popup */}
          <div className="modal fade model-error" id="thirdPartyWebsiteModel" role="dialog" tabIndex="-1" data-keyboard="false" aria-labelledby="thirdPartyWebsitePopupLabel">
            <div role="document" className="modal-dialog" >
              <div className="modal-content">
                <div class="modal-header">
                </div>
                <div className="modal-body">
                  <div className="logo">
                    <img src="images/modal-logo.svg" class="img-fluid" alt="logo" />
                  </div>

                  <p className="text-center mb-0 mt-4">{thirdPartyWebsiteAlertPopupText}</p>
                </div>

                <div className="modal-footer mb-4">
                  <button type="button" className="btn btn-outline btn-warning" data-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={() => {this.redirectionThirdPartnerSite()}} >Ok</button>
                </div>


              </div>
            </div>
          </div>
        </div>

      );
    } else {
      return (
        <ApplicationLanding
          nextStep={this.nextStep}
        />
      );
    }
  }
}

export const mapStateToProps = (state) => {
  return {
    getApplicationQuestions: state.dataStore.getApplicationQuestions,
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    phoneNumbers: state.dataStore.phoneNumbers,
    saveDates: state.dataStore.saveDates,
    saveApplicationData: state.dataStore.saveApplicationData,
    amountRequested: state.dataStore.amountRequested,
    saveexpendituredata: state.dataStore.saveexpendituredata,
    employmentStatus: state.dataStore.employmentStatus,
    threashold: state.dataStore.threashold,
    prepopulateStatus: state.dataStore.prepopulateStatus,
    userDetailsPopulateStatus: state.dataStore.userDetailsPopulateStatus,
    emailFlag: state.dataStore.emailFlag,
    birthDateFlag: state.dataStore.birthDateFlag,
    mobileNumberFlag: state.dataStore.mobileNumberFlag,
    questionValueSave: state.dataStore.questionValueSave,
    showCurrentAddress: state.dataStore.showCurrentAddress,
    firstSectionSteps: state.dataStore.firstSectionSteps,
    loaderStatus: state.dataStore.loaderStatus,
    secondSectionSteps: state.dataStore.secondSectionSteps,
    thirdSectionSteps: state.dataStore.thirdSectionSteps,
    fourSectionSteps: state.dataStore.fourSectionSteps,
    fifthSectionSteps: state.dataStore.fifthSectionSteps,
    DocumentAgreeLabel: state.dataStore.DocumentAgreeLabel,
    AgreeLabel: state.dataStore.AgreeLabel,
    contactDocumentAgree: state.dataStore.contactDocumentAgree,
    sectionHeading: state.dataStore.sectionHeading,
    sectionSubContext: state.dataStore.sectionSubContext,
    isChangeMobile: state.dataStore.isChangeMobile,
    isChangeEmail: state.dataStore.isChangeEmail,
    saveIncomeData: state.dataStore.saveIncomeData,
    saveTermData: state.dataStore.saveTermData,
    isDisableChangeMobileOption: state.dataStore.isDisableChangeMobileOption,
    isDisableChangeEmailOption:state.dataStore.isDisableChangeEmailOption
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    personalQuestions: () => {
      return dispatch(personalQuestions());
    },
    questions: () => {
      return dispatch(questions());
    },
    saveIncomeDataValue: (data) => {
      return dispatch({
        type: 'SAVEINCOMEDATA',
        value: data
      });
    },
    showappValidation: (validations) => {
      return dispatch({
        type: 'SHOW_APP_VALIDATIONS',
        value: validations
      });
    },
    firstSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FIRST_SECTION_STEPS",
        value: validations
      });
    },
    secondSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "SECOND_SECTION_STEPS",
        value: validations
      });
    },
    thirdSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "THIRD_SECTION_STEPS",
        value: validations
      });
    },
    fourSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FOUR_SECTION_STEPS",
        value: validations
      });
    },
    hideappValidation: (validations) => {
      return dispatch({
        type: 'HIDE_APP_VALIDATIONS',
        value: validations
      });
    },
    updateFrequencyExists: (validations) => {
      return dispatch({
        type: 'SET_FREQUENCY_EXISTS',
        value: validations
      });
    },
    resetFrequencyExists: (validations) => {
      return dispatch({
        type: 'RESET_FREQUENCY_EXISTS',
        value: validations
      });
    },
    removeContactHummAgreeLabel: (validations) => {
      return dispatch({
        type: 'REMOVE_CONTACT_AGREE_LABEL',
        value: validations
      });
    },
    getPhoneNumber: (validations) => {
      return dispatch({
        type: 'PHONENO',
        value: validations

      });
    },
    appvalidations: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS',
        value: validations
      });
    },
    updateMobileVerified: (validations) => {
      return dispatch({
        type: 'SET_MOBILE_VERIFIED',
        value: validations
      });
    },
    resetMobileVerified: (validations) => {
      return dispatch({
        type: 'RESET_MOBILE_VERIFIED',
        value: validations
      });
    },
    updateEmailVerified: (validations) => {
      return dispatch({
        type: 'SET_EMAIL_VERIFIED',
        value: validations
      });
    },
    resetEmailVerified: (validations) => {
      return dispatch({
        type: 'RESET_EMAIL_VERIFIED',
        value: validations
      });
    },
    updateMobileChange: (validations) => {
      return dispatch({
        type: 'SET_MOBILE_CHANGE',
        value: validations
      });
    },
    resetMobileChange: (validations) => {
      return dispatch({
        type: 'RESET_MOBILE_CHANGE',
        value: validations
      });
    },
    updateEmailChange: (validations) => {
      return dispatch({
        type: 'SET_EMAIL_CHANGE',
        value: validations
      });
    },
    resetEmailChange: (validations) => {
      return dispatch({
        type: 'RESET_EMAIL_CHANGE',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    appValidationsRemove: (validations) => {
      return dispatch({
        type: 'APP_VALIDATIONS_Remove',
        value: validations
      });
    },
    firstSectionStepsReset: (validations) => {
      return dispatch({
        type: 'FIRST_SECTION_STEPS_REMOVE',
        value: validations
      });
    },
    secondSectionStepsReset: (validations) => {
      return dispatch({
        type: 'SECOND_SECTION_STEPS_REMOVE',
        value: validations
      });
    },
    thirdSectionStepsReset: (validations) => {
      return dispatch({
        type: 'THIRD_SECTION_STEPS_REMOVE',
        value: validations
      });
    },
    fourSectionStepsReset: (validations) => {
      return dispatch({
        type: 'FOUR_SECTION_STEPS_REMOVE',
        value: validations
      });
    },
    updateSectionHeading: (validations) => {
      return dispatch({
        type: 'SECTION_HEADING',
        value: validations
      });
    },
    updateSectionContext: (validations) => {
      return dispatch({
        type: 'SECTION_CONTEXT',
        value: validations
      });
    },
    resetSectionHeading: (validations) => {
      return dispatch({
        type: 'SECTION_HEADING_REMOVE',
        value: validations
      });
    },
    resetSectionContext: (validations) => {
      return dispatch({
        type: 'SECTION_CONTEXT_REMOVE',
        value: validations
      });
    },
    secondSectionStepsUpdate: (validations) => {
      return dispatch({
        type: 'SECOND_SECTION_STEPS',
        value: validations
      });
    },
    getAmountRequested: (validations) => {
      return dispatch({
        type: 'AMOUNTREQUESTED',
        value: validations
      });
    },
    prePopulate: (validations) => {
      return dispatch({
        type: 'PREPOPOLATE',
        value: validations
      });
    },

    removeSavedata: (validations) => {
      return dispatch({
        type: 'REMOVEDATA',
        value: validations
      });
    },
    removeDocumentAgreeLabel: (validations) => {
      return dispatch({
        type: 'REMOVE_DOCUMENT_AGREE_LABEL',
        value: validations
      });
    },
    removeAgreeLabel: (validations) => {
      return dispatch({
        type: 'REMOVE_AGREE_LABEL',
        value: validations
      });
    },
    prepopulateStatusCall: (validations) => {
      return dispatch({
        type: 'PREPOPULATEE',
        value: validations
      });
    },
    userDetailsPopulateStatusCall: (validations) => {
      return dispatch({
        type: 'USER_DETAILS_PREPOPULATE',
        value: validations
      })
    },
    birthDateFlagStatusCall: (validations) => {
      return dispatch({
        type: 'BIRTH_DATE_FLAG',
        value: validations
      })
    },
    firstNameFlagStatusCall: (validations) => {
      return dispatch({
        type: 'FIRST_NAME_FLAG',
        value: validations
      })
    },
    lastNameFlagStatusCall: (validations) => {
      return dispatch({
        type: 'LAST_NAME_FLAG',
        value: validations
      })
    },
    emailFlagStatusCall: (validations) => {
      return dispatch({
        type: 'EMAIL_FLAG',
        value: validations
      })
    },
    mobileNumberFlagStatusCall: (validations) => {
      return dispatch({
        type: 'MOBILE_NUMER_FLAG',
        value: validations
      })
    },
    questionSave: (data) => {
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
      });
    },
    saveDate: (data) => {
      return dispatch({
        type: 'SAVEDATE',
        value: data
      });
    },
    removeQuestionSave: (data) => {
      return dispatch({
        type: 'REMOVEQUESTIONSAVE',
        value: data
      });
    },
    showAddress:(data) => {
      return dispatch({
        type: 'SHOWADDRESS',
        value: data
      });
    },
    getEmploynmentstatus: (validations) => {
      return dispatch({
        type: 'EMPLOYNMENTSTATUS',
        value: validations
      });
    },    
    disableChangeMobileOption: (validations) => {
      return dispatch({
        type: 'UPDATE_MOBILE_CHANGE_OPTION',
        value: validations
      });
    },
    disableChangeEmailOption: (validations) => {
      return dispatch({
        type: 'UPDATE_EMAIL_CHANGE_OPTION',
        value: validations
      });
    },
  }
}

Application.propTypes = {
  firstSectionSteps: PropTypes.array,
  secondSectionSteps: PropTypes.array,
  firstSectionStepsReset: PropTypes.func,
  secondSectionStepsReset: PropTypes.func,
  thirdSectionStepsReset: PropTypes.func,
  fourSectionStepsReset: PropTypes.func,
  resetSectionHeading: PropTypes.func,
  resetSectionContext: PropTypes.func,
  resetMobileChange: PropTypes.func,
  resetEmailChange: PropTypes.func,
  resetFrequencyExists: PropTypes.func,
  removeAgreeLabel: PropTypes.func,
  removeDocumentAgreeLabel: PropTypes.func,
  removeContactHummAgreeLabel: PropTypes.func,
  resetMobileVerified: PropTypes.func,
  resetEmailVerified: PropTypes.func,
  removeQuestionSave: PropTypes.func,
  appValidationsRemove: PropTypes.func,
  showappValidation: PropTypes.func,
  getAmountRequested: PropTypes.func,
  removeSavedata: PropTypes.func,
  showAddress: PropTypes.func,
  firstNameFlagStatusCall: PropTypes.func,
  lastNameFlagStatusCall: PropTypes.func,
  emailFlagStatusCall: PropTypes.func,
  mobileNumberFlagStatusCall: PropTypes.func,
  birthDateFlagStatusCall: PropTypes.func,
  phoneNumbers: PropTypes.string,
  applicationValidation: PropTypes.array,
  saveIncomeDataValue: PropTypes.func,
  updateMobileChange: PropTypes.func,
  updateEmailChange: PropTypes.func,
  history: PropTypes.object,
  saveApplicationDatas: PropTypes.func,
  saveApplicationData: PropTypes.array,
  questions: PropTypes.func,
  updateSectionHeading: PropTypes.func,
  updateSectionContext: PropTypes.func,
  updateFrequencyExists: PropTypes.func,
  prepopulateStatusCall: PropTypes.func,
  appvalidations: PropTypes.func,
  questionValueSave: PropTypes.array,
  amountRequested: PropTypes.string,
  threashold: PropTypes.string,
  emailFlag: PropTypes.bool,
  birthDateFlag: PropTypes.bool,
  mobileNumberFlag: PropTypes.bool,
  updateEmailVerified: PropTypes.func,
  updateMobileVerified: PropTypes.func,
  firstSectionValidationUpdate: PropTypes.func,
  userDetailsPopulateStatusCall: PropTypes.func,
  thirdSectionSteps: PropTypes.array,
  fourSectionSteps: PropTypes.array,
  isChangeEmail: PropTypes.bool,
  isChangeMobile: PropTypes.bool,
  sectionHeading: PropTypes.array,
  sectionSubContext: PropTypes.array,
  secondSectionValidationUpdate: PropTypes.func,
  prepopulateStatus: PropTypes.bool,
  thirdSectionValidationUpdate: PropTypes.func,
  fourSectionValidationUpdate: PropTypes.func,
  getEmploynmentstatus:PropTypes.bool,
  disableChangeMobileOption:PropTypes.func,
  disableChangeEmailOption:PropTypes.func,
  isDisableChangeMobileOption: PropTypes.bool,
  isDisableChangeEmailOption: PropTypes.bool,
  stage: PropTypes.string,
  location: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);